import React, { useState } from "react";
import { Loader } from "@mantine/core";

const Index = ({ height }: any) => {
  return (
    <div
      style={{
        width: "100vw",
        height: height ? height : "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader  variant="dots" color="red" />
    </div>
  );
};
export default Index;
