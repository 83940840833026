import AgoraRTC from "agora-rtc-sdk-ng";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import useSignLanguage from "../../../hooks/sign-language.hooks";
import { Button, createStyles } from "@mantine/core";
import { sign } from "crypto";
import SignLanguageVideoRoomPublic from "./SignLanguageVideoRoomPublic";

const SignLanguageAdmin = ({ event, showSignLanguage }: any) => {
  const { classes } = useStyles();
  const [currentDocId, setCurrentDocId]: any = useState<null | string>(null);
  const [firstLanguage, setSignFirstLanguage] = useState("");
  const [lastLanguage, setSignLastLanguage] = useState("");

  const {
    init,
    join,
    leave,
    firebasePublicGetData,
    connecting,
    joinState,
    remoteUsers,
    clientInitialized,
    signLanguageState,
  } = useSignLanguage(currentDocId);

  useEffect(() => {
    if (event) {
      init();
      setCurrentDocId(event.id);
    }
  }, [event, showSignLanguage]);

  useEffect(() => {
    if (event) {
      firebasePublicGetData(event.id);
    }
  }, [event, joinState, remoteUsers, showSignLanguage]);

  useEffect(() => {
    if (clientInitialized) {
      join(event.id, "audience");
    }
  }, [clientInitialized, showSignLanguage]);

  // useEffect(() => {
  //   if(signLanguageState?.liveStream){
  //     join(event.id, "audience");
  //   }else{
  //     leave();
  //   }
  // },[signLanguageState])

  useEffect(() => {
    if (remoteUsers.length > 0 && remoteUsers != undefined) {
      if (joinState) {
        if (remoteUsers.length >= 1) {
          const mainVideo = remoteUsers.find(
            (x: any) => x.uid == signLanguageState.mainId
          );

          if (mainVideo?.videoTrack != undefined) {
            setSignFirstLanguage(mainVideo.uid);
            mainVideo?.videoTrack.play("publicvideo1");
          }
        }
        if (remoteUsers?.length == 2) {
          const sideVideo = remoteUsers.find(
            (x: any) => x.uid != signLanguageState.mainId
          );
          if (sideVideo?.videoTrack != undefined) {
            setSignLastLanguage(sideVideo.uid);
            sideVideo?.videoTrack.play("publicvideo2");
          }
        }
      }
    }
  }, [connecting, signLanguageState, remoteUsers, joinState, showSignLanguage]);

  return (
    <div className={classes.main}>
      <div className={classes.mainStream}>
        {/* <p style={{color:"white"}}>
      
      {firstLanguage}
      </p>  */}
        Active interpreter - Live
        <div
          id="publicvideo1"
          className={classes.mainVideo}
          style={{ width: "100%", height: "100%" }}
        ></div>
      </div>
      {/* <p style={{color:"white"}}>
      
      {lastLanguage}
      </p>  */}
      <div
        className={classes.switchVideoCoActive}
        style={{
          marginLeft: 10,
          marginBottom: 20,
          width: "calc(50% + 4px)",
        }}
      >
        Co-interpreter
        <div
          id="publicvideo2"
          style={{ width: "100%", height: "100%", marginTop: 5 }}
        ></div>
      </div>
    </div>
  );
};
const useStyles = createStyles((theme) => ({
  main: {
    display: "flex",
    margin: 20,
  },
  mainStream: {
    width: "calc(50% - 4px)",
    height: "50vh",
    marginBottom: 20,
    "@media (max-width: 1200px)": {
      height: "35vh",
    },
  },
  mainVideo: {
    border: "2px solid red",
    marginTop: 5,
  },
  switchVideoCoActive: {
    height: "calc(50vh - 2px)",
    "@media (max-width: 1200px)": {
      height: "calc(35vh + 2px)",
    },
  },
}));
export default SignLanguageAdmin;
