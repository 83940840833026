import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IEvent } from "../../interfaces/event.interface";

interface IEventState {
  events: IEvent | null;
}

const initialstate: IEventState = {
  events: null,
};
export const slice: any = createSlice({
  name: "currentEvent",
  initialState: initialstate,
  reducers: {
    setLoading: (state: any, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCurrentEvent: (state: any, action: PayloadAction<IEvent>) => {
      state.currentvent = action.payload;
    }
  },
});

export const { setLoading, setCurrentEvent } = slice.actions;

export default slice.reducer;
