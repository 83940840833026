import React, { useCallback, useState, useEffect } from "react";
import {
  Table,
  Modal,
  Group,
  Text,
  UnstyledButton,
  ScrollArea,
  createStyles,
  Button,
  TextInput,
  Center,
} from "@mantine/core";
import { Selector, ChevronDown, ChevronUp, Search } from "tabler-icons-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ITable from "../../../interfaces/table.interface";
import moment from "moment";
import { useOrganization } from "../../../hooks/organization.hook";

interface ThProps {
  children: React.ReactNode;
  sorted: boolean;
  id: number;
  reversed: boolean;
  onSort(): void;
}
interface RowData {
  date: string;
  eventName: string;
  live: boolean;
  url: string;
}

function filterData(data: any, search: string) {
  const keys = Object.keys(data[0]);
  const query = search.toLowerCase()?.trim();
  return data.filter((item: any) =>
    keys.some(
      (key: any) =>
        typeof item[key] === "string" &&
        item[key].toLowerCase().includes(query.toLocaleLowerCase())
    )
  );
}

const Index: React.FunctionComponent<ITable> = (props: any) => {
  let { id } = useParams();
  const { classes, cx } = useStyles();
  const [sortBy, setSortBy]: any = useState<keyof RowData>();
  const [live, setLive] = useState(false);
  const [createdByMe, setCreatedByMe] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const { handlers: orgaHandlers, organization }: any = useOrganization();
  const [sortedData, setSortedData] = useState(props.data);

  useEffect(() => {
    if (id) {
      orgaHandlers.getOrganization(id);
    }
  }, [id]);

  const [search, setSearch] = useState("");

  const navigate = useNavigate();
  const { t } = useTranslation("common");
  function sortData(
    data: any,
    payload: { sortBy: any; reversed: boolean; search: string }
  ) {
    if (!payload.sortBy) {
      return filterData(data, payload.search);
    }

    return filterData(
      [...data].sort((a: any, b: any) => {
        if (payload.reversed) {
          return b[payload.sortBy]?.localeCompare(a[payload.sortBy]);
        }

        return a[payload.sortBy]?.localeCompare(b[payload.sortBy]);
      }),
      payload.search
    );
  }

  useEffect(() => {
    if (props?.data.length != sortedData?.length) setSortedData(props.data);
    else setSortedData(props.data);
  }, [props.data]);

  const setSorting = (field: keyof RowData) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(sortedData, { sortBy: field, reversed, search }));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(
      sortData(props.data, {
        sortBy,
        reversed: reverseSortDirection,
        search: value,
      })
    );
  };

  const goAddEventPage = useCallback(
    () => navigate(props.addButtonNavigate, { replace: true }),
    [navigate]
  );

  function Th({ children, reversed, sorted, id, onSort }: ThProps) {
    const { classes } = useStyles();
    const Icon = sorted ? (reversed ? ChevronUp : ChevronDown) : Selector;
    return (
      <th
        key={id}
        className={`${classes.th} ${props?.th?.length < 3 && classes.shortTh} ${
          props?.th?.length == 4 && classes.longTh
        }`}
      >
        <UnstyledButton onClick={onSort} className={classes.control}>
          <Group position="apart">
            <Text className={classes.text} weight={500} size="sm">
              {children}
            </Text>
            <Center className={classes.icon}>
              <Icon size={14} />
            </Center>
          </Group>
        </UnstyledButton>
      </th>
    );
  }

  // const toggleRow = (id: string) =>
  //   setSelectionCheckBox((current) =>
  //     current.includes(id)
  //       ? current.filter((item) => item !== id)
  //       : [...current, id]
  //   );

  const rows = sortedData?.map((item: any) => (
    <tr
      className={classes.item}
      key={item._id}
      // style={{
      //   backgroundColor:
      //     props.tableName == "events" &&
      //     moment(item?.startDate)?.format("L") >= moment().format("L") &&
      //     moment().format("L") <= moment(item?.endDate)?.format("L")
      //       ? "#ffe3e3"
      //       : undefined,
      // }}
    >
      {props.tr(item)}
    </tr>
  ));

  // const setLiveFilter = () => {
  //   setLive(!live);
  //   var filterLive = sortedData;
  //   if (!live) {
  //     filterLive = filterLive.filter(
  //       (x: any) =>
  //         moment(x?.startDate)?.format("L") >= moment().format("L") &&
  //         moment().format("L") <= moment(x?.endDate)?.format("L")
  //     );
  //     setSortedData(filterLive);
  //   } else {
  //     filterLive = props.data;
  //     setSortedData(filterLive);
  //   }
  // };
  // const setFavoritesFilter = () => {
  //   setFavorites(!favorites);
  //   var filterFavorites = sortedData;
  //   filterFavorites = filterFavorites.filter((x: any) =>
  //     selectionCheckBox.some((y: any) => x.id == y)
  //   );
  //   if (!favorites) {
  //     setSortedData(filterFavorites);
  //   } else {
  //     setSortedData(props.data);
  //   }
  // };
  const setCreatedByMeFilter = () => {
    setCreatedByMe(!createdByMe);
    if (!createdByMe) {
      const _data = props.data.filter((x: any) => x.created == "Jane");
      setSortedData(_data);
    } else {
      setSortedData(props.data);
    }
  };

  return (
    <>
      {props.tableName != "organizations" && (
        <Text className={classes.orga_title}>{organization?.name}</Text>
      )}
      <div className={classes.filter_area}>
        <Group className={classes.filter_group}>
          <TextInput
            size="sm"
            className={classes.search_bar}
            style={{ width: props.filter ? 300 : 500 }}
            rightSectionWidth={42}
            placeholder={t("search")}
            icon={<Search size={14} />}
            value={search}
            onChange={handleSearchChange}
          />

          {props.filter && (
            <>
              {/* <Switch
                checked={live}
                onChange={() => {
                  setLiveFilter();
                }}
                label="Live"
                color="red"
              /> */}
              {/* <Switch
                onChange={() => {
                  setFavoritesFilter();
                }}
                label="Favorites"
                color="red"
              />
              <Switch
                checked={createdByMe}
                onChange={() => {
                  setCreatedByMeFilter();
                }}
                label="Created by me"
                color="red"
              /> */}
            </>
          )}
        </Group>
        <div className={classes.add_button}>
          {props.addButtonVisible && (
            <Button onClick={goAddEventPage} color="red">
              {t(props.addButton)}
            </Button>
          )}
        </div>
      </div>
      <ScrollArea
        className={classes.table}
        sx={{ height: "calc(100vh - 270px)" }}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      >
        <Table
          highlightOnHover
          horizontalSpacing="md"
          verticalSpacing="xs"
          sx={{ tableLayout: "fixed", minWidth: 700 }}
        >
          <thead
            className={cx(classes.header, { [classes.scrolled]: scrolled })}
          >
            <tr>
              {/* {props.favorities && <th style={{ width: 10 }}></th>} */}
              {props.th.map((x: any, i: number) => {
                return x.sort ? (
                  <Th
                    id={i}
                    key={i}
                    sorted={sortBy === x.sortName}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting(x.sortName)}
                  >
                    {t(x.name)}
                  </Th>
                ) : (
                  <th
                    className={`${classes.th} ${
                      props?.th?.length < 3 && classes.shortTh
                    } ${props?.th?.length == 4 && classes.longTh}`}
                    key={i}
                  >
                    <Text className={classes.text}>{t(x.name)}</Text>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {rows?.length > 0 && sortedData?.length > 0 ? (
              rows
            ) : (
              <tr>
                <td colSpan={5}>
                  <Text weight={500} align="center">
                  {t("nothing_found")}
                   
                  </Text>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </ScrollArea>
    </>
  );
};
const useStyles = createStyles((theme) => ({
  text: {
    fontSize: 17,
  },
  orga_title: {
    paddingTop: 10,
  },
  filter_area: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
    marginTop: 10,
    "@media (max-width: 768px)": {
      flexDirection: "column-reverse",
      paddingTop: 20,
    },
  },
  filter_group: {
    "@media (max-width: 768px)": {
      width: "100%",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  search_bar: {
    "@media (max-width: 768px)": {
      width: "100% !important",
    },
  },
  add_button: {
    "@media (max-width: 768px)": {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: 10,
    },
  },
  item: {
    // backgroundColor:theme.colors.red[1],
    "&:hover": {
      // backgroundColor: theme.colors.gray[1],
      cursor: "pointer",
    },
    td: {
      borderBottom: "0px !important",
    },
  },
  table: {
    boxShadow: "0px 0px 4px 0px #e2d6d6",
    backgroundColor: theme.white,
    "@media (max-width: 768px)": {
      height: "calc(100vh - 400px)",
    },
  },
  date_area: {
    width: 100,
  },
  live: {
    width: 20,
    height: 20,
    backgroundColor: theme.colors.red,
    borderRadius: "50%",
  },
  header: {
    position: "sticky",
    zIndex: 9,
    top: 0,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    transition: "box-shadow 150ms ease",

    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[3]
          : theme.colors.gray[2]
      }`,
    },
  },
  scrolled: {
    boxShadow: theme.shadows.sm,
  },

  th: {
    padding: "0 !important",
    "&:nth-of-type(1)": {
      width: "20%",
    },
    "&:nth-of-type(2)": {
      width: "60%",
    },
    "&:nth-of-type(3)": {
      width: "20%",
    },
    "@media (max-width: 768px)": {
      "&:nth-of-type(1)": {
        width: "25%",
      },
      "&:nth-of-type(2)": {
        width: "50%",
      },
      "&:nth-of-type(3)": {
        width: "25%",
      },
    },
  },
  longTh: {
    "&:nth-of-type(1)": {
      width: "20%",
    },
    "&:nth-of-type(2)": {
      width: "20%",
    },
    "&:nth-of-type(3)": {
      width: "40%",
    },
    "&:nth-of-type(4)": {
      width: "20%",
    },
    "@media (max-width: 768px)": {
      "&:nth-of-type(1)": {
        width: "22%",
      },
      "&:nth-of-type(2)": {
        width: "22%",
      },
      "&:nth-of-type(3)": {
        width: "29%",
      },
      "&:nth-of-type(4)": {
        width: "25%",
      },
    },
  },
  shortTh: {
    padding: "0 !important",
    "&:nth-of-type(1)": {
      width: "85% !important",
    },
    "&:nth-of-type(2)": {
      width: "15%",
    },
  },

  control: {
    width: "100%",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[3]
          : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
}));
export default Index;
