import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IEventMembers } from "../../interfaces/event-members.interface";

interface IMemberState {
  eventMembers: IEventMembers[] | null;
}

const initialstate: IMemberState = {
  eventMembers: [],
};
export const slice: any = createSlice({
  name: "members",
  initialState: initialstate,
  reducers: {
    setLoading: (state: any, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setEventMembers: (state: any, action: PayloadAction<IEventMembers[]>) => {
      state.eventMembers = action.payload;
    },
    setAllByEventMembers: (
      state: any,
      action: PayloadAction<IEventMembers[]>
    ) => {
      state.allByEventMembers = action.payload;
    },
    setCurrentEventMember: (
      state: any,
      action: PayloadAction<IEventMembers[]>
    ) => {
      state.currentEventMember = action.payload;
    },
  },
});

export const {
  setLoading,
  setEventMembers,
  setAllByEventMembers,
  setCurrentEventMember,
} = slice.actions;

export default slice.reducer;
