import React, { useState, useEffect } from "react";
import {
  createStyles,
  Avatar,
  Accordion,
  Group,
  ActionIcon,
  Button,
  List,
  Center,
  Text,
} from "@mantine/core";
import { User, Messages, PinnedOff, X, Pin } from "tabler-icons-react";
import { HiUserGroup, HiOutlineUserGroup } from "react-icons/hi";
import { Tabs } from "@mantine/core";
import Chat from "./chat";
import Info from "./info";
import { IEvent } from "../../../interfaces/event.interface";
import Role from "../../../enums/role.enum";
import { BsInfoSquare, BsQuestion, BsInfoSquareFill } from "react-icons/bs";
import {
  HiChatBubbleLeftRight,
  HiOutlineChatBubbleLeftRight,
} from "react-icons/hi2";
import { useLocalStorage } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { ReactInternetSpeedMeter } from "../internet-speed-tester";
import { FiWifi, FiWifiOff } from "react-icons/fi";
import AlertDialog from "../dialog/AlertDialog";

type MessageBoxProps = {
  openDrawer: any;
  fixDrawer: any;
  event: IEvent;
  fixed?: boolean;
  remoteUsersNames: any;
  initChat?: any;
  setOpenQuestionModal: any;
  sendMessage?: any;
  audioJoinState: boolean;
  messages?: any;
  ethernetSpeed?:any;
  offline?:boolean;
};
const MessageBox = ({
  openDrawer,
  fixDrawer,
  fixed,
  event,
  initChat,
  remoteUsersNames,
  setOpenQuestionModal,
  sendMessage,
  audioJoinState,
  messages,
  ethernetSpeed,
  offline
}: MessageBoxProps) => {
  const { t } = useTranslation("common");


  const { classes } = useStyles();
  const [activeTab, setActiveTab] = useLocalStorage({
    key: "activeTab",
    defaultValue: 0,
  });

  const showTeamChat = () => {
    if (
      event?.usersRole === Role.Interpreter ||
      event?.usersRole === Role.Admin ||
      event?.usersRole === Role.Owner
    ) {
      return true;
    }

    return false;
  };

  const showFormular = () => {
    if (event?.usersRole === Role.Customer) {
      return true;
    }
    return false;
  };
  const showCustomerChat = () => {
    if (
      event.usersRole === Role.Customer ||
      event.usersRole === Role.User ||
      event.usersRole === Role.Interpreter ||
      event.usersRole === Role.Admin ||
      event.usersRole === Role.Owner
    ) {
      if (event.isPrivate) {
        return true;
      }
      return false;
    }
    return false;
  };

  const showSupportBox = () => {
    setOpenQuestionModal(true);

    if (!fixed) fixDrawer();
  };

  const ethernetControl = () => {
    if (!offline&& ethernetSpeed && ethernetSpeed >= 5) {
      return (
        <Group>
          <FiWifi  size={25} color="green"/>
          <Text>{t("layout.internet_good")}</Text>
        </Group>
      );
    }
    if (!offline && ethernetSpeed && ethernetSpeed < 5) {
      return (
        <Group>
          <FiWifi  size={25} color="red"/>
          <Text>{t("layout.internet_bad")}</Text>
        </Group>
      );
    }
    if(!offline&& ethernetSpeed === null){
      return (
        <Group>
          <FiWifi  size={25} color="gray"/>
          <Text>{t("layout.internet_control_check")}</Text>
        </Group>
      ); 
    }
    if(offline){
      return (
        <Group>
          <FiWifiOff  size={25} color="gray"/>
          <Text>{t("layout.internet_control_please")}</Text>
        </Group>
      ); 
    }
  };
  return (
    <>
      <Group id="close_area" grow position="apart" className={classes.header}>
        <ActionIcon className={classes.pin} onClick={() => fixDrawer()}>
          {fixed ? <PinnedOff color="#000000" /> : <Pin color="#000000" />}
        </ActionIcon>
        <ActionIcon className={classes.close} onClick={() => openDrawer()}>
          <X color="#000000" />
        </ActionIcon>
      </Group>
      <Tabs
        active={activeTab}
        onTabChange={setActiveTab}
        classNames={{ tabActive: classes.tabs }}
        styles={{
          tabActive: { backgroundColor: "#fa5252 !important" },
          // tabLabel: { color: "white" }
        }}
        grow
        tabPadding={0}
        variant="pills"
      >
        {/*  <Tabs.Tab label="Info" icon={<User size={14} />}>
          <div style={{ height: "calc(100vh - 69px)" }}>
            {users.map((x: any, i: number) => (
              <Group p="xs" key={i}>
                <div className={classes.status}>
                  <div
                    className={`${
                      x.status
                        ? classes.user_status + " " + classes.online
                        : classes.user_status + " " + classes.offline
                    }`}
                  ></div>
                </div>
                <Text size="sm">{x.name}</Text>
              </Group>
            ))}
          </div>
        </Tabs.Tab> */}

        {showTeamChat() && (
          <Tabs.Tab
            label={t("layout.team")}
            icon={
              activeTab == 0 ? (
                <HiChatBubbleLeftRight color="white" size={14} />
              ) : (
                <HiOutlineChatBubbleLeftRight size={14} />
              )
            }
          >
            <div style={{ height: `calc(100vh - 72px)` }}>
              <Chat
                initChat={initChat}
                sendMessage={sendMessage}
                messages={messages}
                id={event?.uid + "-source"}
              />
            </div>
          </Tabs.Tab>
        )}

        {showCustomerChat() && (
          <Tabs.Tab
            label={t("layout.all")}
            icon={
              activeTab == 1 ? (
                <HiUserGroup size={15} />
              ) : (
                <HiOutlineUserGroup size={15} />
              )
            }
          >
            <div style={{ height: "calc(100vh - 72px)" }}>
              <Chat
                initChat={initChat}
                sendMessage={sendMessage}
                messages={messages}
                id={event.uid + "-all"}
              />
            </div>
          </Tabs.Tab>
        )}
        <Tabs.Tab
          label={t("layout.info")}
          icon={
            activeTab == (showCustomerChat() ? 2 : 1) ? (
              <BsInfoSquareFill color="white" size={14} />
            ) : (
              <BsInfoSquare size={14} />
            )
          }
        >
          <div
            style={{
              height: `calc(100vh - 72px)`,
              width: "100%",
              position: "relative",
            }}
          >

            <Accordion initialItem={0}>
              {event.isAudioStreaming && (
                <Accordion.Item label={t("layout.audio")}>
                  <Info
                    remoteUsersNames={remoteUsersNames}
                    event={event}
                    audioJoinState={audioJoinState}
                  />
                </Accordion.Item>
              )}

              <Accordion.Item label={t("layout.network")}>
            
            {ethernetControl()}
              </Accordion.Item>
              {showFormular() && (
                <Accordion.Item label={t("layout.form_links")}>
                  <List>
                    <List.Item>
                      <Button
                        component="a"
                        href="https://www.swisstxt.ch/de/annullation/"
                        target="_blank"
                        compact
                        style={{ textDecoration: "underline" }}
                        variant="subtle"
                        color="red"
                      >
                        {t("layout.german_form")}
                      </Button>
                    </List.Item>
                    <List.Item>
                      <Button
                        component="a"
                        href="https://www.swisstxt.ch/fr/annulation/"
                        target="_blank"
                        compact
                        style={{ textDecoration: "underline" }}
                        variant="subtle"
                        color="red"
                      >
                        {t("layout.french_form")}
                      </Button>
                    </List.Item>
                  </List>
                </Accordion.Item>
              )}
            </Accordion>

            <Center>
              <Button
                onClick={showSupportBox}
                color="red"
                leftIcon={<BsQuestion size={25} />}
                style={{ bottom: 20, position: "absolute", width: "90%" }}
              >
                {t("support")}
              </Button>
            </Center>
          </div>
        </Tabs.Tab>
      </Tabs>
    </>
  );
};
const useStyles = createStyles((theme) => ({
  header: {
    "@media (max-width: 769px)": {
      justifyContent: "right",
    },
  },
  tabs: {
    ".mantine-Tabs-tabLabel": {
      color: "white",
    },
  },
  pin: {
    "@media (max-width: 769px)": {
      display: "none",
    },
  },
  close: {
    "@media (max-width: 769px)": {
      maxWidth: "100px",
    },
  },
  status: {
    display: "flex",
    position: "relative",
  },
  user_status: {
    width: 8,
    top: 0,
    zIndex: 99,
    right: 0,
    height: 8,
    borderRadius: "50%",
  },
  online: {
    backgroundColor: theme.colors.green[5],
  },
  offline: {
    backgroundColor: theme.colors.red[6],
  },
}));
export default MessageBox;
