import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import {
  MultiSelect,
  Card,
  createStyles,
  Group,
  Text,
  Select,
} from "@mantine/core";
import { useUser } from "../../../hooks/user.hook";
import { IProfile } from "../../../interfaces/profile.interface";
import { IoPersonOutline } from "react-icons/io5";

interface IProps {
  setValue: Dispatch<SetStateAction<any>>;
  value: any;
  label: string;
  placeholder: string;
}
const MultipleSelect = (props: IProps) => {
  const { classes } = useStyles();
  const { handlers: userHandlers, profiles, profile }: any = useUser();

  const [selectEmail, setSelectEmail]: any = useState(
    props.value.eventMembersEmail
  );
  const [dataForSelect, setDataForSelect]: any = useState([]);
  const [dataForMap, setDataForMap]: any = useState(
    props.value.eventMembersData
  );
  const roles = [
    { value: "Admin", label: "Admin" },
    { value: "Interpreter", label: "Interpreter" },
    { value: "User", label: "User" },
    { value: "Owner", label: "Owner" },
    { value: "Customer", label: "Customer" },
    { value: "SignLanguage", label: "Sign Language" },
  ];

  useEffect(() => {
    // Serverden gelen datayı map edip select için gerekli olan arrayi oluşturuyoruz (value ve label)
    if (profiles && profiles.length > 0) {
      const dataWithValue = profiles?.map((item: any) => {
        return {
          value: item.email,
          label: item.email,
        };
      });
      const filterProfile = dataWithValue.filter(
        (x: any) => x.value != profile.email
      );
      setDataForSelect(filterProfile);
    }
  }, [profiles]);

  useEffect(() => {
    // dataForMap in icinde yeni bir array olustur

    // ! DataForMap arrin icindeki objectleri sonra api ye gonderebilirzin !

    if (dataForMap?.length < selectEmail?.length) {
      const arr1 = selectEmail.map((item: any) => {
        return profiles.find((item2: any) => item2.email === item);
      });

      const arr2 = arr1.filter((object1: any) => {
        return !dataForMap.some((object2: any) => {
          return object1?.id === object2?.id;
        });
      });

      const arr3 = [...dataForMap, ...arr2];
      setDataForMap(arr3);
    } else {
      const arr2 = dataForMap?.filter((object1: any) => {
        return !selectEmail.some((object2: any) => {
          return object1.email === object2;
        });
      });

      const profileToRemove: IProfile = arr2[0];

      const arr3 = dataForMap?.filter((obj: IProfile) => {
        return obj?.id !== profileToRemove?.id;
      });

      setDataForMap(arr3);
    }
  }, [selectEmail]);

  const getSelectRole = (e: any, profile: IProfile) => {
    // bütün array clone al
    let clonedArray = JSON.parse(JSON.stringify(dataForMap));

    // objecti kur ve degistir
    var item = { ...profile, role: e };

    // degistirilen objecti arrayin icindeki bul ve degistir
    var foundIndex = clonedArray.findIndex((x: any) => x?.id == profile?.id);
    clonedArray[foundIndex] = item;

    // yeni arrayi set et
    props.setValue({
      ...props.value,
      eventMembersData: clonedArray,
    });
  };

  const getSelectEmail = (e: any) => {
    props.setValue({
      ...props.value,
      eventMembersEmail: e,
    });
  };

  return (
    <>
      <MultiSelect
        data={dataForSelect}
        clearable
        searchable
        label={props.label}
        placeholder={props.placeholder}
        onChange={(e: any) => getSelectEmail(e)}
        value={selectEmail}
      />
      {dataForMap?.map((x: IProfile | any, i: number) => (
        <Card
          style={{ width: "100%", padding: 10 }}
          key={i}
          className={classes.card}
          shadow="sm"
          p="lg"
        >
          <Group grow>
            <Group className={classes.title}>
              <IoPersonOutline />
              <Text className={classes.email}>{x?.email}</Text>
            </Group>
            <Select
              className={classes.select}
              placeholder="Pick Role"
              value={x?.role}
              onChange={(e: any) => getSelectRole(e, x)}
              data={roles}
            />
          </Group>
        </Card>
      ))}
    </>
  );
};
const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.white[1],
    width: "100%",
  },
  title: {
    "@media (max-width: 768px)": {
      flexDirection: "row",
      maxWidth: "100%",
      width: "100%",
    },
  },
  email: {
    "@media (max-width: 768px)": {
      whiteSpace: "nowrap",
      width: "70%",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  select: {
    "@media (max-width: 768px)": {
      width: "100% !important",
      maxWidth: "100% !important",
    },
  },
}));
export default MultipleSelect;
