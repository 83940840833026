import React, { useEffect } from "react";
import { Provider } from "react-redux";
import store from "./store/store";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import { useTranslation } from "react-i18next";
import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";

export interface IApplicationProps {}

const App: React.FunctionComponent<IApplicationProps> = (props) => {
  const { t, i18n } = useTranslation("common");

  useEffect(() => {
    const firstWordShortcut = localStorage.getItem("first_word_shortcut");
    const lastWordShortcut = localStorage.getItem("last_word_shortcut");
    const firstSentenceShortcut = localStorage.getItem("first_sentence_shortcut");
    const lastSentenceShortcut = localStorage.getItem("last_sentence_shortcut");
    const inputModeShortcut = localStorage.getItem("input_mode");
    if (!firstWordShortcut) {
      localStorage.setItem("first_word_shortcut", "a");
    }
    if (!lastWordShortcut) {
      localStorage.setItem("last_word_shortcut", "d");
    }
    if (!firstSentenceShortcut) {
      localStorage.setItem("first_sentence_shortcut", "f");
    }
    if (!lastSentenceShortcut) {
      localStorage.setItem("last_sentence_shortcut", "l");
    }
    if (!inputModeShortcut) {
      localStorage.setItem("input_mode", "i");
    }
  }, []);
  return (
    <Provider store={store}>
      <MantineProvider>
        <ModalsProvider>
          {" "}
          <AppRoutes />
        </ModalsProvider>
      </MantineProvider>
    </Provider>
  );
};

export default App;
