import EventComplete from "./eventComplete";
import EventDetails from "./eventDetails";
import EventLanguages from "./eventLanguages";
import EventPrivacy from "./eventPrivacy";
import EventSettings from "./eventSettings";

export {
  EventComplete,
  EventDetails,
  EventLanguages,
  EventPrivacy,
  EventSettings,
};
