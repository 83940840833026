import { useState, useEffect } from "react";

import {
  auth,
  FirebaseUser,
  updatePassword,
  signInWithEmailAndPassword,
  UserCredential,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "../api/firebase";
import { useAppDispatch } from "../store/hooks";
import {
  setFirebaseToken,
  setLoading,
} from "../store/reducers/firebaseAuth.reducer";
import { setError } from "../store/reducers/notification.reducer";
import {
  resetProfile,
  setFirebaseUserInfo,
} from "../store/reducers/user.reducer";
import { AppDispatch, RootState } from "../store/store";
import restClient from "../api/restClient";

export declare interface IFirebaseUserInfo extends FirebaseUser {
  accessToken?: string;
}

export const useFirebaseAuth = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const [user, setLocalFirebaseUserInfo] = useState<IFirebaseUserInfo | null>(
    null
  );

  const login = async (email: string, password: string) => {
    try {
      dispatch(setLoading(true));
      signInWithEmailAndPassword(auth, email, password)
        .then(async (userCredential: UserCredential) => {
          const user: IFirebaseUserInfo = userCredential.user;
          if (!user.emailVerified) {
            auth.signOut();
            dispatch(
              setError("Please check your Email and verify your email address.")
            );
          } else {
            handleUserInfo(user);
            dispatch(setError(""));
          }
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          const errorCode = error.code;
          const errorMessage = error.message;
          dispatch(setError(errorCode));
        });
    } catch (e) {
      dispatch(setLoading(false));
    }
  };
  const authRegister = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    nextStep: any
  ) => {
    dispatch(setLoading(true));
    try {
      createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential: UserCredential) => {
          dispatch(setLoading(false));
          var postData = {
            email: userCredential.user.email,
            firebaseUid: userCredential.user.uid,
            firstName: firstName,
            lastName: lastName,
          };
          await restClient.post("/profiles/", postData);
          dispatch(setError(""));
          sendEmailVerification(userCredential.user)
            .then((res) => {
              nextStep()
            })
            .catch((err) => {
              console.log("error", err);
            });
          auth.signOut();
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          dispatch(setError(errorCode));
          setTimeout(() => dispatch(setLoading(false)), 1500);
        });
    } catch (e) {
      dispatch(setLoading(false));
    }
  };
  const resetPassword = async (email: string) => {
    const config = {
      url: window.location.origin + "/login",
      handleCodeInApp: true,
    };
    try {
      dispatch(setLoading(true));
      await sendPasswordResetEmail(auth, email, config)
        .then((res) => {
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          const errorCode = error.code;
          dispatch(setError(errorCode));
        });
    } catch (e) {
      dispatch(setLoading(false));
    }
  };

  const changePassword = async (newPassword?: any) => {
    dispatch(setLoading(true));
    const config = {
      url: "http://localhost:3001/login",
      handleCodeInApp: true,
    };
    const user: any = await auth.currentUser;
    try {
      await updatePassword(user, newPassword)
        .then((res) => {
          dispatch(setLoading(false));
          auth.signOut();
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    } catch (e) {
      dispatch(setLoading(false));
    }
  };

  const handleUserInfo = async (user: IFirebaseUserInfo) => {
    dispatch(setLoading(true));
    const firebaseToken = await user.getIdToken();
    dispatch(setFirebaseToken(firebaseToken));
    dispatch(setFirebaseUserInfo(user));
    dispatch(setLoading(false));
  };

  const logout = () => {
    auth.signOut();
    dispatch(setFirebaseToken(null));
    dispatch(setFirebaseUserInfo(null));
    dispatch(setLoading(false));
    dispatch(resetProfile());
  };

  return {
    login,
    authRegister,
    logout,
    handleUserInfo,
    resetPassword,
    changePassword,
  };
};
