import React, { useEffect, useState, useRef, useCallback } from "react";
import { createStyles, Text, Paper } from "@mantine/core";
import { Scrollbar } from "react-scrollbars-custom";

import { useConnector } from "../../../hooks/sharedbconnector.hook";
import { useSearchParams } from "react-router-dom";

const TranslationArea = ({
  event,
  autoScroll,
  textCenter,
  fontSize,
  language,
  variation,
  downloadPdf,
  color,
}: any) => {
  const refContainer: any = useRef();
  const [connected, setConnected] = useState(false);
  const [searchParams] = useSearchParams();
  const urlParams = Object.fromEntries([...searchParams]);
  let componentRef: any = useRef();

  const [
    bindingReady,
    guid,
    operationCounter,
    translatedData,
    { init, close },
  ] = useConnector();
  const scrollbarRef: any = useRef<HTMLDivElement>();

  useEffect(() => {
    if (connected) return;
    if (init && event && !event.isRichText) {
      setConnected(true);
      init(event.id, language, language, refContainer.current);
    }
  }, [event, language, connected, init]);

  useEffect(() => {
    if (autoScroll && scrollbarRef.current) {
      scrollToBottom();
    }
  }, [
    scrollbarRef,
    operationCounter,
    refContainer,
    autoScroll,
    fontSize,
    language,
  ]);

  useEffect(() => {
    return () => {
      close();
    };
  }, []);

  useEffect(() => {
    if (autoScroll) {
      setTimeout(() => {
        scrollToBottom();
      }, 1000);
    }
    if (autoScroll) {
      setTimeout(() => {
        scrollToBottom();
      }, 3000);
    }
  }, []);

  useEffect(() => {
    if (autoScroll && scrollbarRef.current) {
      scrollToBottom();
    }
  }, [operationCounter, scrollbarRef, language, urlParams]);

  const renderTranslations = (trans: any) => {
    return trans.map((sentenceData: any, index: number) => {
      return (
        <div
          key={index}
          style={
            color.backgroundColor !== "transparent"
              ? { textAlign: "left" }
              : { textAlign: "center" }
          }
        >
          <Text
            style={{
              fontSize: `${fontSize}px `,
              wordBreak: "break-word",
              textAlign: textCenter && "center",
              lineHeight:
                variation == 2
                  ? fontSize == 39
                    ? "1.6"
                    : fontSize == 16
                    ? "1.55"
                    : fontSize == 26
                    ? "1.55"
                    : "1.5"
                  : "1.5",
            }}
          >
            {sentenceData.value}
          </Text>
        </div>
      );
    });
  };

  const scrollToBottom = () => {
    scrollbarRef.current.scrollToBottom({ behavior: "smooth" });
  };

  const textareaStyle = {
    ...color,
    outline: "none",
    fontSize: `${fontSize}px `,
    width: "100%",
    height: "100%",
    padding: variation == 2 ? "0px 70px" : variation == 3 ? "16px 70px" : 16,
    resize: "none",
    lineHeight:
      variation == 2
        ? fontSize == 39
          ? "1.6"
          : fontSize == 16
          ? "1.55"
          : fontSize == 26
          ? "1.55"
          : "1.5"
        : "1.5",
  };

  return (
    <Paper
      ref={(el: any) => (componentRef = el)}
      radius={0}
      style={textareaStyle}
    >
      <Scrollbar
        noScrollY={true}
        style={{ width: "100%", height: "100%" }}
        ref={scrollbarRef}
        tabIndex={0}
      >
        {translatedData &&
          translatedData.length > 0 &&
          renderTranslations(translatedData)}
      </Scrollbar>
    </Paper>
  );
};

export default TranslationArea;
