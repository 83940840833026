import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useNotifications } from "@mantine/notifications";
import { useSelector } from "react-redux";
import { UserCheck, MailOpened, CircleCheck } from "tabler-icons-react";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import IPageProps from "../../../interfaces/page.interface";
import {
  AppShell,
  Stepper,
  Button,
  Center,
  Title,
  Paper,
  Group,
  Container,
  TextInput,
  Text,
  Anchor,
  PasswordInput,
  InputWrapper,
} from "@mantine/core";
import { useTranslation } from "react-i18next";

import { isValidEmail } from "../../../utils";
import { useFirebaseAuth } from "../../../hooks/firebaseAuth.hook";
import useWindowSize from "../../../utils/windowsDimensions";
import { useForm } from "react-hook-form";
import Dialog from "../../../components/low-level/dialog/AlertDialog";
import clientPublic from "../../../api/restClient";

const SignUpPage: React.FunctionComponent<IPageProps> = (props) => {
  const { authRegister } = useFirebaseAuth();
  const { t, i18n } = useTranslation("common");
  const [registerStart, setRegisterStart] = useState(false);
  const loading = useSelector((state: any) => state.firebaseAuth.loading);
  const { width, height } = useWindowSize();

  let bodyHeight = 300;
  if (height) {
    bodyHeight = height - 228;
  }
  let buttonWidth = 300;
  if (width) {
    buttonWidth = width / 1.5;
  }
  const {
    watch,
    register,
    formState: { errors },
  } = useForm();
  const [active, setActive] = useState(0);
  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const emailCredential = watch("email");
  const firstName = watch("firstname");
  const lastName = watch("lastname");
  const passwordCredential = watch("password");

  const isValid = isValidEmail(emailCredential);

  const onSubmit = async () => {
    setRegisterStart(true);
    if (
      isValid &&
      passwordCredential?.length >= 6 &&
      firstName &&
      firstName?.length > 2 &&
      lastName?.length > 2
    ) {
      await authRegister(
        emailCredential,
        passwordCredential,
        firstName,
        lastName,
        nextStep
      );
      setRegisterStart(false);
      // if (!error && !loading) navigate("/login");
      // setActive((current) => (current < 3 ? current + 1 : current));
    }
  };

  return (
    <Container size={420} my={40}>
      <Title
        align="center"
        mb={30}
        sx={(theme) => ({
          fontFamily: `Greycliff CF, ${theme.fontFamily}`,
          fontWeight: 900,
        })}
      >
        {active == 0 ? t("auth.register") : t("auth.verify_email")}
      </Title>
      <Stepper
        color="red"
        active={active}
        onStepClick={setActive}
        breakpoint="sm"
      >
        <Stepper.Step
          icon={<UserCheck size={18} />}
          label={t("auth.first_step")}
          description={t("auth.create_account")}
          allowStepSelect={active > 0}
        >
          <Paper withBorder shadow="md" p={30} mt={20} radius="md">
            <TextInput
              id="firstname"
              label={t("auth.firstname")}
              {...register("firstname")}
              placeholder={t("auth.firstname")}
              required
              error={
                registerStart && firstName?.length < 2 && t("auth.required")
              }
            />
            <TextInput
              id="lastname"
              label={t("auth.lastname")}
              {...register("lastname")}
              placeholder={t("auth.lastname")}
              required
              error={
                registerStart && lastName?.length < 2 && t("auth.required")
              }
            />
            <TextInput
              id="email"
              label={t("auth.email")}
              {...register("email")}
              placeholder={t("auth.email_placeholder")}
              required
              error={registerStart && !isValid && t("auth.required")}
            />
            <PasswordInput
              id="password"
              label={t("auth.password")}
              {...register("password")}
              error={
                (registerStart &&
                  passwordCredential?.length < 1 &&
                  t("auth.required")) ||
                (registerStart &&
                  passwordCredential?.length < 6 &&
                  t("auth.required_min_6"))
              }
              placeholder={t("auth.password")}
              required
            />
            <Button
              loading={loading}
              color="red"
              fullWidth
              mt="xl"
              onClick={() => onSubmit()}
            >
              {t("auth.sign_up")}
            </Button>
          </Paper>
          <Text color="dimmed" size="sm" align="center" mt={5}>
            {t("auth.already_a_member")}{" "}
            <Anchor<"a"> href="/login" size="sm">
              {t("auth.login")}
            </Anchor>
          </Text>
        </Stepper.Step>
        <Stepper.Step
          icon={<MailOpened size={18} />}
          label="Second step"
          description="Verify email"
          allowStepSelect={active > 1}
        >
          <Container>
            <Center>
              <CircleCheck size={80} strokeWidth={1} color={"#40bf4d"} />
            </Center>
            <Center>
              <Group direction="column" align="center">
                <Text color="dimmed" size="sm" align="center" mt={5}>
                  {t("auth.register_success")}
                </Text>
                <Text color="dimmed" size="sm" align="center">
                  {t("auth.register_success_2")}
                </Text>
                <Anchor<"a"> href="/login" size="sm">
                  {t("auth.register_success_3")}
                </Anchor>
              </Group>
            </Center>
          </Container>
        </Stepper.Step>
      </Stepper>
    </Container>
  );
};

export default SignUpPage;
