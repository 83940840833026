import React, { useState } from "react";
import {

  TextInput,
  Button,
} from "@mantine/core";


const EditBox = ({ value, handleUpdate, index, hash }: any) => {
  const [text, setText] = useState(value);

  return (
    <>
      <TextInput
        value={text}
        onChange={(e) => {
          setText(e.target.value);
        }}
      />
      <Button fullWidth onClick={() => handleUpdate(index, text, hash)} mt="md">
        Submit
      </Button>
    </>
  );
};

export default EditBox;
