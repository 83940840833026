import { useAppDispatch, useAppSelector } from "../store/hooks";
import { AppDispatch, RootState } from "../store/store";

import restClient from "../api/restClient";
import {
  setEventMembers,
  setAllByEventMembers,
  setLoading,
} from "../store/reducers/event-members.reducer";
import { setSuccess, setError } from "../store/reducers/notification.reducer";

import { IOrganizationMember } from "../interfaces/organization-member.interface";
import ICreateMember from "../interfaces/create-member.interface";
import getAuthHeader from "../utils/getAuthHeader";
import { IOrganizationMembers } from "../interfaces/organization-members.interface";

export const useEventMembers = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const eventMembers: IOrganizationMember[] = useAppSelector(
    (state: RootState) => state.eventMembers.eventMembers
  );
  const allByEventMembers: IOrganizationMember[] = useAppSelector(
    (state: RootState) => state.eventMembers.allByEventMembers
  );
  const loading: IOrganizationMember = useAppSelector(
    (state: RootState) => state.eventMembers.loading
  );

  const createEventMember = async (data: ICreateMember) => {
    dispatch(setLoading(true));
    try {
      const config = await getAuthHeader();
      const response = await restClient.post("/event-members", data, config);
      const member: IOrganizationMember = response.data;
      if (member) {
        getAllEventMembers();
        dispatch(setLoading(false));
      }
    } catch (e) {
      dispatch(setLoading(false));
      console.log(e);
    }
  };

  const getAllEventMembers = async () => {
    dispatch(setLoading(true));
    try {
      const config = await getAuthHeader();
      const response = await restClient.get("/event-members", config);
      const eventMembers: IOrganizationMembers[] = response.data;
      if (eventMembers) {
        dispatch(setEventMembers(eventMembers));
        dispatch(setLoading(false));
      }
    } catch (e) {
      dispatch(setLoading(false));
      console.log(e);
    }
  };

  const removeEventMember = async (memberId: string) => {
    dispatch(setLoading(true));
    try {
      const config = await getAuthHeader();
      const response = await restClient.delete(
        "/event-members/" + memberId,
        config
      );
      const member: IOrganizationMember = response.data;
      if (member) {
        dispatch(setLoading(false));
        getAllEventMembers();
      }
    } catch (e) {
      dispatch(setLoading(false));
      console.log(e);
    }
  };

  const allByEventMember = async (eventId: string) => {
    try {
      const config = await getAuthHeader();
      const response = await restClient.get(
        "/event-members/event/" + eventId,
        config
      );

      const member: IOrganizationMember = response.data;
      if (member) {
        dispatch(setAllByEventMembers(member));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateEventMembers = async (id: string, data: ICreateMember) => {
    dispatch(setLoading(true));
    dispatch(setSuccess(""));
    try {
      const config = await getAuthHeader();
      const response = await restClient.patch(
        "/event-members/" + id,
        data,
        config
      );
      const events: IOrganizationMember = response.data;
      if (events) {
        dispatch(setSuccess("success"));
        getAllEventMembers();
      }
    } catch (e) {
      console.log(e);
      dispatch(setSuccess(""));
      dispatch(setLoading(false));
      dispatch(setError(e));
    }
  };
  const handlers = {
    getAllEventMembers: getAllEventMembers,
    createEventMember: createEventMember,
    removeEventMember: removeEventMember,
    allByEventMember: allByEventMember,
    updateEventMembers: updateEventMembers,
  };

  return {
    handlers,
    eventMembers,
    allByEventMembers,
    loading,
  };
};
