import {
  Container,
  Card,
  Group,
  TextInput,
  Button,
  Center,
} from "@mantine/core";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../components/low-level/layout";
import { useTranslation } from "react-i18next";
import { useOrganization } from "../../hooks/organization.hook";
import { useForm } from "react-hook-form";

interface CustomizedState {
  event: string;
}
const EditEventPage: React.FunctionComponent = (props) => {
  const { t, i18n } = useTranslation("common");
  const location = useLocation();
  const state = location.state as CustomizedState;
  const { event }: any = state;
  const {
    handlers: orgaHandlers,
    organization,
    organizations,
  }: any = useOrganization();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const eventName = watch("eventName");

  const updateItem = () => {
    orgaHandlers.updateOrganization(event.id, eventName);
  };
  return (
    <Layout page="edit" eventName={t("organization.edit_organization")}>
      <Container pt="md" size="xs">
        <Card>
          <Group>
            <TextInput
              {...register("eventName")}
              style={{ width: "100%" }}
              placeholder={t("organization.organization_name")}
              label={t("organization.organization_name")}
              defaultValue={event.name}
            />
          </Group>
          <Center>
            <Button
              disabled={eventName == undefined ? true : eventName == event.name}
              onClick={() => updateItem()}
              mt="lg"
              color="red"
            >
              {t("apply")}
            </Button>
          </Center>
        </Card>
      </Container>
    </Layout>
  );
};

export default EditEventPage;
