import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IEventState {
  role: string;
}

const initialstate: IEventState = {
  role: "Guest",
};
export const slice: any = createSlice({
  name: "role",
  initialState: initialstate,
  reducers: {
    setRole: (state: any, action: PayloadAction<any>) => {
      state.role = action.payload;
    },
    setLoading: (state: any, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const { setRole, setLoading } = slice.actions;

export default slice.reducer;
