import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IErrorState {
  errorMessage: string | null;
  successMessage: string | null;
}
const initialstate: IErrorState = {
  errorMessage: "",
  successMessage: "",
};

export const counterSlice = createSlice({
  name: "notification",
  initialState: initialstate,
  reducers: {
    setError: (state: { errorMessage: any }, action: { payload: any }) => {
      state.errorMessage = action.payload;
    },
    setSuccess: (state: { successMessage: any }, action: { payload: any }) => {
      state.successMessage = action.payload;
    },
  },
});

export const { setError, setSuccess } = counterSlice.actions;

export default counterSlice.reducer;
