import React, { useState, Dispatch, SetStateAction } from "react";
import {
  InputWrapper,
  createStyles,
  Checkbox,
  SimpleGrid,
  Group,
  Stepper,
  StepperProps,
} from "@mantine/core";
import { User, Mail } from "tabler-icons-react";
import { useParams } from "react-router-dom";
import Button from "../button/button";
import { useTranslation } from "react-i18next";
import { MembersSelect } from "../select";

interface IProps {
  setValue: Dispatch<SetStateAction<any>>;
  value: any;
  nextStep: () => void;
  prevStep: () => void;
}

const EventDetails = (props: IProps) => {
  const { t } = useTranslation("common");
  const { id } = useParams();
  const { classes } = useStyles();
  const [active, setActive] = useState(0);
  const [dataForMap, setDataForMap]: any = useState(
    props.value.eventMembersData
  );

  const StyledStepper = (props: StepperProps) => {
    const { classes } = useStyles();
    return <Stepper {...props} />;
  };
  const prevStep = () =>
    setActive((current: number) => (current > 0 ? current - 1 : current));

  const handleSubmitMember = async () => {
    const data: any = dataForMap.map((x: any) => {
      return { profile: x.id, roles: x.role };
    });
    if (data) {
      await props.setValue({
        ...props.value,
        eventMembers: data,
      });
    }
    props.nextStep();
  };
  return (
    <SimpleGrid cols={1} className={classes.container}>
      <InputWrapper
        id="event-name-wrapper"
        label={t("events.private")}
        description={t("events.private_desc")}
        style={{ width: "100%" }}
      >
        <Checkbox
          checked={props.value?.isPrivate}
          onChange={(event) =>
            props.setValue({
              ...props.value,
              isPrivate: event.currentTarget.checked,
            })
          }
          color="red"
          value={"isPrivate"}
          label={t("events.private")}
        />
      </InputWrapper>
      <StyledStepper
        color="red"
        active={active}
        styles={{
          steps: { justifyContent: "center" },
        }}
        onStepClick={setActive}
        breakpoint="sm"
      >
        <Stepper.Step
          label={t("events.select_members")}
          icon={<User />}
          color="red"
          allowStepSelect={active > 0}
        >
          <MembersSelect
            label={t("events.select_members")}
            value={props.value}
            setValue={props.setValue}
            placeholder={t("events.select_members")}
          />
        </Stepper.Step>
      </StyledStepper>
      <Group position="apart">
        <Button
          text={t("back")}
          loading={false}
          variant="default"
          onClick={active == 0 ? props.prevStep : prevStep}
        />

        <Button
          text={t("next_step")}
          loading={false}
          color="red"
          onClick={handleSubmitMember}
        />
      </Group>
    </SimpleGrid>
  );
};
const useStyles = createStyles((theme) => ({
  container: {
    width: "80%",
    "@media (max-width: 769px)": {
      width: "100% !important",
    },
  },
  card: {
    backgroundColor: theme.white[1],
    width: "100%",
  },
  group: {
    "@media (max-width: 768px)": {
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
    },
  },
  title: {
    "@media (max-width: 768px)": {
      flexDirection: "row",
      maxWidth: "100%",
      width: "100%",
    },
  },
  email: {
    "@media (max-width: 768px)": {
      whiteSpace: "nowrap",
      width: "70%",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  select: {
    "@media (max-width: 768px)": {
      width: "100% !important",
      maxWidth: "100% !important",
    },
  },
}));
export default EventDetails;
