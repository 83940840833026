import React, { useState } from "react";
import {
  getDatabase,
  ref,
  onValue,
  push,
  onDisconnect,
  set,
  serverTimestamp,
} from "firebase/database";
import { auth, firebaseApp } from "../api/firebase";

export const userOnlineOffline = () => {
  var uid = auth?.currentUser?.uid;
  const db = getDatabase();
  const myConnectionsRef = ref(db, `users/${uid}/connections`);
  const lastOnlineRef = ref(db, `users/${uid}/lastOnline`);

  const connectedRef = ref(db, ".info/connected");

  onValue(connectedRef, (snap) => {
    if (snap.val() === true) {
      // We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)
      const con = push(myConnectionsRef);

      // When I disconnect, remove this device
      onDisconnect(con).remove();

      // Add this device to my connections list
      // this value could contain info about the device or a timestamp too
      set(con, true);
      // When I disconnect, update the last time I was seen online
      onDisconnect(lastOnlineRef).set(serverTimestamp());
    }
  });
};
