import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IFirebaseAuthState {
  firebaseToken: string | null;
  loading: boolean;
}

const initialstate: IFirebaseAuthState = {
  firebaseToken: null,
  loading: false,
};

export const slice = createSlice({
  name: "firebase",
  initialState: initialstate,
  reducers: {
    setLoading: (state: any, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFirebaseToken: (state: any, action: PayloadAction<string | null>) => {
      state.firebaseToken = action.payload;
    },
  },
});

export const { setLoading, setFirebaseToken } = slice.actions;

export default slice.reducer;
