import { nanoid } from "nanoid";
import moment from "moment";
import { IProfile } from '../interfaces/profile.interface';
import { Timestamp } from "firebase/firestore";

class MessageClass {
  message: string;
  senderId: string;
  sendTime: string;
  uid: string;
  sender: string;
  sendTimeFirebaseTimestamp: any
  constructor({
    userId,
    message,
    profile,
  }: {
    userId: string;
    message: string;
    profile: IProfile;
  }) {
    const uid = nanoid(12).toLowerCase();
    this.message = message?.trim();
    this.senderId = userId;
    this.sendTime = moment().utc().format();
    this.sendTimeFirebaseTimestamp = Timestamp.fromDate(new Date()),
    this.uid = uid;
    this.sender = profile.firstName ? profile.firstName : profile.email;
    // direction 'outgoing | incoming'
  }
}

export default MessageClass;

// 0BbYc2S2DGazfFnoRUem