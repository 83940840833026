import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOrganization } from "../../interfaces/organization.interface";


interface IUserState {
  organization: IOrganization | null;
  organizations: IOrganization[] | null;
}

const initialstate: IUserState = {
  organization: null,
  organizations: null,
};

export const slice: any = createSlice({
  name: "organization",
  initialState: initialstate,
  reducers: {
    setLoading: (state: any, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setOrganization: (state: any, action: PayloadAction<IOrganization>) => {
      state.organization = action.payload;
    },
    setOrganizations: (state: any, action: PayloadAction<any>) => {
      state.organizations = action.payload;
    }
  },
});

export const { setLoading, setOrganization, setOrganizations } = slice.actions;

export default slice.reducer;
