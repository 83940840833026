import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../store";

export enum themeEnum {
  LIGHT = "theme-light",
  DARK = "theme-dark",
}

export interface IUiState {
  title: string | null;
  showTitle: boolean;
  scrollY: number;
  scrollToChange: number | null;
  pageHeight: number;
  theme: themeEnum.DARK | themeEnum.LIGHT;
}

const inititalState: IUiState = {
  title: "",
  showTitle: true,
  scrollY: 0,
  scrollToChange: null,
  pageHeight: 0,
  theme: themeEnum.LIGHT,
};

export const slice = createSlice({
  name: "ui",
  initialState: inititalState,
  reducers: {
    setTitle: (state: { title: any }, action: { payload: any }) => {
      state.title = action.payload;
    },
    setShowTitle: (state: { showTitle: any }, action: { payload: any }) => {
      state.showTitle = action.payload;
    },
    setScrollY: (state: { scrollY: any }, action: { payload: any }) => {
      state.scrollY = action.payload;
    },
    setScrollTo: (state: { scrollToChange: any }, action: { payload: any }) => {
      state.scrollToChange = action.payload;
    },
    setCurrentPageHeight: (
      state: { pageHeight: any },
      action: { payload: any }
    ) => {
      state.pageHeight = action.payload;
    },
    setTheme: (
      state: any,
      action: { payload: themeEnum.DARK | themeEnum.LIGHT }
    ) => {
      state.theme = action.payload;
    },
  },
});

export const {
  setTitle,
  setShowTitle,
  setScrollY,
  setScrollTo,
  setTheme,
  setCurrentPageHeight,
} = slice.actions;

export const initTheme =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const uiSettings = localStorage.getItem("ui");
    let settings = null;

    if (uiSettings) {
      settings = JSON.parse(uiSettings);
    }
    if (settings) {
      dispatch(setTheme(settings.theme));
    } else {
      dispatch(setTheme(themeEnum.LIGHT));
      await localStorage.setItem(
        "ui",
        JSON.stringify({ theme: themeEnum.LIGHT })
      );
    }
  };

export const selectIsDarkTheme = (state: RootState) =>
  state.ui.theme === themeEnum.DARK;

export default slice.reducer;
