import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProfile } from "../../interfaces/profile.interface";

interface IProfileState {
  profiles: IProfile[] | null;
}

const initialstate: IProfileState = {
  profiles: null,
};

export const slice = createSlice({
  name: "profiles",
  initialState: initialstate,
  reducers: {
    setLoading: (state: any, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setProfiles: (state: any, action: PayloadAction<IProfile[]>) => {
      state.profiles = action.payload;
    }
  },
});

export const { setLoading, setProfiles } = slice.actions;

export default slice.reducer;
