import React, { useEffect, useState, useRef, useCallback } from "react";

import Loading from "../loading";
import { useConnector } from "../../../hooks/sharedbconnector.hook";
import { useLocation, useSearchParams } from "react-router-dom";

const TextAreaEditor = ({
  event,
  autoScroll,
  fontSize,
  videoHover,
  variation,
  color,
  setAutoScroll,
  scrollHidden = false,
  textCenter,
  divideIntoTwo,
  mobile,
  readonly = false,
}: any) => {
  const [searchParams] = useSearchParams();
  const urlParams = Object.fromEntries([...searchParams]);

  const refContainer: any = useRef();
  let location: any = useLocation();
  const [refVisible, setRefVisible] = useState(false);
  const [connected, setConnected] = useState(false);
  // const [inputListener, setInputListener] = useState<any>(null);
  const [
    bindingReady,
    guid,
    operationCounter,
    translatedData,
    { init, close },
  ] = useConnector();

  useEffect(() => {
    if (connected) return;

    if (!refContainer.current || !event || event.isRichText) return;

    setConnected(true);
    init(event.id, "main", "main", refContainer.current);
    /*
    const inputListener = refContainer.current.addEventListener(
      "input",
      function (event: any) {
        setAutoScroll(false);
      }
    );
    setInputListener(inputListener);
  */
    if (autoScroll) {
      setTimeout(() => {
        scrollToBottom();
      }, 1000);
    }
    if (autoScroll) {
      setTimeout(() => {
        scrollToBottom();
      }, 3000);
    }
  }, [refContainer, refVisible, event, connected]);
  /*
  useEffect(() => {
    return () => {
      if (inputListener) {
        inputListener.removeEventListener();
      }
      close();
    };
  }, []);
  */

  useEffect(() => {
    if (autoScroll) {
      scrollToBottom();
    }
  }, [
    operationCounter,
    refContainer,
    autoScroll,
    fontSize,
    variation,
    divideIntoTwo,
    urlParams,
  ]);

  const scrollToBottom = () => {
    refContainer.current.scrollTop = refContainer?.current?.scrollHeight;
  };

  useEffect(() => {
    const x: any = document.getElementById(
      "textarea1"
    ) as HTMLDivElement | null;

    if (x) {
      // x.value.style.backgroundColor = "red";
    }
  }, []);
  return (
    <>
      {/* ? event.showPlayer
                ? "16px 70px"
                : 16 */}
      <div
        style={{
          backgroundColor:
            variation == 2 && event.showPlayer
              ? "rgba(0, 0, 0, 0.5)"
              : color?.backgroundColor,
          padding:
            location.pathname == `/event/${event.shortUid}`
              ? 0
              : variation == 2
              ? event.showPlayer
                ? 0
                : 16
              : variation == 3
              ? mobile
                ? "10px"
                : "16px 70px"
              : 16,
          width: "100%",
          height:
            location.pathname == `/p/${event.shortUid}`
              ? variation == 2
                ? "100%"
                : "calc(100% - 32px)"
              : "100%",
        }}
      >
        <textarea
          readOnly={readonly}
          id="textarea1"
          tabIndex={0}
          name="textarea1"
          ref={(el) => {
            refContainer.current = el;
          }}
          spellCheck="false"
          style={{
            ...color,
            backgroundColor: "transparent",
            padding:
              location.pathname == `/event/${event.shortUid}`
                ? 16
                : variation == 2
                ? mobile
                  ? "0px"
                  : event.showPlayer
                  ? "0px 70px"
                  : 0
                : 0,
            resize: "none",
            cursor: readonly && "context-menu",
            textAlign: textCenter && "center",
            fontFamily: "Roboto",
            outline: "none",
            fontSize: `${fontSize}px`,
            overflowY: scrollHidden && "hidden",
            overflowX: scrollHidden && "hidden",
            display: "flex",
            border: 0,
            height:
              location.pathname == `/event/${event.shortUid}`
                ? "calc(100% - 32px)"
                : "100%",
            flexDirection: "column",
            width:
              location.pathname == `/event/${event.shortUid}`
                ? "calc(100% - 32px)"
                : variation == 2
                ? mobile
                  ? "100%"
                  : event.showPlayer
                  ? "calc(100% - 139.5px)"
                  : "100%"
                : "100%",
            lineHeight:
              variation == 2
                ? fontSize == 39
                  ? "1.6"
                  : fontSize == 16
                  ? "1.55"
                  : fontSize == 26
                  ? "1.55"
                  : "1.5"
                : "1.5",
          }}
        ></textarea>
      </div>
    </>
  );
};

export default TextAreaEditor;
