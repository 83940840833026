import {
  doc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { db } from "../api/firebase";
import {
  IVideoStateData,
  setVideoState,
} from "../store/reducers/video-reducer";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store/store";

export default function useVideo(id: string | null) {
  const dispatch = useDispatch();

  const currentRaiseHandState: any = useAppSelector(
    (state: RootState) => state.video.currentVideoState
  );


  const [initLoaded, setInitLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!id) {
      return;
    }
    initVideo(id);
  }, [id]);

  useEffect(() => {
    if (!id || !initLoaded) {
      return;
    }

    const docRef = doc(db, "show-player-preview", id);
    const unsub = onSnapshot(docRef, (doc: any) => {
      // IN REDUCER SCHREIBEN!!!
      const data = doc.data();
      dispatch(setVideoState(data));
    });
    return () => {
      unsub && unsub();
      setInitLoaded(false);
      dispatch(setVideoState(null));
    };
  }, [id, initLoaded]);

  const fetchInitialVideoStatus = async (id: string) => {
    const docRef = doc(db, "show-player-preview", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      dispatch(setVideoState(data));
      setInitLoaded(true);
    } else {
      const data: IVideoStateData = {
        createdAt: serverTimestamp(),
        docId: id,
        previewType: "logo",
        showPreview: false,
        updatedAt: serverTimestamp(),
      };
      await setDoc(docRef, data);
      const docSnap = await getDoc(docRef);
      const docData = docSnap.data();

      dispatch(setVideoState(docData));
      setInitLoaded(true);
    }
  };

  const initVideo = useCallback((id: string) => {
    if (!id) return;
    if (id) {
      fetchInitialVideoStatus(id);
      dispatch(setVideoState(null));
      setInitLoaded(false);
    }
  }, []);

  const video = async (id: any): Promise<void> => {

    if (!id) return;
    const videoRef = doc(db, "show-player-preview", id);
    const docRef = doc(db, "show-player-preview", id);
    const docSnap = await getDoc(docRef);
    const data: any = docSnap.data();

    await updateDoc(videoRef, {
      updatedAt: serverTimestamp(),
      showPreview: data.showPreview ? false : true,
      previewType: "logo",
    });
  };

  return {
    video,
    currentRaiseHandState,
    initLoaded
  };
}
