import React, { useEffect, useState, useRef, useCallback } from "react";
// import Quill from "quill";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.snow.css";
import Sharedb from "sharedb/lib/client";
import { Button, Paper, createStyles } from "@mantine/core";
import ReactQuill, { Quill } from "react-quill";
import { saveAs } from "file-saver";
import * as quillToWord from "quill-to-word";

const ReconnectingWebSocket = require("reconnecting-websocket").default;

const richText = require("rich-text");
const Connection = require("sharedb/lib/client").Connection;
Sharedb?.types?.register(richText.type);

const RichTextarea = ({
  id,
  collection,
  autoScroll,
  readOnly = false,
  setAutoScroll,
  fontSize,
  fullSize,
  downloadWorld,
  setDownloadDocx,
  setShowDownloadDocx,
  color,
}: any) => {
  const [socket, setSocket]: any = useState(null);
  const [connection, setConnection]: any = useState(null);
  const [value, setValue] = useState("");
  const [loading, setLoading]: any = useState(false);
  const [scrollElement, setScrollElement]: any = useState(null);
  const [quillObj, setQuillObj]: any = useState(null);
  const [doc, setDoc]: any = useState(null);
  const [newValue, setNewValue]: any = useState(null);
  const [scrollActive, setScrollActive] = useState(false);
  const scrollActiveRef = React.useRef(scrollActive);

  // const [inputListener, setInputListener] = useState<any>(null);
  const [operationCounter, setOperationCounter] = useState<any>(0);
  const { classes } = useStyles({ fontSize });
  const refContainer: any = useRef();
  const refQuill: any = useRef();

  useEffect(() => {
    // Connecting to our socket server
    // const _socket = new WebSocket("ws://wwww.localhost:3001");
    const _socket: any = new ReconnectingWebSocket(
      "wss://richtext-coop.madarray.solutions/"
    );
    const _connection = new Connection(_socket);
    setSocket(_socket);
    setConnection(_connection);
    return () => {
      // Disconnecting from our socket server
      // socket.close();
      // _connection.close();
    };
  }, []);

  useEffect(() => {
    // Querying for our document
    if (!connection || !id || !collection) {
      return;
    }
    const _doc = connection.get(collection, id);
    setDoc(_doc);
  }, [connection, id, collection]);

  useEffect(() => {
    if (!doc) {
      return;
    }
    doc.subscribe((err: any) => {
      if (err) throw err;

      const toolbarOptions = [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        [{ color: [] }, { background: [] }],
      ];

      const options = {
        theme: "snow",
        scrollingContainer: "scrolling-container",
        readOnly: readOnly,
        modules: {
          toolbar: toolbarOptions,
        },
      };
      const quill = new Quill("#editor", options);
      /**
       * On Initialising if data is present in servers
       * Updaing its content to editor
       */
      quill.setContents(doc.data);

      /**
       * On Text change publishing to our server
       * so that it can be broadcasted to all other clients
       */
      quill.on("text-change", (delta: any, _oldDelta: any, source: any) => {
        setNewValue(Math.floor(Math.random() * 10));
        if (source !== "user") return;
        doc.submitOp(delta, { source: quill });
      });

      setShowDownloadDocx(true)
      setQuillObj(quill);
    });
  }, [doc]);

  useEffect(() => {
    const editor: any = document.getElementById("editor");
    /*
    const inputListener = editor?.addEventListener(
      "input",
      function (event: any) {
        setAutoScroll(false);
      }
    );*/
    // setInputListener(inputListener);

    if (autoScroll) {
      setTimeout(() => {
        scrollToBottom();
      }, 1000);
    }
    if (autoScroll) {
      setTimeout(() => {
        scrollToBottom();
      }, 3000);
    }
  }, [
    operationCounter,
    autoScroll,
    refContainer,
    refQuill,
    value,
    quillObj,
    scrollElement,
  ]);
  /*
  useEffect(() => {
    return () => {
      if (inputListener) {
        inputListener.removeEventListener();
      }
      close();
    };
  }, []);
  */

  useEffect(()=>{
    if(downloadWorld){
      _downloadWorld()
    }
  },[downloadWorld])
  const _downloadWorld = async () => {

    const delta: any = quillObj.getContents();
    const quillToWordConfig: any = {
      exportAs: "blob",
    };

    const docAsBlob: any = await quillToWord.generateWord(
      delta,
      quillToWordConfig
    );
    saveAs(docAsBlob, "word-export.docx");
    setDownloadDocx(false)
  };

  const scrollToBottom = () => {
    const editor: any = document.getElementsByClassName("ql-editor");
    setScrollActive(true);

    editor[0].scrollTop = editor[0].scrollHeight;
  };
  useEffect(() => {
    if (autoScroll) {
      scrollToBottom();
    }
  }, [
    operationCounter,
    autoScroll,
    refContainer,
    refQuill,
    value,
    quillObj,
    newValue,
    scrollElement,
  ]);

  const handleScrollActive = () => {
    const st = autoScroll;
    scrollActiveRef.current = st;
    setScrollActive(st);
  };

  useEffect(() => {
    handleScrollActive();
  }, [autoScroll]);

  useEffect(() => {
    setLoading(true);
    if (!refContainer.current) return;
    const editorRef = refContainer.current.children;
    if (editorRef.length > 0) {
      setTimeout(() => {
        const t = editorRef[0];
        setScrollElement(t);
        t.scrollTop = t.scrollHeight;
        setLoading(false);
      }, 1000);
    }
  }, [refContainer]);

  useEffect(() => {
    if (!quillObj || !doc || !scrollElement) return;
    doc.on("op", (op: any, source: any) => {
      if (source === quillObj) return;
      setOperationCounter(operationCounter + 1);
      quillObj.updateContents(op);

      if (scrollActiveRef.current) {
        scrollElement.scrollTop = scrollElement.scrollHeight;
      }
    });
    //t.scrollTop = t.scrollHeight
  }, [quillObj, scrollElement, doc]);

  return (
    <Paper
      p="md"
      radius={0}
      style={{
        color: "black",
        backgroundColor: "white",
        outline: "none",
        height: "100%",
        width: "100%",
        padding: 0,
      }}
      className={readOnly ? classes.editor_paper : undefined}
      ref={refContainer}
    >
      <ReactQuill
        id="editor"
        ref={refQuill}
        readOnly={true}
        theme="snow"
        className={
          readOnly
            ? `${classes.editor_read_only} quill mantine-buu9f8 ql-container ql-snow`
            : `${classes.editor} quill mantine-buu9f8 ql-container ql-snow`
        }
        value={value}
        onChange={setValue}
      />
      
    </Paper>
  );
};
const useStyles = createStyles((theme, { fontSize }: any) => ({
  editor_paper: {
    ".ql-toolbar": {
      display: "none",
    },
    ".ql-editor": {
      overflow: "hidden",
    },
  },
  editor_read_only: {
    border: "0px solid !important",
    overflow: "hidden",
    height: "100% !important",
    "@media (max-width: 415px)": {
      height: "calc(100% - 66px) !important",
    },
    ".ql-editor": {
      fontSize: fontSize,
    },
  },
  editor: {
    height: "calc(100% - 42px) !important",
    "@media (max-width: 415px)": {
      height: "calc(100% - 66px) !important",
    },

    ".ql-editor": {
      fontSize: fontSize,
      boerder: "0px solid",
    },
    ".ql-tooltip": {
      display: "none",
    },
  },
}));
export default RichTextarea;
