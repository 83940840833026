import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  PasswordInput,
  InputWrapper,
  Button,
} from "@mantine/core";
import Layout from "../../components/low-level/layout";
import { useTranslation, WithTranslation } from "react-i18next";
import { useUser } from "../../hooks/user.hook";
import { useFirebaseAuth } from "../../hooks/firebaseAuth.hook";
import { useForm } from "react-hook-form";

const EditPasswordPage: React.FunctionComponent = (props) => {
  const { t } = useTranslation("common");
  const [disabled, setDisabled] = useState(true);
  const { changePassword } = useFirebaseAuth();
  const [changePasswordStart, setChangePasswordStart] = useState(false);

  const { handlers, firebaseUserInfo, profile } = useUser();

  useEffect(() => {
    handlers.updateProfile(profile.id);
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const passwordCredential = watch("password");
  const rePasswordCredential = watch("repassword");
  const passwordCredentialIsFilled = passwordCredential?.length >= 6;
  const rePasswordCredentialIsFilled = rePasswordCredential?.length >= 6;

  const ChangePassword = () => {
    setChangePasswordStart(true);
    if (!disabled) {
      changePassword(rePasswordCredential);
      setChangePasswordStart(false);
    }
  };

  useEffect(() => {
    if (passwordCredential?.length >= 6 && rePasswordCredential?.length >= 6) {
      if (passwordCredential != rePasswordCredential) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    } else {
      setDisabled(true);
    }
  }, [passwordCredential, rePasswordCredential]);

  return (
    <>
      <Layout eventName="Edit Password">
        <Container pt="md" size="xs" px="xs">
          <Card>
            <InputWrapper
              id="password"
              required
              error={
                changePasswordStart &&
                !passwordCredentialIsFilled &&
                t("required")
              }
            >
              <PasswordInput
                {...register("password")}
                label={t("editPassword.new_password")}
                placeholder={t("editPassword.new_password")}
              />
            </InputWrapper>
            <InputWrapper
              id="password"
              required
              error={
                changePasswordStart && !rePasswordCredentialIsFilled
                  ? t("required")
                  : passwordCredentialIsFilled &&
                    passwordCredential != rePasswordCredential &&
                    t("editPassword.password_not_same")
              }
            >
              <PasswordInput
                mt={"xl"}
                {...register("repassword")}
                disabled={
                  watch("password") ? watch("password")?.length < 6 : true
                }
                label={t("editPassword.retype_password")}
                placeholder={t("editPassword.retype_password")}
              />
            </InputWrapper>
            <Button
              loading={changePasswordStart}
              disabled={disabled}
              onClick={() => ChangePassword()}
              fullWidth
              mt="sm"
              color="red"
            >
              {t("save")}
            </Button>
          </Card>
        </Container>
      </Layout>
    </>
  );
};

export default EditPasswordPage;
