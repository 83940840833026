import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import {
  Container,
  Card,
  Group,
  TextInput,
  Button,
  Center,
} from "@mantine/core";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import EditEvent from "../../components/low-level/new-event/eventComplete";
import Layout from "../../components/low-level/layout";
import { useTranslation } from "react-i18next";
import { useEvents } from "../../hooks/event.hook";
import { useSelector } from "react-redux";
import Loading from "../../components/low-level/loading";
interface IProps {
  setIsPrivate?: any;
  setOpenEditArea?: any;
}
const EditEventPage: React.FunctionComponent<IProps> = (props: IProps) => {
  const { id }: any = useParams();

  const { handlers: eventHandlers, currentEvent, loading } = useEvents();
  const [data, setData] = useState(currentEvent);
  useEffect(() => {
    if (id) {
      eventHandlers.getEventData(id);
    }
  }, [id, loading]);

  useEffect(() => {
    setData(currentEvent);
  }, [currentEvent, loading, data]);

  return (
    <Card mb="xs">
      <Center style={{ width: "100%" }}>
        {loading ? (
          <Loading />
        ) : (
          currentEvent && (
              <EditEvent
                setValue={setData}
                pageName="editEvent"
                value={data}
                setOpenEditArea={props.setOpenEditArea}
                setIsPrivate={props.setIsPrivate}
              />
          )
        )}
      </Center>
    </Card>
  );
};

export default EditEventPage;
