import * as React from "react";
import Form from "../../components/low-level/stripe";
import {
  CardElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51LUTbxIVlBri5TOJKdPqFASbefhvnaDFVhUYkbhu08NTsFMPX67JebIuLnULwySylJ8AoyfdJDJCInxgKF9HwFg8008lS9zjJI"
);

const Index = () => {
  return (
    <Elements stripe={stripePromise}>
      <Form />
    </Elements>
  );
};
export default Index;
