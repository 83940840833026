import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  TextInput,
  Title,
  Paper,
  Text,
  InputWrapper,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isValidEmail } from "../../../utils";
import IPageProps from "../../../interfaces/page.interface";
import { useForm } from "react-hook-form";
import { useFirebaseAuth } from "../../../hooks/firebaseAuth.hook";
import { useUser } from "../../../hooks/user.hook";

const SignInPage: React.FunctionComponent<IPageProps> = (props) => {
  const { t, i18n } = useTranslation("common");

  const { resetPassword } = useFirebaseAuth();
  const [submit, setSubmit] = useState(false);
  const [complete, setComplete] = useState(false);
  const error = useSelector((state: any) => state.error?.errorMessage);
  const navigate = useNavigate();
  const {
    register,
    watch,
    formState: { errors },
  } = useForm();
  const emailCredential = watch("email");
  const isValid = isValidEmail(emailCredential);

  const onSubmit = () => {
    setSubmit(true);
    if (isValid) {
      resetPassword(emailCredential);
      setComplete(true);
      setTimeout(() => {
        setSubmit(false);
      }, 1000);
    }
    if (!isValid) {
      setSubmit(false);
    }
  };

  useEffect(() => {
    if (error == undefined && complete) {
      setTimeout(() => {
        navigate("/login");
      }, 6000);
    }
  }, [error, complete]);
  
  return (
    <Container size={420} my={40}>
      <Title
        align="center"
        sx={(theme) => ({
          fontFamily: `Greycliff CF, ${theme.fontFamily}`,
          fontWeight: 900,
        })}
      >
        {t("auth.change_password")}
      </Title>
      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        {complete ? (
          <Text>
            Check your e-mail box for your password reset process, you are
            directed to the login page.
            
          </Text>
        ) : (
          <>
            <InputWrapper
              id="email"
              required
              error={submit && !isValid && t("auth.required")}
            >
              <TextInput
                {...register("email")}
                label={t("auth.email")}
                placeholder={t("auth.email_placeholder")}
                required
              />
            </InputWrapper>
            <Button
              loading={submit}
              color="red"
              fullWidth
              mt="xl"
              onClick={() => onSubmit()}
            >
              {t("auth.reset_password")}
            </Button>
          </>
        )}
      </Paper>
    </Container>
  );
};

export default SignInPage;
