import React, { useState, useEffect } from "react";
import {
  createStyles,
  Container,
  Text,
  Group,
  Popover,
  Avatar,
  Tabs,
  ActionIcon,
} from "@mantine/core";
import { Messages } from "tabler-icons-react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Chat from "../message/messageBox";
import { IEvent } from "../../../interfaces/event.interface";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions.hook";
import { BsQuestion } from "react-icons/bs";
type Props = {
  openDrawer: any;
  fixDrawer: any;
  opened: boolean;
  showMessage: boolean;
  event: IEvent;
  initChat?: any;
  fixed?:boolean;
  sendMessage?: any;
  setOpenQuestionModal: any;
  remoteUsersNames?: any;
  audioJoinState: boolean;
  messages?: any;
  ethernetSpeed?:any;
  setOpenEditArea: any;
};

const Footer = ({
  openDrawer,
  fixDrawer,
  opened,
  showMessage = false,
  event,
  initChat,
  fixed,
  remoteUsersNames,
  audioJoinState,
  setOpenQuestionModal,
  ethernetSpeed,
  sendMessage,
  setOpenEditArea,
  messages,
}: Props) => {
  const { t, i18n } = useTranslation("common");
  const [close, setClose] = useState(false);
  let location = useLocation();
  const { classes } = useStyles();

  return (
    <>
      {opened && event && (
        <div className={classes.right_drawer} onClick={close && openDrawer}>
          <div
            className={classes.right_drawer_container}
            onMouseEnter={() => setClose(false)}
            onMouseLeave={() => setClose(true)}
          >
            <Chat
            ethernetSpeed={ethernetSpeed}
              initChat={initChat}
              audioJoinState={audioJoinState}
              sendMessage={sendMessage}
              setOpenQuestionModal={setOpenQuestionModal}
              messages={messages}
              remoteUsersNames={remoteUsersNames}
              openDrawer={openDrawer}
              fixDrawer={fixDrawer}
              event={event}
            />
          </div>
        </div>
      )}

      <div
        className={`${classes.footer} ${
          !opened && classes.footer_open_message_box
        }`}
      >
        <Container pr="2%" className={classes.inner} fluid>
          <div className={`${classes.footer_items}`}>
            <Group
              className={
                showMessage ? classes.footer_left : classes.footer_left_full
              }
            >
              {/* <div>
                <Text style={{ fontSize: 17 }} weight={700}>
                  AS-support@swisstxt.ch // +41 44 315 15 84 // MO-FR, 08:00 -
                  17:30
                </Text>
              </div> */}
            </Group>

            {showMessage && (
              <Group className={classes.right_area}>
                <div className={classes.message_button}>
                  <ActionIcon
                    mr={10}
                    onClick={() => setOpenQuestionModal(true)}
                    size={35}
                    variant="outline"
                  >
                    <BsQuestion size={25} color="#000000" />
                  </ActionIcon>
                  {/* <div className={classes.message_notification}></div> */}
                  <ActionIcon
                    onClick={() => {
                      setOpenEditArea(false), openDrawer();
                    }}
                    size={35}
                    color={fixed ? "red":"dark"}
                    variant={fixed ? "filled":"outline"}
                  >
                    <Messages size={25} color={fixed ? "white":"black"} />
                  </ActionIcon>
                </div>
              </Group>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};
const useStyles = createStyles((theme) => ({
  message_notification: {
    width: 10,
    height: 10,
    right: -4,
    top: -4,
    zIndex: 9,
    borderRadius: "50%",
    position: "absolute",
    backgroundColor: theme.colors.green[6],
  },
  message_button: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
  },
  right_drawer: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,.5)",
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 999,
  },
  right_drawer_container: {
    width: "21vw",
    height: "100vh",
    zIndex: 9999,
    position: "absolute",
    right: 0,
    backgroundColor: theme.colors.gray[2],
    [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
      width: "30vw",
    },
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      width: "40vw",
    },
    "@media (max-width: 769px)": {
      width: "100vw",
      position: "fixed",
    },
  },
  footer: {
    height: 70,
    // position: "absolute",
    bottom: 0,
    backgroundColor: theme.white,
    alignItems: "center",
    display: "flex",
    width: "100%", //burası
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    "@media (max-width: 769px)": {
      height: "auto",
      padding: "20px 0px",
    },
  },
  footer_open_message_box: {
    position: "static",
  },
  footer_left: {
    [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
      width: "40%",
    },
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      width: "30%",
    },
    "@media (max-width: 769px)": {
      display: "none",
    },
  },
  footer_left_full: {
    [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
      width: "100%",
    },
    "@media (max-width: 769px)": {
      width: "100% !important",
    },
    "@media (max-width: 550px)": {
      width: "100% !important",
    },
  },
  popover_area: {
    "& mantine-Popover-body": { backgroundColor: "blue !important" },
  },
  popover: {
    height: 230,
    marginLeft: 5,
    overflow: "auto",
  },
  chat: {
    height: 270,
    overflow: "auto",
  },
  chat_input: {
    paddingLeft: 10,
    borderTop: `2px solid ${theme.colors.gray[3]}`,
  },
  inner: {
    display: "flex",
    width: "100%",
    alignItems: "space-between",
  },
  footer_items: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    "@media (max-width: 768px)": {
      flexDirection: "column-reverse",
      alignItems: "center",
    },
  },
  right_area: {
    "@media (max-width: 768px)": {
      marginTop: 40,
      marginBottom: 20,
    },
    "@media (max-width: 550px)": {
      marginTop: 0,
      marginRight: 30,
      width: "100%",
      justifyContent: "flex-end",
    },
  },
  connected_area: {
    backgroundColor: "red",
  },
  disconnect: {
    width: 11,
    height: 10,
    backgroundColor: theme.colors.red,
    borderRadius: "50%",
  },
  text: {
    color: theme.colors.gray[6],
  },
}));
export default Footer;
