import { useAppDispatch, useAppSelector } from "../store/hooks";
import { AppDispatch, RootState } from "../store/store";

import restClient from "../api/restClient";
import {
  setMembers,
  setCurrentMember,
  setLoading,
} from "../store/reducers/member.reducer";
import { IOrganizationMember } from "../interfaces/organization-member.interface";
import ICreateMember from "../interfaces/create-member.interface";
import getAuthHeader from "../utils/getAuthHeader";
import { IOrganizationMembers } from "../interfaces/organization-members.interface";

export const useMembers = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const members: IOrganizationMember[] = useAppSelector(
    (state: RootState) => state.member.members
  );
  const currentMember: IOrganizationMember = useAppSelector(
    (state: RootState) => state.member.currentMember
  );
  const loading: IOrganizationMember = useAppSelector(
    (state: RootState) => state.member.loading
  );

  const createMember = async (data: ICreateMember) => {
    dispatch(setLoading(true));
    try {
      const config = await getAuthHeader();
      const response = await restClient.post("/members", data, config);
      const member: IOrganizationMember = response.data;
      dispatch(setLoading(false));
      return member;
    } catch (e) {
      dispatch(setLoading(false));
      return e;
    }
  };

  const memberUpdate = async (
    memberId: string,
    id: string,
    data: ICreateMember
  ) => {
    dispatch(setLoading(true));
    try {
      const config = await getAuthHeader();
      const response = await restClient.patch(
        "/members/" + memberId,
        data,
        config
      );
      const events: IOrganizationMember = response.data;
      if (events) {
        getAllMembers(id);
        dispatch(setLoading(false));
      }
    } catch (e) {
      dispatch(setLoading(false));
      return e;
    }
  };

  const getAllMembers = async (id: string) => {
    try {
      const config = await getAuthHeader();
      const response = await restClient.get(
        "/members/organization/" + id,
        config
      );
      const members: IOrganizationMembers[] = response.data;
      if (members) {
        dispatch(setMembers(members));
      }
    } catch (e) {
      return e;
    }
  };

  const getMemberData = async (id: string) => {
    try {
      const config = await getAuthHeader();
      const response = await restClient.get("/members/" + id, config);
      const member: IOrganizationMember = response.data;
      if (member) {
        dispatch(setCurrentMember(member));
      }
    } catch (e) {
      return e;
    }
  };

  const removeMember = async (id: string, memberId: string) => {
    try {
      const config = await getAuthHeader();
      const response = await restClient.delete("/members/" + memberId, config);
      const member: IOrganizationMember = response.data;
      if (member) {
        getAllMembers(id);
      }
    } catch (e) {
      return e;
    }
  };

  const handlers = {
    getAllMembers: getAllMembers,
    createMember: createMember,
    removeMember: removeMember,
    memberUpdate: memberUpdate,
    getMemberData: getMemberData,
  };

  return {
    handlers,
    members,
    currentMember,
    loading,
  };
};
