import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  onAuthStateChanged,
  updatePassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  User as FirebaseUser,
  UserCredential,
  sendEmailVerification,
} from "firebase/auth";

import {
  getStorage,
  uploadBytes,
  ref,
  uploadBytesResumable,
  listAll,
  getDownloadURL,
  deleteObject
} from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getAnalytics, logEvent } from "firebase/analytics";

import config from "../config/index";

const firebaseConfig = config.firebase;

const firebaseApp = initializeApp(firebaseConfig);

// const storage = getStorage(firebaseApp);

//init services
const auth = getAuth();
const db = getFirestore(firebaseApp);
const database = getDatabase(firebaseApp);
const analytics = getAnalytics(firebaseApp);
const storage = getStorage(firebaseApp);

const logEventAnalytics = (eventName: string, params?: any) => {
  if (!params) params = {};
  logEvent(analytics, eventName, params);
};

export {
  firebaseApp,
  database,
  auth,
  db,
  storage,
  ref,
  uploadBytes,
  listAll,
  getDownloadURL,
  deleteObject,
  uploadBytesResumable,
  createUserWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  sendEmailVerification,
  logEventAnalytics,
};
export type { FirebaseUser, UserCredential };
