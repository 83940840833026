import { IProfile } from './../interfaces/profile.interface';
import { useState, useEffect } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";

import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import getAuthHeader from "../utils/getAuthHeader";

declare global {
  interface Window {
    client: any;
  }
}

export default function useAgora(profile?: IProfile) {
  let uid: string | null = null;

  if (!profile || !profile.uid) {
    uid = uuidv4() + "-" + "Speaker";
  } else {
     uid = profile?.uid + "-666-" + profile.firstName;
  }

  const LEAVING_IN_MINUTES = 10;

  const [localAudioTrack, setLocalAudioTrack] = useState<any>(undefined);

  const [joinState, setJoinState] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [clientInitialized, setClientInitialized] = useState(false);
  const [client, setClient] = useState<any>(null);
  const [leavingIn, setLeavingIn] = useState<any>(undefined);
  const [leavingTimeTimer, setLeavingTimeTimer] = useState<any>(undefined);

  const [remoteUsers, setRemoteUsers] = useState([]);
  const [remoteUsersNames, setRemoteUserName] = useState<any>([]);


  async function init(_client?: any) {
    if (!_client) {
      _client = AgoraRTC.createClient({ codec: "h264", mode: "live" });
    }

    AgoraRTC.setLogLevel(4);

    setClient(_client);
    setClientInitialized(true);
  }

  async function createLocalTracks(audioConfig?: any) {
    const microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack(
      audioConfig
    );
    setLocalAudioTrack(microphoneTrack);
    return [microphoneTrack];
  }

  async function join(channel: string, role: "audience" | "host") {
    if (!client) {
      return;
    }
    if (!channel) {
      return;
    }

    if (!role) {
      return;
    }

    setConnecting(true);
    try {
      setJoinState(false);
      if (!client) return;

      // const token = (await functions.httpsCallable('getAgoraToken', {})({ channelName: channel, uid })).data;

      const config = await getAuthHeader();
      const req = {
        data: {
          channelName: channel,
          uid: uid,
        },
      };

      const res = await axios.post(
        `https://europe-west3-interpreteri.cloudfunctions.net/getAgoraToken`,
        req,
        config
      );

      const token = res.data.result;

      client.setClientRole(role, role === "host" ? undefined : { level: 1 });

      if (role === "host") {
        const [microphoneTrack] = await createLocalTracks();
        await client.join(
          "b93de4f7d3ff48689e92b81f2ff0b70a",
          channel,
          token || null,
          uid
        );
        await client.publish([microphoneTrack]);
      } else {
        await client.join(
          "b93de4f7d3ff48689e92b81f2ff0b70a",
          channel,
          token || null,
          uid
        );
      }

      window.client = client;

      setJoinState(true);
    } finally {
      setConnecting(false);
    }
  }

  async function leave() {
    if (localAudioTrack) {
      localAudioTrack.stop();
      localAudioTrack.close();
    }
    setRemoteUsers([]);
    setJoinState(false);
    await client?.leave();
  }

  const handleLeave = async () => {
    try {
      setLeavingIn(undefined);
      await leave();
      // close();
    } catch {}
  };

  const getRemoteUsersNames = (rUsers: any[]) => {
    const userUids = rUsers.map((user: any) => user.uid);

    return userUids.map((uid: string) => {
      const pieces = uid.split("-666-");
      const name = pieces[pieces.length - 1]
      const profileUid = pieces[0]
      return {profileUid, name};
    });

  };

  useEffect(() => {
    if (!client) return;
    setRemoteUsers(client.remoteUsers);

    const handleUserPublished = async (user: any, mediaType: any) => {
      await client.subscribe(user, mediaType);
      // toggle rerender while state of remoteUsers changed.
      console.log("toggle rerender while state of remoteUsers", user);
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
    };
    const handleUserUnpublished = (user: any) => {
      console.log("handleUserUnpublished", user);

      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
    };
    const handleUserJoined = (user: any) => {
      console.log("user joined", user);
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
      const names = getRemoteUsersNames(client.remoteUsers);
      console.log("names", names);
      setRemoteUserName(names);
    };
    const handleUserLeft = (user: any) => {
      console.log("handleUserLeft", user);
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
      const names = getRemoteUsersNames(client.remoteUsers);
      console.log("names", names);
      setRemoteUserName(names);
    };

    client.on("user-published", handleUserPublished);
    client.on("user-unpublished", handleUserUnpublished);
    client.on("user-joined", handleUserJoined);
    client.on("user-left", handleUserLeft);

    return () => {
      client.off("user-published", handleUserPublished);
      client.off("user-unpublished", handleUserUnpublished);
      client.off("user-joined", handleUserJoined);
      client.off("user-left", handleUserLeft);
    };
  }, [client]);

  useEffect(() => {
    if (remoteUsers && remoteUsers.length === 0 && joinState) {
      setLeavingIn(LEAVING_IN_MINUTES * 60);
    } else if (remoteUsers && remoteUsers.length > 0) {
      setLeavingIn(undefined);
    }
  }, [remoteUsers, joinState]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleLeave);
    window.addEventListener("unload", handleLeave);
    return () => {
      window.removeEventListener("beforeunload", handleLeave);
      window.removeEventListener("unload", handleLeave);
    };
  });

  /*
  // TODO: make this work
  console.log('LEAVING IN', leavingIn);

  useEffect(() => {
    if (leavingIn !== undefined && leavingIn > 0) {
      setLeavingTimeTimer(
        setTimeout(() => {
          if (
            leavingIn !== undefined &&
            (!remoteUsers || remoteUsers.length === 0)
          ) {
            setLeavingIn(leavingIn - 1);
          } else {
            setLeavingIn(undefined);
          }
        }, 1000)
      );
    } else if (leavingIn <= 0) {
      handleLeave();
    }

    return () => {
      if (leavingTimeTimer) {
        clearTimeout(leavingTimeTimer);
      }
    }
  }, []);

  */

  return {
    localAudioTrack,
    joinState,
    leave,
    join,
    init,
    remoteUsers,
    remoteUsersNames,
    connecting,
    clientInitialized,
  };
}
