import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  Center,
  Group,
  Text,
  Button,
  Title,
  Image,
  createStyles,
} from "@mantine/core";
import Layout from "../../components/low-level/layout/publicLayout";
const DownloadPage = () => {
  const { classes } = useStyles();
  const [os, setOS] = useState("mac");

  useEffect(() => {
    const OS: any = window.navigator;
    setOS(OS?.platform);
  }, []);

  const otherOS = (e: any) => {
    setOS(e);
  };
  return (
    <Layout>
      <div
        style={{
          overflow: "hidden",
          height: "100%",
        }}
      >
        <Container
          size="xl"
          style={{ display: "flex", alignItems: "center", height: "100%" }}
        >
          <Card
            style={{
              width: "100%",
              backgroundColor: "#c90900",
              height: "70vh",
            }}
          >
            <div className={classes.main}>
              {["win32", "win16", "windows", "android"].includes(
                os.toLowerCase()
              ) ? (
                <Image
                  className={classes.logo}
                  alt="Live Access"
                  fit="contain"
                  src={require("../../assets/download/windows.png")}
                />
              ) : (
                <Image
                  className={classes.logo}
                  alt="Live Access"
                  fit="contain"
                  src={require("../../assets/download/macos.png")}
                />
              )}
              <div className={classes.text_area}>
                <Title color="white" mb="xl" className={classes.title}>
                  Hello 👋
                </Title>
                <Title color="white" mb="xl" className={classes.title}>
                  You can download the LiveAccess Desktop Application here:
                </Title>
                {["win32", "android"].includes(os.toLowerCase()) ? (
                  <>
                    <Button
                      mt="xl"
                      component="a"
                      className={classes.download_button}
                      href="https://github.com/korayaggul/liveaccess-desktopapp-download/blob/main/windows/LiveAccess.msi?raw=true"
                    >
                      Download for Windows
                    </Button>

                    <Text mt={5} size="sm" align="right">
                      Also available on{" "}
                      <button
                        onClick={() => otherOS("mac")}
                        className={classes.other_os_buttton}
                      >
                        macOS
                      </button>
                    </Text>
                  </>
                ) : (
                  <>
                    <Button
                      mt="xl"
                      component="a"
                      className={classes.download_button}
                      href="https://github.com/korayaggul/liveaccess-desktopapp-download/blob/main/macOS/LiveAccess-arm64.dmg?raw=true"
                    >
                      <span className={classes.download_button_text}>
                        {" "}
                        Download for Mac M Processors
                      </span>
                    </Button>
                    <Button
                      mt="xl"
                      component="a"
                      className={classes.download_button}
                      href="https://github.com/korayaggul/liveaccess-desktopapp-download/blob/main/macOS/LiveAccess.dmg?raw=true"
                    >
                      <span className={classes.download_button_text}>
                        Download for Mac Intel Processors
                      </span>
                    </Button>
                    <Text mt={5} size="sm" align="right">
                      Also available on{" "}
                      <button
                        onClick={() => otherOS("win32")}
                        className={classes.other_os_buttton}
                      >
                        Windows
                      </button>
                    </Text>
                  </>
                )}
              </div>
            </div>
          </Card>
        </Container>
      </div>
    </Layout>
  );
};
const useStyles = createStyles((theme) => ({
  main: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    "@media (max-width: 768px)": {
      flexDirection: "column",
      justifyContent: "space-evenly",
    },
  },
  other_os_buttton: {
    backgroundColor: "transparent",
    border: "0px",
    cursor: "pointer",
    color: "white",
    textDecoration: "underline",
    fontWeight: "bold",
  },
  download_button: {
    width: "100%",
    backgroundColor: "white",
    color: "black",
    height: "70px",
    transition: "all .5s",
    marginTop: 10,
    "&:hover": {
      backgroundColor: "#ededed",
      transform: "scale(1.02)",
      transition: "all .5s",
    },
  },
  download_button_text: {
    "@media (max-width: 768px)": {
      fontSize: 15,
    },
  },
  download_other: {
    color: "wihte",
  },
  text_area: {
    width: "50%",
    color: "white",
    "@media (max-width: 768px)": {
      width: "100%",
      height: "80%",
    },
  },
  title: {
    "@media (max-width: 768px)": {
      fontSize: 20,
      marginBottom: 10,
    },
  },
  logo: {
    width: "35%",
    "@media (max-width: 768px)": {
      width: "32vw",
    },
    "&:figure": {
      backgroundColor: "red",
    },
  },
}));

export default DownloadPage;
