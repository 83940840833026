import React, { useState, Dispatch, SetStateAction, useEffect } from "react";
import {
  Group,
  SimpleGrid,
  createStyles,
  Input,
  InputWrapper,
  Checkbox,
  Select,
} from "@mantine/core";
import { Plus, Trash } from "tabler-icons-react";
import { useForm } from "react-hook-form";
import Button from "../button/button";
import { useTranslation } from "react-i18next";
interface IProps {
  setValue: Dispatch<SetStateAction<any>>;
  value: any;
  nextStep: () => void;
  prevStep: () => void;
}

const NewEventSettings: React.FunctionComponent<IProps> = (props: IProps) => {
  const { t } = useTranslation("common");
  const [next, setNext] = useState(false);
  const [videoTypes, setVideoTypes] = useState(props.value.sources.type);
  const [otherWebsite, setOtherWebsite] = useState(props.value.otherWebsites);
  const [showPlayer, setShowPlayer] = useState(props.value.showPlayer);
  const [showDownload, setShowDownload] = useState(props.value?.showDownload);
  const [showSignLanguage, setShowSignLanguage] = useState(
    props.value?.showSignLanguage
  );
  const [richText, setRichText] = useState(props.value.isRichText);
  const [audioStreaming, setAudioStreaming] = useState(
    props.value.isAudioStreaming
  );
  const [urls, setUrls]: any = useState(props.value.urls);
  const { classes } = useStyles();

  const videoTypesOptions = [
    { value: "application/x-mpegURL", label: "Live Stream" },
    { value: "video/youtube", label: "Youtube" },
    { value: "video/facebook", label: "Facebook" },
    { value: "video/vimeo", label: "Vimeo" },
    { value: "video/mp4", label: "mp4" },
  ];
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const handleAddUrl = () => {
    if (props.value.urls.length <= 9)
      setUrls([...urls, { title: "", url: "" }]);
  };

  useEffect(() => {
    if (props.value.isPrivate) {
      setRichText(props.value.isRichText);
    } else {
      setRichText(false);
    }
  }, [props.value.isPrivate]);

  const handleRemoveUrl = (index: number) => {
    const newUrls = [...urls];
    var deneme2 = newUrls.filter((x: any, i: number) => i !== index);
    // newUrls.splice(index, 1);
    setUrls(deneme2);
  };
  const getSettingsDetail = () => {
    setNext(true);
    var _urls: any = [];
    urls.map((x: any, i: number) => {
      _urls.push({ title: watch(`title${i}`), url: watch(`url${i}`) });
    });
    var sources = [{ src: watch("sourcesSrc"), type: videoTypes }];
    props.setValue({
      ...props.value,
      urls: _urls,
      sources: sources,
      showPlayer: showPlayer,
      otherWebsites: otherWebsite,
      isRichText: richText,
      isAudioStreaming: audioStreaming,
      showDownload: showDownload,
      showSignLanguage: showSignLanguage,
    });
    setNext(false);
    props.nextStep();
  };
  return (
    <SimpleGrid cols={1} className={classes.container}>
      <InputWrapper
        id="event-name-wrapper"
        label={t("events.settings")}
        style={{ width: "100%" }}
      >
        {props.value.isPrivate && (
          <Checkbox
            color="red"
            checked={audioStreaming}
            onChange={(event) => setAudioStreaming(event.currentTarget.checked)}
            value={"isAudioStreaming"}
            label={t("events.audio_streaming")}
          />
        )}
      </InputWrapper>
      {props.value.isPrivate && (
        <Checkbox
          color="red"
          checked={richText}
          onChange={(event) => setRichText(event.currentTarget.checked)}
          value={"isRichText"}
          label={t("events.rich_text")}
        />
      )}
      <Checkbox
        color="red"
        checked={showDownload}
        onChange={(event) => setShowDownload(event.currentTarget.checked)}
        value={"showDownload"}
        label={t("events.show_download")}
      />
      <Checkbox
        color="red"
        checked={showSignLanguage}
        onChange={(event) => setShowSignLanguage(event.currentTarget.checked)}
        value={"showSignLanguage"}
        label={t("events.show_sign_language")}
      />
      <Checkbox
        value={"showPlayer"}
        checked={showPlayer}
        onChange={(event) => setShowPlayer(event.currentTarget.checked)}
        color="red"
        label={t("events.add_player")}
      />
      {showPlayer && (
        <>
          <Select
            label={t("events.platform")}
            placeholder={t("events.platform")}
            color="red"
            value={videoTypes}
            onChange={(e: any) => setVideoTypes(e)}
            data={videoTypesOptions}
          />
          <InputWrapper
            id="event-name-wrapper"
            required
            label={t("events.url")}
            // error={eventNameError()}
            style={{ width: "100%" }}
          >
            <Input
              id="link"
              defaultValue={props.value.sources.src}
              placeholder={t("events.platform_link")}
              {...register("sourcesSrc")}
              required
            />
          </InputWrapper>
        </>
      )}
      <Checkbox
        color="red"
        checked={otherWebsite}
        onChange={(event) => setOtherWebsite(event.currentTarget.checked)}
        value={"otherWebsites"}
        label={t("events.add_a_website_link")}
      />
      {otherWebsite && (
        <>
          {urls?.map((url: any, i: number) => {
            return (
              <span key={i}>
                <Group style={{ alignItems: "flex-end" }}>
                  <Group style={{ width: "89%" }}>
                    <InputWrapper
                      id="event-name-wrapper"
                      required
                      label={t("events.title")}
                      // error={eventNameError()}
                      style={{ width: "100%" }}
                    >
                      <Input
                        id={`urls.${i}.title`}
                        {...register(`title${i}`)}
                        defaultValue={urls[i]?.title}
                        placeholder={t("events.title")}
                        required
                      />
                    </InputWrapper>
                    <InputWrapper
                      id="event-name-wrapper"
                      required
                      label={t("events.url")}
                      // error={eventNameError()}
                      style={{ width: "100%" }}
                    >
                      <Input
                        id={`urls.${i}.url`}
                        placeholder={t("events.url")}
                        defaultValue={urls[i]?.url}
                        {...register(`url${i}`)}
                        required
                      />
                    </InputWrapper>
                  </Group>
                  <Button
                    loading={false}
                    color="red"
                    onClick={() => handleRemoveUrl(i)}
                  >
                    <Trash />
                  </Button>
                </Group>
              </span>
            );
          })}
          {urls.length < 10 && (
            <Button loading={false} color="green" onClick={handleAddUrl}>
              <Plus />
            </Button>
          )}
        </>
      )}
      <Group position="apart">
        <Button
          text={t("back")}
          loading={false}
          variant="default"
          onClick={props.prevStep}
        />

        <Button
          text={t("next_step")}
          loading={false}
          color="red"
          onClick={getSettingsDetail}
        />
      </Group>
    </SimpleGrid>
  );
};
const useStyles = createStyles((theme) => ({
  container: {
    width: "80%",
    "@media (max-width: 769px)": {
      width: "100% !important",
    },
  },
}));
export default NewEventSettings;
