import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Group,
  createStyles,
  Select,
  Button,
  Text,
  Center,
} from "@mantine/core";
import { useParams, useNavigate } from "react-router-dom";
import { Mail } from "tabler-icons-react";
import Layout from "../../components/low-level/layout";
import { useTranslation } from "react-i18next";
import { useMembers } from "../../hooks/members.hook";

const EditEventPage: React.FunctionComponent = (props) => {
  let { memberId, id } = useParams();
  let navgiate = useNavigate();
  const { classes } = useStyles();
  const { t } = useTranslation("common");
  const {
    handlers: membersHandlers,
    currentMember,
    members,
  }: any = useMembers();
  const [selectRole, setSelectRole] = useState();
  const roles = [
    { value: "Admin", label: t("admin") },
    { value: "Interpreter", label: t("interpreter") },
    { value: "User", label: t("user") },
    { value: "Owner", label: t("owner") },
    { value: "Customer", label: t("customer") },
    { value: "SignLanguage", label: t("sign_language") },
  ];

  useEffect(() => {
    if (memberId) {
      membersHandlers.getMemberData(memberId);
    }
  }, [memberId]);

  useEffect(() => {
    setSelectRole(currentMember?.roles[0]);
  }, [currentMember]);

  const handleSubmit = () => {
    const data = {
      profile: currentMember?.profile.id,
      organization: id,
      roles: selectRole,
    };
    membersHandlers.memberUpdate(memberId, id, data);
    navgiate(-1);
  };
  return (
    <Layout
      navigate={`/organizations/${id}/members`}
      page="edit"
      eventName={t("members.edit_member")}
    >
      <Container p="md" pb="md" size="md">
        <Card>
          <Center style={{ width: "100%" }}>
            <Card className={classes.card} shadow="sm" p="lg">
              <Group grow className={classes.box}>
                <Group className={classes.mail_box}>
                  <Mail />
                  <Text className={classes.email}>
                    {currentMember?.profile?.email}
                  </Text>
                </Group>
                <Select
                className={classes.select}
                  placeholder={t("members.select_role")}
                  value={selectRole}
                  //   error={!selectRole && t("role_select")}
                  onChange={(e: any) => setSelectRole(e)}
                  data={roles}
                />
              </Group>
            </Card>
          </Center>
          <Group position="apart" mt="xs">
            <div></div>
            <Button color="green" onClick={handleSubmit}>
              {t("save")}
            </Button>
          </Group>
        </Card>
      </Container>
    </Layout>
  );
};
const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.white[1],
    width: "100%",
  },
  select:{
    "@media (max-width: 768px)": {
      width: "100%",
      maxWidth: "100%",
    },
  },
  box: {
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  },
  mail_box: {
    "@media (max-width: 768px)": {
      width: "100%",
      maxWidth: "100%",
    },
  },
  email: {
    "@media (max-width: 768px)": {
      whiteSpace: "nowrap",
      width: "80%",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
}));
export default EditEventPage;
