import React, { useState, useEffect } from "react";
import {
  createStyles,
  Menu,
  Header,
  Container,
  Image,
  Group,
  Text,
  ActionIcon,
  Title,
} from "@mantine/core";
import { useParams } from "react-router-dom";
import {
  BsQuestionSquare,
  BsInfoSquare,
  BsInfoSquareFill,
  BsQuestionSquareFill,
} from "react-icons/bs";
import SupportMailBox from "../supportMailBox/supportMailBox";
import { useTranslation } from "react-i18next";

const HEADER_HEIGHT = 60;
type Props = {
  openWebSite: any;
  website: boolean;
  mobile: boolean;
  eventName: string;
  otherWebsites: boolean;
  urls: any;
  logo: any;
};

const PublicHeader = (props: Props) => {
  let { id } = useParams();
  const { classes } = useStyles();
  const [openQuestionModal, setOpenQuestionModal] = useState(false);
  const { t, i18n } = useTranslation("common");

  const items = () => {
    return (
      <Group>
        {/* <ActionIcon onClick={() => setOpenQuestionModal(true)} size="lg">
          {openQuestionModal ? (
            <BsQuestionSquareFill style={{ width: "100%" }} size="lg" />
          ) : (
            <BsQuestionSquare style={{ width: "100%" }} size="lg" />
          )}
        </ActionIcon> */}
        {props.otherWebsites && props?.urls?.length == 1 && (
          <ActionIcon
            tabIndex={-1}
            size="lg"
            aria-label={`Information Website Button ${props.urls[0].url}`}
            lang="en"
            onClick={() => props.openWebSite(props.urls[0].url)}
          >
            {props.website ? (
              <BsInfoSquareFill style={{ width: "100%" }} size="lg" />
            ) : (
              <BsInfoSquare style={{ width: "100%" }} size="lg" />
            )}
          </ActionIcon>
        )}
        {props.otherWebsites && props?.urls?.length > 1 && (
          <Menu
            tabIndex={-1}
            control={
              <ActionIcon
                tabIndex={-1}
                size="lg"
                aria-label={`Information Websites Button`}
                lang="en"
                className={`${props.website && classes.active}`}
              >
                <BsInfoSquare style={{ width: "100%" }} size="lg" />
              </ActionIcon>
            }
          >
            <Menu.Label>Websites</Menu.Label>
            {props.urls.map((x: any, index: number) => (
              <Menu.Item onClick={() => props.openWebSite(x.url)} key={index}>
                <h1
                  aria-label={`${x.title}`}
                  lang="en"
                  style={{ fontSize: "18px" }}
                >
                  {x.title}
                </h1>
              </Menu.Item>
            ))}
          </Menu>
        )}
      </Group>
    );
  };
 
  return (
    <>
      <SupportMailBox
        openModal={openQuestionModal}
        setOpenModal={setOpenQuestionModal}
      />
      {/* full screen setting buttons area */}
      <Header height={HEADER_HEIGHT} className={classes.header}>
        <Container className={classes.inner} fluid>
          <Group className={classes.header_item}>
            {props.logo == undefined ? (
              <a tabIndex={-1} href="/organizations">
                <Image
                  className={classes.logo}
                  height={80}
                  lang="en"
                  alt="Live Access Logo"
                  fit="contain"
                  src={require("../../../assets/logo.png")}
                />
              </a>
            ) : (
              <a tabIndex={-1} href={props?.logo?.url}>
                <Image
                  className={classes.logo}
                  height={40}
                  lang="en"
                  alt="Live Access Logo"
                  fit="contain"
                  src={props.logo?.img}
                />
              </a>
            )}
          </Group>
          <Group
            position="center"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className={classes.header_item}
          >
            <h1
              tabIndex={0}
              id="eventTitle"
              className={classes.event_name}
              lang={i18n?.language}
              aria-label={`${props.eventName}`}
            >
              {props.eventName}
            </h1>
          </Group>
          <Group position="right" className={classes.header_item}>
            {items()}
          </Group>
        </Container>
      </Header>
     
    </>
  );
};
const useStyles = createStyles((theme) => ({
  event_name: {
    fontSize: "18px",
    "@media (max-width: 1000px)": {
      marginLeft: 10,
      width: "90%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      justifyContent: "flex-start",
    },
    "@media (max-width: 768px)": {
      marginLeft: 10,
      maxWidth: "40vw",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      justifyContent: "flex-start",
    },
  },
  inner: {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  header: {
    height: "7vh",
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },
  active: {
    backgroundColor: theme.colors.red[2],
    ":hover": {
      backgroundColor: theme.colors.red[3],
    },
  },
  logo: {
    width: 120,
    "@media (max-width: 768px)": {
      width: 100,
    },
  },
  header_item: {
    width: "100%",
  },

}));
export default PublicHeader;
