import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOrganizationMember } from "../../interfaces/organization-member.interface";
import { IOrganizationMembers } from "../../interfaces/organization-members.interface";

interface IMemberState {
  members: IOrganizationMembers[] | null;
  currentMember: IOrganizationMember | null;
}

const initialstate: IMemberState = {
  members: [],
  currentMember: null,
};
export const slice: any = createSlice({
  name: "members",
  initialState: initialstate,
  reducers: {
    setLoading: (state: any, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setMembers: (state: any, action: PayloadAction<IOrganizationMember[]>) => {
      state.members = action.payload;
    },
    setCurrentMember: (
      state: any,
      action: PayloadAction<IOrganizationMember>
    ) => {
      state.currentMember = action.payload;
    },
  },
});

export const { setLoading, setMembers, setMember, setCurrentMember } =
  slice.actions;

export default slice.reducer;
