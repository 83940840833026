import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useRef,
} from "react";
import {
  Title,
  Group,
  NumberInput,
  Text,
  Select,
  createStyles,
  TextInput,
  Card,
  SimpleGrid,
  CheckboxGroup,
  Checkbox,
  InputWrapper,
  Input,
  Accordion,
  Popover,
} from "@mantine/core";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useEvents } from "../../../hooks/event.hook";
import { BiLink } from "react-icons/bi";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Language,
  User,
  Browser,
  Settings,
  PlayerPlay,
  Plus,
  Trash,
  Edit,
} from "tabler-icons-react";
import Switch from "../switch";
import ICreateEvent from "../../../interfaces/create-event.interface";
import Button from "../button/button";
import { useSelector } from "react-redux";
import { MembersSelect } from "../select";
import { IoPeopleOutline } from "react-icons/io5";
import QRCode from "react-qr-code";

interface IProps {
  setValue: Dispatch<SetStateAction<any>>;
  setIsPrivate?: any;
  value: any;
  nextStep?: () => void;
  prevStep?: () => void;
  pageName?: string;
  setOpenEditArea?: any;
}
const EventComplete: React.FunctionComponent<IProps> = (props: IProps) => {
  const { handlers: eventHandlers, events } = useEvents();
  let location: any = useLocation();
  const { id }: any = useParams();

  let navigate = useNavigate();
  const [videoTypes, setVideoTypes] = useState(props.value?.sources[0]?.type);
  const [otherWebsite, setOtherWebsite] = useState(props.value?.otherWebsites);
  const [showPlayer, setShowPlayer] = useState(props.value?.showPlayer);
  const [eventName, setEventName] = useState(props.value?.name);
  const [richText, setRichText] = useState(props.value?.isRichText);
  const [isAudio, setIsAudio] = useState(props.value?.isAudioStreaming);
  const [_private, setPrivate] = useState(props.value?.isPrivate);
  const [showDownload, setShowDownload] = useState(props.value?.showDownload);
  const [showSignLanguage, setShowSignLanguage] = useState(
    props.value?.showSignLanguage
  );
  const [showVideoUrl, setShowVideoUrl] = useState(false);
  const [fontSizeOpen, setFontSizeOpen] = useState(false);
  const [liveTextDelayShow, setLiveTextDelayShow] = useState(false);
  const [liveTextDelay, setLiveTextDelay] = useState(0);

  const [splitUrl, setSplitUrl] = useState(false);
  const [translations, setTranslations] = useState(props.value?.translations);
  const [urls, setUrls]: any = useState(props.value?.urls);
  const [eventMembers, setEventMembers] = useState(props.value.eventMembers);
  const [mobile, setMobile] = useState(false);
  const [cancelEvent, setCancelEvent] = useState(false);
  const [copied, setCopied] = useState(false);
  const [copiedEventUrl, setCopiedEventUrl] = useState(false);
  const [videoLinkError, setVideoLinkError] = useState(false);
  const [videoTypeError, setVideoTypeError] = useState(false);
  const [variation, setVariation] = useState<string | null>("null");
  const [color, setColor] = useState<string | null>("null");
  const [fontSizeUrl, setFontSizeUrl] = useState(16);
  const [fontSizeUrl2, setFontSizeUrl2] = useState<string | null>("16");
  const [languageUrlData, setLanguageUrlData] = useState<any | null>([]);

  const [leftLanguageUrl, setLeftLanguageUrl] = useState<string | null>("null");
  const [rightLanguageUrl, setRightLanguageUrl] = useState<string | null>(
    "main"
  );

  const loading: any = useSelector((action: any) => action.event.loading);

  useEffect(() => {
    if (location.pathname == `/event/${id}`) props.setIsPrivate(_private);
  }, [_private, location.pathname]);

  const { classes } = useStyles();
  const { t } = useTranslation("common");

  useEffect(() => {
    setPrivate(props.value?.isPrivate);
    setShowPlayer(props.value?.showPlayer);
    setOtherWebsite(props.value?.otherWebsites);
    setTranslations(props.value?.translations);
    setIsAudio(props.value?.isAudioStreaming);
    setRichText(props.value?.isRichText);
    setShowDownload(props.value?.showDownload);
    setUrls(props.value?.urls);
    setVideoTypes(props.value?.sources[0]?.type);
    setEventName(props.value?.name);
    setShowSignLanguage(props.value?.showSignLanguage);
  }, [props.value, cancelEvent]);

  const languages = [
    { value: "en", label: t("en") },
    { value: "de", label: t("de") },
    { value: "it", label: t("it") },
    { value: "fr", label: t("fr") },
    { value: "uk", label: t("uk") },
    { value: "rm", label: t("rm") },
  ];

  useEffect(() => {
    var languageUrlData = languages.filter((x: any) => {
      return translations.includes(x.value);
    });
    languageUrlData.unshift(
      { value: "null", label: t("empty") },
      { value: "main", label: t("source") },
      { value: "pre", label: t("Pre") }
    );
    setLanguageUrlData(languageUrlData);
  }, []);

  const videoTypesOptions = [
    { value: "application/x-mpegURL", label: "Live Stream" },
    { value: "video/youtube", label: "Youtube" },
    { value: "video/facebook", label: "Facebook" },
    { value: "video/vimeo", label: "Vimeo" },
    { value: "video/mp4", label: "mp4" },
  ];
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (window?.innerWidth < 800) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);
  useEffect(() => {
    if (!_private) setRichText(false);
  }, [_private]);

  useEffect(() => {
    if (videoTypes != undefined) {
      setVideoTypeError(false);
    }
    if (watch("sourcesSrc")?.length > 6) {
      setVideoLinkError(false);
    }
  }, [videoTypes, watch("sourcesSrc")]);

  const handleSubmitEvent = async () => {
    var _urls: any = [];
    await urls.map((x: any, i: number) => {
      _urls.push({ title: watch(`title${i}`), url: watch(`url${i}`) });
    });
    const orgaId =
      props.pageName == "newEvent" ? id : props.value.organization.id;
    let data: ICreateEvent;

    var sources = [{ src: watch("sourcesSrc"), type: videoTypes }];
    if (props.pageName == "editEvent") {
      data = {
        name: eventName,
        isPrivate: _private,
        isRichText: _private ? richText : false,
        isAudioStreaming: _private ? isAudio : false,
        otherWebsites: otherWebsite,
        translations: !richText ? translations : [],
        showDownload: showDownload,
        showSignLanguage: showSignLanguage,
      };
    } else {
      data = {
        name: eventName,
        organization: orgaId,
        isPrivate: _private,
        showPlayer: showPlayer,
        livePreviewLogos: [],
        isPreviewCustomLogo: false,
        isRichText: _private ? richText : false,
        isAudioStreaming: _private ? isAudio : false,
        urls: otherWebsite ? _urls : [],
        sources: showPlayer ? sources : [],
        otherWebsites: otherWebsite,
        translations: !richText ? translations : [],
        participants: eventMembers,
        showDownload: showDownload,
        showSignLanguage: showSignLanguage,
      };
    }

    var videoControl = showPlayer
      ? watch("sourcesSrc")?.length > 6 && videoTypes != undefined
      : true;
    var eventId: any = localStorage.getItem("eventId");

    if (!videoControl) {
      if (watch("sourcesSrc")?.length < 6) {
        setVideoLinkError(true);
      }
      if (videoTypes == undefined) {
        setVideoTypeError(true);
      }
    }

    if (eventName?.length > 2) {
      props.pageName == "newEvent"
        ? await eventHandlers.createEvent(data)
        : (await eventHandlers.eventUpdate(
            eventId,
            props.value?.shortUid,
            data
          ),
          window.scrollTo({ top: 0, behavior: "smooth" }));
      if (props.pageName == "newEvent") {
        navigate(`/organizations/${orgaId}/events`);
      }
    }
  };

  const handleAddUrl = () => {
    if (props.value?.urls?.length <= 9)
      setUrls([...urls, { title: "", url: "" }]);
  };

  const handleRemoveUrl = (index: number) => {
    const newUrls = [...urls];
    var _newUrls = newUrls.filter((x: any, i: number) => i !== index);
    // newUrls.splice(index, 1);
    setUrls(_newUrls);
  };

  const copyLink = () => {
    setCopied(true);

    const el = document.createElement("input");
    el.value = props.value?.passUid;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setTimeout(() => {
      setCopied(false);
    }, 500);
  };

  const copyCreateUrl = () => {
    setCopiedEventUrl(true);

    const el = document.createElement("input");
    el.value = urlDetail();

    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setTimeout(() => {
      setCopiedEventUrl(false);
    }, 500);
  };
  const nameMin = eventName?.length < 3;
  const nameMax = eventName?.length > 40;

  const eventNameError = () => {
    var error: any = null;
    if (nameMin) error = t("events.min_char");
    else if (nameMax) error = t("events.max_char");
    else error = false;
    return error;
  };
  const handleCancelEvent = () => {
    if (props.pageName == "newEvent") {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setCancelEvent(true);
      setTimeout(() => {
        setCancelEvent(false);
      }, 100);
    } else {
      props.setOpenEditArea(false);
    }
  };

  const downloadQRCode = () => {
    const svg: any = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx: any = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "qrcode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  const urlQrCode = window.location.origin + "/p/" + props.value?.shortUid;

  const urlDetail = () =>
    window.location.origin +
    "/p/" +
    props.value?.shortUid +
    "?" +
    (showVideoUrl ? "hiddenVideo=" + showVideoUrl : "") +
    (variation != null && variation != "null"
      ? (showVideoUrl ? "&" : "") + "view=" + variation
      : "") +
    (color != null && color != "null"
      ? (showVideoUrl || (variation != null && variation != "null")
          ? "&"
          : "") +
        "color=" +
        color
      : "") +
    (splitUrl
      ? (showVideoUrl ||
        (variation != null && variation != "null") ||
        (color != null && color != "null")
          ? "&"
          : "") +
        "split=" +
        splitUrl +
        (leftLanguageUrl != null ? "&language=" + leftLanguageUrl : "") +
        (rightLanguageUrl != null ? "&secondlanguage=" + rightLanguageUrl : "")
      : leftLanguageUrl != "null"
      ? (showVideoUrl ||
        (color != null && color != "null") ||
        (variation != null && variation != "null")
          ? "&"
          : "") +
        "language=" +
        leftLanguageUrl
      : "") +
    (fontSizeOpen
      ? (showVideoUrl ||
        splitUrl ||
        leftLanguageUrl != "null" ||
        (variation != null && variation != "null") ||
        (color != null && color != "null")
          ? "&"
          : "") +
        "fontSize=" +
        (showVideoUrl
          ? fontSizeUrl
          : variation != "null" && variation == "2"
          ? fontSizeUrl2
          : fontSizeUrl)
      : "") +
    (liveTextDelayShow
      ? (showVideoUrl ||
        splitUrl ||
        leftLanguageUrl != "null" ||
        (variation != null && variation != "null") ||
        fontSizeOpen ||
        (color != null && color != "null")
          ? "&"
          : "") +
        "delay=" +
        liveTextDelay
      : "");
  return (
    <SimpleGrid cols={1} className={classes.container}>
      <Group>
        <Edit size={30} color="green" />
        <Text
          className={`${mobile && classes.name}`}
          weight={"bold"}
          size={"xl"}
          color="green"
        >
          {props.pageName == "newEvent" ? t("events.preview") : t(eventName)}
        </Text>
      </Group>
      <SimpleGrid cols={1}>
        <Card className={classes.card} shadow="sm" p="lg">
          <Group direction="column">
            <Card p="xs" className={classes.card_icon}>
              <Group>
                <User />
                <Text>{t("events.name_event")}</Text>
              </Group>
            </Card>
            <TextInput
              id="name"
              style={{ width: "100%" }}
              label={t("events.event_name")}
              error={eventNameError()}
              onChange={(event) => setEventName(event.currentTarget.value)}
              value={eventName}
              defaultValue={eventName}
              placeholder={t("events.event_name")}
              required
            />
          </Group>
        </Card>
        {window.location.pathname == `/event/${id}` && (
          <>
            <Card className={classes.card} shadow="sm" p="lg">
              <Group direction="column">
                <Card p="xs" className={classes.card_icon}>
                  <Group>
                    <BiLink size={30} />
                    <Text>{t("events.desktop_app_event_id")}</Text>
                  </Group>
                </Card>
                <Group grow style={{ width: "100%" }}>
                  <Group style={{ width: "50%" }} direction="row">
                    <Text>{props.value?.passUid}</Text>
                  </Group>

                  <Group position="right">
                    <div style={{ display: "none" }}>
                      <QRCode id="QRCode" value={urlQrCode} />
                    </div>
                    <Button
                      loading={loading}
                      color={"green"}
                      onClick={downloadQRCode}
                    >
                      <Popover
                        opened={copied}
                        position="right"
                        placement="center"
                        withArrow
                        transition="slide-up"
                        target={t("events.get_qr_code")}
                      >
                        {t("events.copied")}
                      </Popover>
                    </Button>
                    <Button
                      loading={loading}
                      color={"green"}
                      onClick={copyLink}
                    >
                      <Popover
                        opened={copied}
                        position="right"
                        placement="center"
                        withArrow
                        transition="slide-up"
                        target={t("events.copy_link")}
                      >
                        {t("events.copied")}
                      </Popover>
                    </Button>
                  </Group>
                </Group>
              </Group>
            </Card>
            {!props.value.isRichText && (
              <Card className={classes.card} shadow="sm" p="lg">
                <Accordion
                  initialItem={0}
                  multiple
                  styles={{
                    content: { paddingLeft: 0 },
                  }}
                >
                  <Accordion.Item label={t("events.create_url")}>
                    <Group direction="column">
                      <Group grow style={{ width: "100%" }}>
                        <Group style={{ width: "50%" }} direction="row">
                          <Text>{t("events.view")}</Text>
                        </Group>
                        <Group position="right">
                          <Select
                            placeholder="Pick one"
                            value={variation}
                            onChange={setVariation}
                            data={[
                              { value: "null", label: t("empty") },
                              { value: "1", label: "1" },
                              { value: "2", label: "2" },
                              { value: "3", label: "3" },
                            ]}
                          />
                        </Group>
                      </Group>

                      <Group grow style={{ width: "100%" }}>
                        <Group style={{ width: "50%" }} direction="row">
                          <Text>{t("events.color")}</Text>
                        </Group>
                        <Group position="right">
                          <Select
                            placeholder="Pick one"
                            value={color}
                            onChange={setColor}
                            data={[
                              { value: "null", label: t("empty") },
                              { value: "1", label: t("events.light") },
                              { value: "2", label: t("events.dark") },
                              {
                                value: "3",
                                label: t("events.green_white"),
                              },
                              {
                                value: "4",
                                label: t("events.green_black"),
                              },
                              {
                                value: "5",
                                label: t("events.blue_white"),
                              },
                              {
                                value: "6",
                                label: t("events.blue_black"),
                              },
                            ]}
                          />
                        </Group>
                      </Group>
                      <Group grow style={{ width: "100%" }}>
                        <Group style={{ width: "50%" }} direction="row">
                          <Text>{t("events.live_delay_second")}</Text>
                        </Group>
                        <Group position="right">
                          <Switch
                            label={""}
                            marginTop
                            checked={liveTextDelayShow}
                            setValue={setLiveTextDelayShow}
                          />
                          {liveTextDelayShow && (
                            <NumberInput
                              min={0}
                              value={liveTextDelay}
                              onChange={(e: any) => setLiveTextDelay(e)}
                            />
                          )}
                        </Group>
                      </Group>
                      <Group grow style={{ width: "100%" }}>
                        <Group style={{ width: "50%" }} direction="row">
                          <Text>{t("events.font_size")}</Text>
                        </Group>
                        <Group position="right">
                          <Switch
                            label={""}
                            marginTop
                            checked={fontSizeOpen}
                            setValue={setFontSizeOpen}
                          />
                          {fontSizeOpen &&
                            (showVideoUrl ? (
                              <NumberInput
                                value={fontSizeUrl}
                                onChange={(val: number) => setFontSizeUrl(val)}
                              />
                            ) : variation == "2" ? (
                              <Select
                                placeholder="Pick one"
                                value={fontSizeUrl2}
                                onChange={setFontSizeUrl2}
                                data={[
                                  { value: "16", label: "16" },
                                  { value: "21", label: "21" },
                                  {
                                    value: "26",
                                    label: "26",
                                  },
                                  {
                                    value: "39",
                                    label: "39",
                                  },
                                ]}
                              />
                            ) : (
                              <NumberInput
                                value={fontSizeUrl}
                                onChange={(val: number) => setFontSizeUrl(val)}
                              />
                            ))}
                        </Group>
                      </Group>
                      <Group grow style={{ width: "100%" }}>
                        <Group style={{ width: "50%" }} direction="row">
                          <Text>{t("events.hidden_video")}</Text>
                        </Group>
                        <Group position="right">
                          <Switch
                            label={""}
                            marginTop
                            checked={showVideoUrl}
                            setValue={setShowVideoUrl}
                          />
                        </Group>
                      </Group>
                      <Group grow style={{ width: "100%" }}>
                        <Group style={{ width: "50%" }} direction="row">
                          <Text>{t("events.split")}</Text>
                        </Group>
                        <Group position="right">
                          <Switch
                            label={""}
                            marginTop
                            checked={splitUrl}
                            setValue={() => {
                              setSplitUrl(!splitUrl),
                                setLeftLanguageUrl(
                                  leftLanguageUrl == "null"
                                    ? "main"
                                    : leftLanguageUrl
                                );
                            }}
                          />
                        </Group>
                      </Group>
                      <Group grow style={{ width: "100%" }}>
                        <Group style={{ width: "50%" }} direction="row">
                          <Text>{t("events.left_language")}</Text>
                        </Group>
                        <Group position="right">
                          <Select
                            placeholder="Pick one"
                            value={leftLanguageUrl}
                            onChange={setLeftLanguageUrl}
                            data={languageUrlData.filter((x: any) =>
                              splitUrl ? x.value != "null" : x
                            )}
                          />
                        </Group>
                      </Group>
                      {splitUrl && (
                        <>
                          <Group grow style={{ width: "100%" }}>
                            <Group style={{ width: "50%" }} direction="row">
                              <Text>{t("events.right_language")}</Text>
                            </Group>
                            <Group position="right">
                              <Select
                                placeholder="Pick one"
                                value={rightLanguageUrl}
                                onChange={setRightLanguageUrl}
                                data={languageUrlData.filter((x: any) =>
                                  splitUrl ? x.value != "null" : x
                                )}
                              />
                            </Group>
                          </Group>
                        </>
                      )}
                      <Group grow style={{ width: "100%" }}>
                        <Group style={{ width: "90%" }} direction="row">
                          <Text>{urlDetail()}</Text>
                        </Group>
                        <Group style={{ width: "10%" }} position="right">
                          <Button
                            loading={loading}
                            color={"green"}
                            onClick={copyCreateUrl}
                          >
                            <Popover
                              opened={copiedEventUrl}
                              position="right"
                              placement="center"
                              withArrow
                              transition="slide-up"
                              target={t("events.copy_link")}
                            >
                              {t("events.copied")}
                            </Popover>
                          </Button>
                        </Group>
                      </Group>
                    </Group>
                  </Accordion.Item>
                </Accordion>
              </Card>
            )}
          </>
        )}
        {window.location.pathname != `/event/${id}` && (
          <Card className={classes.card} shadow="sm" p="lg">
            <Card p="xs" className={classes.card_icon}>
              <Group>
                <IoPeopleOutline />
                <Text>{t("events.members")}</Text>
              </Group>
            </Card>
            <MembersSelect
              label={t("events.select_members")}
              value={props.value}
              setValue={props.setValue}
              placeholder={t("events.select_members")}
            />
          </Card>
        )}
        <Card className={classes.card} shadow="sm" p="lg">
          <Card p="xs" className={classes.card_icon}>
            <Group>
              <Settings />
              <Text>{t("events.settings")}</Text>
            </Group>
          </Card>
          <Switch
            label={t("events.private")}
            description={t("events.private_desc")}
            checked={_private}
            setValue={setPrivate}
          />

          {_private && (
            <Switch
              label={t("events.audio")}
              description={t("events.audio_desc")}
              checked={isAudio}
              setValue={setIsAudio}
            />
          )}
          {_private && (
            <Switch
              label={t("events.rich_text")}
              description={t("events.rich_text_desc")}
              checked={richText}
              setValue={setRichText}
            />
          )}
          <Switch
            label={t("events.show_download")}
            description={t("events.show_download_desc")}
            checked={showDownload}
            setValue={setShowDownload}
          />
          <Switch
            label={t("events.show_sign_language")}
            description={t("events.show_sign_language_desc")}
            checked={showSignLanguage}
            setValue={setShowSignLanguage}
          />
          {props.pageName == "newEvent" && (
            <Switch
              label={t("events.show_player")}
              description={t("events.show_player_desc")}
              checked={showPlayer}
              setValue={setShowPlayer}
            />
          )}
          <Switch
            label={t("events.other_websites")}
            description={t("events.other_websites_desc")}
            checked={otherWebsite}
            setValue={setOtherWebsite}
          />
        </Card>
        {!richText && (
          <Card className={classes.card} shadow="sm" p="lg">
            <Group direction="column">
              <Card p="xs" className={classes.card_icon}>
                <Group>
                  <Language />
                  <Text>{t("events.translations")}</Text>
                </Group>
              </Card>
              <CheckboxGroup
                // defaultValue={}
                color="red"
                value={translations}
                defaultValue={props.value.translations}
                onChange={(e) => setTranslations(e)}
                orientation="vertical"
                label={t("events.select_languages")}
                description={t("events.select_languages_desc")}
              >
                {languages.map((x: any, i: number) => (
                  <Checkbox key={i} value={x.value} label={x.label} />
                ))}
              </CheckboxGroup>
            </Group>
          </Card>
        )}
        {props.value.sources && showPlayer && props.pageName == "newEvent" && (
          <Card className={classes.card} shadow="sm" p="lg">
            <Card p="xs" className={classes.card_icon}>
              <Group>
                <PlayerPlay />
                <Text>{t("events.player")}</Text>
              </Group>
            </Card>
            <Select
              label={t("events.platform")}
              placeholder={t("events.platform")}
              color="red"
              required
              error={videoTypeError && t("events.required_video_type")}
              defaultValue={videoTypes}
              onChange={(e: any) => setVideoTypes(e)}
              data={videoTypesOptions}
            />
            <InputWrapper
              id="event-name-wrapper"
              required
              label={t("events.url")}
              error={videoLinkError && t("events.required_link")}
              style={{ width: "100%" }}
            >
              <Input
                id="link"
                defaultValue={props.value?.sources[0]?.src}
                placeholder={t("events.video_link")}
                {...register("sourcesSrc")}
                required
              />
            </InputWrapper>
          </Card>
        )}
        {otherWebsite > 0 && (
          <Card className={classes.card} shadow="sm" p="lg">
            <Card p="xs" className={classes.card_icon}>
              <Group>
                <Browser />
                <Text>{t("events.websites")}</Text>
              </Group>
            </Card>
            <>
              {urls?.map((url: any, i: number) => {
                return (
                  <span key={i}>
                    <Group style={{ alignItems: "flex-end" }}>
                      <Group style={{ width: "85%" }}>
                        <InputWrapper
                          id="event-name-wrapper"
                          required
                          label={t("events.title")}
                          // error={eventNameError()}
                          style={{ width: "100%" }}
                        >
                          <Input
                            id={`urls.${i}.title`}
                            {...register(`title${i}`)}
                            defaultValue={urls[i]?.title}
                            placeholder={t("events.title")}
                          />
                        </InputWrapper>
                        <InputWrapper
                          id="event-name-wrapper"
                          required
                          label={t("events.url")}
                          // error={eventNameError()}
                          style={{ width: "100%" }}
                        >
                          <Input
                            id={`urls.${i}.url`}
                            placeholder={t("events.url")}
                            defaultValue={urls[i]?.url}
                            {...register(`url${i}`)}
                          />
                        </InputWrapper>
                      </Group>
                      <Button color="red" onClick={() => handleRemoveUrl(i)}>
                        <Trash />
                      </Button>
                    </Group>
                  </span>
                );
              })}
              {urls?.length < 10 && (
                <Button
                  mt="xs"
                  style={{ width: "100%" }}
                  color="green"
                  onClick={handleAddUrl}
                >
                  <Plus />
                </Button>
              )}
            </>
          </Card>
        )}
      </SimpleGrid>
      <Group position="apart">
        {props.pageName == "newEvent" ? (
          <Button
            text={t("back")}
            loading={loading}
            variant="default"
            onClick={props.prevStep}
          />
        ) : (
          <Button loading={loading} color={"red"} onClick={handleCancelEvent}>
            {t("cancel")}
          </Button>
        )}

        <Button
          loading={loading}
          color={props.pageName == "newEvent" ? "red" : "green"}
          onClick={handleSubmitEvent}
        >
          {props.pageName == "newEvent" ? t("events.new_event") : t("save")}
        </Button>
      </Group>
    </SimpleGrid>
  );
};

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.white[1],
  },
  card_icon: {
    backgroundColor: theme.colors.gray[0],
    width: "100%",
  },
  detail_title: {
    width: 100,
    fontWeight: "bold",
  },
  container: {
    width: "80%",
    "@media (max-width: 769px)": {
      width: "100% !important",
    },
  },

  group: {
    width: "100%",
  },

  name: {
    whiteSpace: "nowrap",
    width: "50%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));
export default EventComplete;
