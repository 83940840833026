import React, { useState, useCallback, useEffect } from "react";
import {
  createStyles,
  Container,
  Card,
  Grid,
  Group,
  Center,
  Text,
  Title,
  TextInput,
  ActionIcon,
  Select,
  Button,
  Accordion,
  Loader,
  Modal,
  Input,
} from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../components/low-level/layout";
import { useTranslation, WithTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Adjustments, Check, X } from "tabler-icons-react";
import { useUser } from "../../hooks/user.hook";
import { useForm } from "react-hook-form";
import { useLocalStorage } from "@mantine/hooks";
import { auth } from "../../api/firebase";
import {
  MdArrowUpward,
  MdArrowDownward,
  MdOutlineArrowBack,
  MdOutlineArrowForward,
} from "react-icons/md";
export declare interface ICreatedProfileResponse {
  createdAt: string;
  email: string;
  firstName: string;
  fullName: string;
  id: string;
  lastName: string;
  memberOfOrganization: any[];
  uid: string;
  firebaseUid: string;
}
const ProfilePage: React.FunctionComponent = (props) => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { t, i18n } = useTranslation("common");
  const [value, setValue]: any = useLocalStorage({
    key: "language",
    defaultValue: i18n.resolvedLanguage,
  });
  const [openShortcutModal, setOpenShortcutModal] = useState(false);
  const [closeButton, setCloseButton] = useState(true);
  const loading = useSelector((state: any) => state.firebaseAuth.loading);
  const [isOpen, setIsOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const { handlers: userHandlers, firebaseUserInfo, profile } = useUser();
  const [firstName, setFirstName] = useState(profile?.firstName);
  const [lastName, setLastName] = useState(profile?.lastName);
  const [keyCode, setKeyCode]: any = useState([]);
  const [keyCodeLocal, setKeyCodeLocal]: any = useState([]);
  const [shortcutName, setShortcutName]: any = useState("");

  const shortcuts = [
    { name: "first_word_shortcut" },
    { name: "last_word_shortcut" },
    { name: "first_sentence_shortcut" },
    { name: "last_sentence_shortcut" },
    { name: "input_mode" },
  ];
  const defaultShortcutsData = [
    { name: "next_sentence", icon: MdArrowUpward },
    { name: "last_sentence", icon: MdArrowDownward },
    { name: "right_word", icon: MdOutlineArrowBack },
    { name: "left_word", icon: MdOutlineArrowForward },
    { name: "esc", text: "Esc" },
  ];
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const nameCredential = watch("name");
  const lastNameCredential = watch("lastname");

  useEffect(() => {
    i18n.changeLanguage(value);
  }, [value]);

  useEffect(() => {
    if (profile.firstName !== firstName || profile.lastName !== lastName)
      setCloseButton(false);
    else setCloseButton(true);
  }, [firstName, lastName]);

  const getData = (isOpened: boolean, imageSrc: any) => {
    setIsOpen(isOpened);
    setImageSrc(imageSrc);
  };

  const onUpdateProfile = async () => {
    if (firstName?.length > 2 && lastName?.length > 2) {
      const data = {
        email: profile.email,
        firebaseUid: firebaseUserInfo.uid,
        firstName: firstName,
        lastName: lastName,
      };
      await userHandlers.updateProfile(profile.id, data);
      if (data) {
        // navigate(-1);
        setCloseButton(true);
      }
    }
  };

  useEffect(() => {
    userHandlers.getProfile(profile.uid);
  }, [profile]);

  const closeButtonArea = () => {
    setLastName(profile.lastName);
    setFirstName(profile.firstName);
    setCloseButton(true);
  };

  const openModal = (shortcut: any,e:any) => {
    setKeyCode(e)
    setOpenShortcutModal(true);
    setShortcutName(shortcut);
  };

  useEffect(() => {
    if (openShortcutModal)
      window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [keyCode, keyCodeLocal, openShortcutModal]);

  const handleUserKeyPress = (event: any) => {
    setKeyCode(event.key);
  };
  const saveShortcut = () => {
    if (keyCode.length > 0) localStorage.setItem(shortcutName, keyCode);
    setOpenShortcutModal(false);
  };

  const clearShortcut = () => {
    setKeyCode([]);
  };
  const defaultShortcuts = () => {
    localStorage.setItem("first_word_shortcut", "a");
    localStorage.setItem("last_word_shortcut", "d");
    localStorage.setItem("first_sentence_shortcut", "f");
    localStorage.setItem("last_sentence_shortcut", "l");
    localStorage.setItem("input_mode", "i");
  };
  return (
    <>
      <Modal
        opened={openShortcutModal}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setOpenShortcutModal(false)}
        title={t("profilePage." + shortcutName)}
      >
        <div style={{ display: "flex" }}>
          <div
            style={{
              backgroundColor: "gray",
              display: "flex",
              height: 100,
              marginBottom: 10,
              width: "100%",
              color: "white",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {keyCode.length < 1 ? (
              <Text>Please Press a Button</Text>
            ) : (
              <Text>{keyCode}</Text>
            )}
          </div>
        </div>
        <Group grow>
          <Button onClick={() => clearShortcut()}>Clear</Button>
          <Button onClick={() => saveShortcut()} color="green">
            Save
          </Button>
        </Group>
      </Modal>
      <Layout scrollScreen={true} eventName={t("profile")}>
        <Container pb="md" pt="md" size="sm" px="xs">
          <Card>
            <Center>
              <Group m="md">
                <Text size="lg" weight={500}>
                  {profile.email}
                </Text>
              </Group>
            </Center>
            <Select
              label={t("profilePage.select_language")}
              placeholder={t("profilePage.select_language")}
              value={value}
              onChange={setValue}
              data={[
                { value: "en", label: t("en") },
                { value: "de", label: t("de") },
                { value: "it", label: t("it") },
                { value: "fr", label: t("fr") },
              ]}
            />
            <Group
              mt={20}
              position="apart"
              grow
              className={classes.name_surname_area}
            >
              <TextInput
                value={firstName}
                onChange={(e: any) => setFirstName(e.target.value)}
                className={classes.text_input}
                placeholder={t("firtname")}
                error={firstName?.length < 3 && t("required")}
                label={t("firstname")}
              />
              <TextInput
                className={classes.text_input}
                value={lastName}
                onChange={(e: any) => setLastName(e.target.value)}
                error={lastName?.length < 3 && t("required")}
                placeholder={t("lastname")}
                label={t("lastname")}
              />
              {loading ? (
                <Loader color="red" variant="dots" />
              ) : (
                firstName?.length > 2 &&
                lastName?.length > 2 &&
                !closeButton && (
                  <Group position="apart" grow className={classes.button_area}>
                    <ActionIcon
                      onClick={closeButtonArea}
                      color="red"
                      variant="filled"
                    >
                      <X size={16} />
                    </ActionIcon>
                    <ActionIcon
                      color="green"
                      variant="filled"
                      onClick={() => onUpdateProfile()}
                    >
                      <Check size={16} />
                    </ActionIcon>
                  </Group>
                )
              )}
            </Group>
            <Accordion
              styles={{
                content: { paddingLeft: 0 },
              }}
            >
              <Accordion.Item label={t("profilePage.keyboard_shortcuts")}>
                <Grid>
                  <Grid.Col span={6}>
                    <Title mb="sm" order={3}>
                      {t("profilePage.shortcuts_commands")}
                    </Title>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Title mb="sm" order={3}>
                      {t("profilePage.shortcuts")}
                    </Title>
                  </Grid.Col>
                </Grid>
                {shortcuts.map((x: any) => (
                  <div className={classes.shortcut_button}>
                    <Text
                      size="sm"
                      color="gray"
                      className={classes.shortcuts_text}
                    >
                      {t("profilePage." + x.name)}
                    </Text>
                    <Button
                      variant="outline"
                      color="red"
                      onClick={() => openModal(x.name,localStorage.getItem(x.name))}
                    >
                      <Text>Control + {localStorage.getItem(x.name)}</Text>
                    </Button>
                  </div>
                ))}
                
                {defaultShortcutsData.map((x: any) => (
                  <div className={classes.shortcut_button}>
                    <Text
                      size="sm"
                      color="gray"
                      className={classes.shortcuts_text}
                    >
                      {t("profilePage." + x.name)}
                    </Text>
                    <Button variant="outline" color="red">
                      {x.icon?.name == "MdOutlineArrowBack" || x.icon?.name == "MdOutlineArrowForward" ? "Shift +" : ""} {x.icon && <x.icon />}
                      {x.text && x.text}
                    </Button>
                  </div>
                ))}
                <div className={classes.shortcut_button}>
                  <Text
                    size="sm"
                    color="gray"
                    className={classes.shortcuts_text}
                  >
                    {t("profilePage.clear")}
                  </Text>
                  <Button onClick={defaultShortcuts} color="green">
                    <Text>Clear</Text>
                  </Button>
                </div>
              </Accordion.Item>
            </Accordion>
          </Card>
        </Container>
      </Layout>
    </>
  );
};
const useStyles = createStyles((theme) => ({
  name_surname_area: {
    display: "flex",
    alignItems: "flex-end",
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      flexDirection: "column",
      width: "100%",
      gap: 0,
    },
  },
  shortcuts_text: {
    width: "50%",
  },
  shortcut_button: {
    display: "flex",
    margin: "10px 0px",
    alignItems: "center",
  },
  text_input: {
    height: 100,
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      height: 80,
      width: "100%",
      maxWidth: "100%",
    },
  },
  button_area: {
    height: 100,
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      width: "100%",
      maxWidth: "100%",
      marginTop: theme.spacing.lg,
    },
  },
}));
export default ProfilePage;
