import {
  Container,
  Space,
  Modal,
  Group,
  Text,
  ActionIcon,
  Button,
  Menu,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useOrganization } from "../../hooks/organization.hook";
import Table from "../../components/low-level/table";
import { useSelector } from "react-redux";
import Loading from "../../components/low-level/loading";
import { useTranslation } from "react-i18next";
import Layout from "../../components/low-level/layout";
import { Edit, Trash, DotsVertical } from "tabler-icons-react";
import { useUser } from "../../hooks/user.hook";

const OrganizationPage: React.FunctionComponent = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("common");
  const loading: any = useSelector(
    (action: any) => action.organization.loading
  );
  const { handlers, firebaseUserInfo, profile } = useUser();
  const {
    handlers: orgaHandlers,
    organization,
    organizations,
  }: any = useOrganization();

  const [data, setData] = useState(organizations);
  const [deleteOpenModal, setDeleteOpenModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState();

  useEffect(() => {
    if (id) {
      orgaHandlers.getOrganization(id);
    }
  }, [id]);

  useEffect(() => {
    orgaHandlers.getAllOrganizations();
  }, []);

  useEffect(() => {
    setData(organizations);
  }, [organizations, data, loading]);

  const th = [
    { name: t("organization.organization_name"), sortName: "name", sort: true },
    { name: "", sort: false, sortName: "enterRoom" },
  ];

  const removeItem = (id: any) => {
    orgaHandlers.removeOrganization(id);
  };
  const editPage = (e: any) => {
    navigate("/edit-organization", { state: { event: e } });

    if (!organizations || !profile) return <Loading />;
  };
  const goEvent = (id: string) => {
    navigate(`/organizations/${id}/events`);
  };

  const addButtonVisibleEmail = () => {
    if (profile.email == "korayaggul0@gmail.com") {
      return true;
    } else if (profile.email == "andrea.uvackova@swisstxt.ch") {
      return true;
    } else if (profile.email == "marina.defilippi@swisstxt.ch") {
      return true;
    } else if (profile.email == "liliane.martignetti-blanco@swisstxt.ch") {
      return true;
    } else if (profile.email == "dalia.goldberger@swisstxt.ch") {
      return true;
    } else if (profile.email == "michaela.nachtrab@swisstxt.ch") {
      return true;
    } else if (profile.email == "sarah.moessner@swisstxt.ch") {
      return true;
    } else if (profile.email == "fabio.bensoltane@swisstxt.ch") {
      return true;
    } else if (profile.email == "tolga@gigel.io") {
      return true;
    } else {
      return false;
    }
  };

  const tr = (item: any) => {
    return (
      <>
        <td style={{ width: "100%" }} onClick={() => goEvent(item.id)}>
          <Group spacing="sm">
            <div>
              <Text style={{ fontSize: 17 }} weight={500}>
                {item.name}
              </Text>
              <Text color="dimmed" size="xs"></Text>
            </div>
          </Group>
        </td>
        <td>
          {item.isOwner && (
            <ActionIcon>
              <Menu
                control={
                  <div>
                    <DotsVertical size={20} strokeWidth={1} color={"black"} />
                  </div>
                }
              >
                <Menu.Item
                  onClick={() => editPage(item)}
                  icon={<Edit size={14} />}
                >
                  {t("edit")}
                </Menu.Item>
                <Menu.Item
                  onClick={() => deleteItem(item.id)}
                  color="red"
                  icon={<Trash size={14} />}
                >
                  {t("delete")}
                </Menu.Item>
              </Menu>
            </ActionIcon>
          )}
        </td>
      </>
    );
  };

  const deleteItem = (e: any) => {
    setDeleteOpenModal(true);
    setDeleteItemId(e);
  };

  return (
    <Layout page="organizations">
      <Modal
        centered
        withCloseButton={false}
        opened={deleteOpenModal}
        onClose={() => setDeleteOpenModal(false)}
        title={t("are_you_sure")}
      >
        <Group grow>
          <Button onClick={() => setDeleteOpenModal(false)} color="green">
            {t("cancel")}
          </Button>
          <Button
            onClick={() => {
              removeItem(deleteItemId);
              setDeleteOpenModal(false);
            }}
            color="red"
          >
            {t("delete")}
          </Button>
        </Group>
      </Modal>
      <Container size="md">
        {data && (
          <>
            <Space h="lg" />
            <Table
              addButtonVisible={addButtonVisibleEmail()}
              addButtonNavigate="/new-organization"
              addButton="addOrganisation"
              tableName="organizations"
              th={th}
              tr={tr}
              removeItem={removeItem}
              filter={false}
              data={data}
              loading={false}
            />
          </>
        )}
      </Container>
    </Layout>
  );
};

export default OrganizationPage;
