import React, { useState, useEffect } from "react";
import { Dialog, createStyles, Button, TextInput, Text } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { useTranslation } from "react-i18next";

interface Props {
  text: any;
  open: boolean;
  success: any;
}

const AlertDialog = (props: Props) => {
  const { classes } = useStyles();
  const { t } = useTranslation("common");

  const { height, width } = useViewportSize();

  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(props.open);
    setTimeout(() => {
      setOpened(false);
    }, 2000);
  }, [props.open]);

  return (
    <Dialog
      styles={{
        root: {
          width: width <= 768 ? "80vw" : "20vw",
          color: props.success.length ? "green" : "red",
        },
        closeButton: { display: "none" },
      }}
      opened={opened}
      withCloseButton
      shadow="lg"
      transition="slide-up"
      transitionDuration={1000}
      transitionTimingFunction="ease"
      position={{ top: 20, right: 20 }}
      size="lg"
      radius="md"
    >
      <Text size="md" weight={500}>
        {props.text?.includes("network")
          ? t("network_error")
          : props.success.length > 0
          ? props.success
          : props.text}
      </Text>
    </Dialog>
  );
};
const useStyles = createStyles((theme) => ({
  dialog: {},
}));
export default AlertDialog;
