import React, { useState, useEffect, useRef } from "react";
import {
  Image,
  Document,
  Page,
  Text,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
import { useConnector } from "../../../hooks/sharedbconnector.hook";
import axios from "axios";
interface MyPDFComponentProps {
  event: any;
  language: any;
  setPdfButtonDisabled?: any;
}

function DownloadPdf({
  event,
  language,
  setPdfButtonDisabled,
}: MyPDFComponentProps) {
  const [
    bindingReady,
    guid,
    operationCounter,
    translatedData,
    { init, close },
  ] = useConnector();
  const refContainer: any = useRef();
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    if (connected) return;
    if (init && event) {
      setConnected(true);
      init(event.id, language, language, refContainer.current);
    }
  }, [event, language, connected, init]);

  const styles = StyleSheet.create({
    page: {
      backgroundColor: "white",
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    section: { color: "black", margin: "2px 0px" },
    detail: { textAlign: "right", fontSize: 12 },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: "center",
      color: "grey",
    },
  });

  useEffect(() => {
    if (translatedData.length == 0) {
      setPdfButtonDisabled(true);
    } else {
      setPdfButtonDisabled(false);
    }
  }, [translatedData]);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.header} fixed>
          {event.name}
        </Text>

        <View
          fixed
          style={{
            display: "flex",
            flexDirection: "row", // explizite Angabe, dass die Elemente in einer Zeile (horizontal) angeordnet werden sollen
            justifyContent: "center", // Zentriert die Bilder horizontal
            alignItems: "center", // Zentriert die Bilder vertikal
            flexWrap: "nowrap", // Verhindert, dass die Elemente in neue Zeilen umgebrochen werden
            width: "100%",
            paddingBottom: 20, // Nutzt die volle Breite des übergeordneten Containers
          }}
        >
          {event.customLogos && event.customLogos.length > 0 ? (
            event.customLogos.map((x: any, i: number) => (
              <Image
                key={i}
                style={{
                  flexShrink: 0, // Verhindert, dass das Bild schrumpft, um in den Container zu passen
                  width: "auto", // Auto-Breite, um das natürliche Seitenverhältnis zu erhalten
                  height: "50", // Fixe Höhe, um Konsistenz zu gewährleisten
                  marginLeft: i > 0 ? "20px" : "0", // Fügt einen linken Abstand zwischen den Bildern hinzu, außer beim ersten
                }}
                fixed
                src={x.img}
              />
            ))
          ) : (
            <Image
              style={{
                flexShrink: 0, // Verhindert, dass das Bild schrumpft, um in den Container zu passen
                width: "auto", // Auto-Breite, um das natürliche Seitenverhältnis zu erhalten
                height: "50", // Fixe Höhe, um Konsistenz zu gewährleisten
              }}
              src={require("../../../assets/srg_ssr_logo.png")}
            />
          )}
        </View>
        {translatedData &&
          translatedData?.length > 0 &&
          translatedData?.map((x: any, i: number) => (
            <Text key={i} style={styles.section}>
              {x?.value}
            </Text>
          ))}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}

export default DownloadPdf;
