import { configureStore, Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";

import firebaseAuthReducer from "./reducers/firebaseAuth.reducer";
import userReducer from "./reducers/user.reducer";
import uiReducer from "./reducers/ui.reducer";
import organizationReducer from "./reducers/organization.reducer";
import profileReducer from "./reducers/profile.reducer";
import eventReducer from "./reducers/event.reducer";
import memberReducer from "./reducers/member.reducer";
import eventMembersReducer from "./reducers/event-members.reducer";
import notificationReducer from "./reducers/notification.reducer";
import rolesReducer from "./reducers/roles.reducer";
import raiseHandReducer from "./reducers/raise-hand.reducer";
import uploadReducer from "./reducers/upload.reducer";
import schedulerReducer from "./reducers/scheduler.reducer";
import videoReducer from "./reducers/video-reducer";
import signLanguageReducer from "./reducers/sign-language.reducer";
const store: any = configureStore({
  reducer: {
    firebaseAuth: firebaseAuthReducer,
    user: userReducer,
    ui: uiReducer,
    organization: organizationReducer,
    profile: profileReducer,
    notification: notificationReducer,
    event: eventReducer,
    member: memberReducer,
    eventMembers: eventMembersReducer,
    roles: rolesReducer,
    raiseHand: raiseHandReducer,
    uploadImage: uploadReducer,
    scheduler:schedulerReducer,
    video:videoReducer,
    signLanguage:signLanguageReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;
