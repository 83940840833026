export const firebaseConfig = {
  apiKey: "AIzaSyC74OOCcCYrfSTKn8_n_S6DTC55BroJLmY",
  authDomain: "interpreteri.firebaseapp.com",
  databaseURL: "https://interpreteri-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "interpreteri",
  storageBucket: "interpreteri.appspot.com",
  messagingSenderId: "225213178016",
  appId: "1:225213178016:web:d8bb5dc8c669dd97f5e9c5",
  measurementId: "G-3TEX2C65BC"
};
