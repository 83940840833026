import { ActionIcon, Button, Text, createStyles } from "@mantine/core";
import "./styles.css";
import { HiOutlineHandRaised } from "react-icons/hi2";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
const SignLanguageVideoRoom = ({
  switchVideoRequest,
  live,
  switchMainRequestFunction,
  switchFunction,
  sideVideoActive,
  switchTimer,
  switchVideo,
}: any) => {
  const { classes } = useStyles();
  return (
    <div className={classes.main}>
      <div className={classes.mainStream}>
        Active interpreter {live ? "- Live" : ""}
        <div
          id="video-container-1"
          className={classes.mainVideo}
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            overflow: "hidden",
            border: live ? "3px solid red" : "3px solid lightgray",
          }}
        >
          <div
            style={{
              backgroundColor: live ? "darkred" : "white",
              position: "absolute",
              zIndex: 9999,
              left: 10,
              top: 10,
              paddingLeft: 8,
              paddingRight: 8,
              fontSize: 13,
              color: live ? "white" : "green",
              borderRadius: 5,
              display: "flex",
              alignItems: "center",
              gap: 5,
            }}
          >
            {live ? "live" : "online"}{" "}
            <div
              style={{
                width: 8,
                height: 8,
                backgroundColor: live ? "red" : "green",
                borderRadius: 10,
              }}
            ></div>
          </div>
        </div>
        {/* <SignVideoPlayer user={users[0]} /> */}
      </div>
      <div
        style={{
          marginLeft: 10,
          marginBottom: 20,
          width: switchVideoRequest ? "calc(50% - 2px)" : "calc(50% + 4px)",
        }}
      >
        Co-interpreter
        <div
          id="video-container-2"
          style={{
            width: "100%",
            height: "100%",
            marginTop: 5,
            position: "relative",
            overflow: "hidden",
            display: sideVideoActive ? "flex" : "none",
            borderRadius: 15,
            border: live ? "3px solid rgb(157,197,111)" : "3px solid lightgray",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              position: "absolute",
              zIndex: 9999,
              left: 10,
              top: 10,
              paddingLeft: 8,
              paddingRight: 8,
              fontSize: 13,
              color: "green",
              borderRadius: 5,
              display: "flex",
              alignItems: "center",
              gap: 5,
            }}
          >
            online
            <div
              style={{
                width: 8,
                height: 8,
                backgroundColor: "green",
                borderRadius: 10,
              }}
            ></div>
          </div>
          {switchVideoRequest ? (
              <div
                className={classes.side_video_request}
              >
                <Text style={{ fontSize: 100 }}>✋</Text>
                <Text
                  style={{ fontSize: 30, fontWeight: "bold", color: "white" }}
                >
                  Interpreter
                </Text>
                <Text
                  style={{ fontSize: 30, fontWeight: "bold", color: "white" }}
                >
                  is ready
                </Text>
                <Button onClick={switchFunction} mt={20} style={{ fontWeight: "normal" }} color="red">
                    yes, switch
                  </Button>
              </div>
          ) : switchVideo ? (
            <div
              onClick={switchFunction}
              className={classes.side_video_request}
            >
              <Text
                style={{ fontSize: 200, fontWeight: "bold", color: "white" }}
              >
                {switchTimer}
              </Text>
            </div>
          ) : (
            <Button
              style={{
                backgroundColor: "white",
                position: "absolute",
                zIndex: 9999,
                bottom: 60,
                left: 0,
                right: 0,
                color: "black",
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
                height: 60,
                borderRadius: 5,
                gap: 5,
              }}
              onClick={switchMainRequestFunction}
              className={classes.request_lead_button}
            >
              <Text style={{ fontSize: "1.2vw" }} weight="normal">
                ✋ Request
              </Text>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
const useStyles = createStyles((theme) => ({
  main: {
    display: "flex",
    marginTop: 25,
  },
  mainStream: {
    width: "calc(50% - 4px)",
    height: "50vh",
    marginBottom: 20,
    "@media (max-width: 1200px)": {
      height: "35vh",
    },
  },

  mainVideo: {
    borderRadius: 15,
    marginTop: 5,
  },

  request_lead_button: {
    WebkitBoxShadow: "0px 0px 17px -1px rgba(0,0,0,0.58)",
    boxShadow: "0px 0px 17px -1px rgba(0,0,0,0.58)",
  },
  side_video_request: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(20,201,18,0.4)",
    position: "absolute",
    zIndex: 9999,
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
  },
  modal: {
    width: "55%",
    height: "30%",
    borderRadius: 20,
    backgroundColor: "white",
    WebkitBoxShadow: "0px 0px 17px -1px rgba(0,0,0,0.58)",
    boxShadow: "0px 0px 17px -1px rgba(0,0,0,0.58)",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
  },
  close_modal: {
    position: "absolute",
    top: 0,
    right: 10,
  },
}));
export default SignLanguageVideoRoom;
