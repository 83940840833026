import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IVideoStateData {
  createdAt: string | any;
  docId: string;
  showPreview: boolean;
  previewType: string;
  updatedAt: string | any;
}

interface IVideoState {
  currentVideoState: IVideoStateData | null;
}
const initialstate: IVideoState = {
  currentVideoState: null,
};

export const counterSlice = createSlice({
  name: "video",
  initialState: initialstate,
  reducers: {
    setVideoState: (
      state: { currentVideoState: any },
      action: { payload: any }
    ) => {
      state.currentVideoState = action.payload;
    },
  },
});

export const { setVideoState } = counterSlice.actions;

export default counterSlice.reducer;
