import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { AppDispatch } from "../../store/store";
import {
  Container,
  Group,
  Tooltip,
  Text,
  Modal,
  Button,
  ActionIcon,
  Popover,
  createStyles,
} from "@mantine/core";
import { useRoles } from "../../hooks/roles.hooks";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "../../components/low-level/layout";
import Table from "../../components/low-level/table";
import { useEvents } from "../../hooks/event.hook";

import { useSelector } from "react-redux";
import { Trash, ClipboardText } from "tabler-icons-react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Role from "../../enums/role.enum";

const EventsPage: React.FunctionComponent = (props) => {
  const { id } = useParams();
  const { t } = useTranslation("common");
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { handlers: rolesHandlers, OrganizationRole } = useRoles();
  const { handlers: eventsHandlers, events }: any = useEvents();
  const [data, setData] = useState(events);
  const [copied, setCopied] = useState(false);
  const [copyUrl, setCopyUrl] = useState("");
  const [mobile, setMobile] = useState(false);
  const [deleteOpenModal, setDeleteOpenModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState();

  useEffect(() => {
    if (window?.innerWidth < 850) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);
  const loading: any = useSelector(
    (action: any) => action.organization.loading
  );
  useEffect(() => {
    if (id) {
      eventsHandlers.getAllOrganizationEvents(id);
    }
  }, [id]);

  const th = [
    // { name: "Date", sortName: "updatedAt", sort: true },
    { name: t("events.created_at"), sortName: "createdAt", sort: true },
    { name: t("events.event_name"), sortName: "name", sort: true },
    { name: t("events.enter_room"), sort: false, sortName: "enterRoom" },
  ];

  useEffect(() => {
    setData(events);
  }, [events, data, loading]);

  const removeItem = (itemId: any) => {
    eventsHandlers.removeEvent(itemId, id);
  };

  const copyLink = (id: any) => {
    setCopyUrl(id);
    setCopied(true);
    const el = document.createElement("input");
    el.value = window.location.origin + "/p/" + id;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setTimeout(() => {
      setCopied(false);
    }, 500);
  };

  const goEventPage = (item: any) => {
    localStorage.setItem("eventId", item.id);
    navigate(`/event/${item.shortUid}`, { state: { event: item } });
  };

  const tr = (item: any) => {
    return (
      <>
        {/* <td>
          <Group spacing="sm">
            <div>
              <Text size="sm" weight={500}>
                {item?.startDate
                  ? moment(item?.startDate).format("DD/MM/YYYY")
                  : ""}
              </Text>
            </div>
          </Group>
        </td> */}
        <td onClick={() => goEventPage(item)}>
          <Group spacing="sm">
            <div>
              <Text className={classes.text} size="sm" weight={500}>
                {moment(item?.createdAt).format("DD/MM/YYYY")}
              </Text>
            </div>
          </Group>
        </td>
        <td onClick={() => goEventPage(item)} style={{ width: "100%" }}>
          <Group spacing="sm">
            <div>
              <Text
                className={`${mobile && classes.name} ${classes.text}`}
                weight={500}
              >
                {item.name}
              </Text>
              <Text color="dimmed" size="xs"></Text>
            </div>
          </Group>
        </td>
        <td>
          <Group spacing={20}>
            <Tooltip
              color="red"
              disabled={mobile}
              position="right"
              placement="center"
              label={t("events.copy_link")}
              withArrow
            >
              <ActionIcon onClick={() => copyLink(item.shortUid)}>
                <Popover
                  opened={copied && copyUrl == item.shortUid}
                  position="right"
                  placement="center"
                  withArrow
                  transition="slide-up"
                  target={
                    <ClipboardText size={20} strokeWidth={1} color={"black"} />
                  }
                >
                  {t("events.copied")}
                </Popover>
              </ActionIcon>
            </Tooltip>
            {OrganizationRole == Role.Admin ||
              (OrganizationRole == Role.Owner && (
                <Tooltip
                  color="red"
                  position="right"
                  disabled={mobile}
                  placement="center"
                  label={t("delete")}
                  withArrow
                >
                  <ActionIcon onClick={() => deleteItem(item.id)}>
                    <Trash color="red" size={20} />
                  </ActionIcon>
                </Tooltip>
              ))}
          </Group>
        </td>
      </>
    );
  };
  const deleteItem = (e: any) => {
    setDeleteOpenModal(true);
    setDeleteItemId(e);
  };
  return (
    <Layout>
      <Modal
        centered
        withCloseButton={false}
        opened={deleteOpenModal}
        onClose={() => setDeleteOpenModal(false)}
        title={t("are_you_sure")}
      >
        <Group grow>
          <Button onClick={() => setDeleteOpenModal(false)} color="green">
            {t("cancel")}
          </Button>
          <Button
            onClick={() => {
              removeItem(deleteItemId);
              setDeleteOpenModal(false);
            }}
            color="red"
          >
            {t("delete")}
          </Button>
        </Group>
      </Modal>
      <Container size="md" px="xs">
        <Table
          addButtonVisible={
            OrganizationRole == Role.Admin || OrganizationRole == Role.Owner
          }
          addButtonNavigate={`/organizations/${id}/new-event`}
          addButton="addEvent"
          tableName="events"
          th={th}
          tr={tr}
          removeItem={removeItem}
          data={data}
          loading={false}
          filter={true}
        />
      </Container>
    </Layout>
  );
};
const useStyles = createStyles((theme) => ({
  name: {
    whiteSpace: "nowrap",
    width: 250,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  text: {
    fontSize: 17,
  },
}));
export default EventsPage;
