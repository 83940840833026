import React, { useState, useEffect, useCallback } from "react";
import { ActionIcon, Group, Text, createStyles } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Progress } from "@mantine/core";
import { IoRefreshOutline } from "react-icons/io5";

type Props = {
  event: any;
  remoteUsersNames: any;
  audioJoinState: boolean;
};
let audioContext: any;
let analyser: any;
let microphone: any;
let scriptProcessor: any;
let _stream: any;
const Info = (props: Props) => {
  const { t } = useTranslation("common");
  const { classes } = useStyles();

  const { remoteUsersNames, event, audioJoinState } = props;
  const [stream, setStream] = useState<any>(null);
  const [deviceId, setDeviceId] = useState<any>(null);
  const [usedMic, setUsedMic] = useState<any>("");

  useEffect(() => {
    if (event.usersRole === "Customer" && audioJoinState) {
      createMedia()
    }
  }, [audioJoinState, event]);

  const createMedia = () => {
    navigator.mediaDevices
        .getUserMedia({
          audio: true,
          video: false,
        })
        .then(function (stream: any) {
          audioContext = new AudioContext();
          _stream = stream;
          const audioTracks = stream.getAudioTracks();
          if (audioTracks.length > 0) {
            setUsedMic(audioTracks[0].label);
          }
          analyser = audioContext.createAnalyser();
          microphone = audioContext.createMediaStreamSource(stream);
          setStream(stream);
          scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);
          analyser.smoothingTimeConstant = 0.8;
          analyser.fftSize = 1024;
          microphone.connect(analyser);
          analyser.connect(scriptProcessor);
          scriptProcessor.connect(audioContext.destination);
          scriptProcessor.onaudioprocess = () => {
            const array = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(array);
            const arraySum = array.reduce((a, value) => a + value, 0);
            const average = arraySum / array.length;
            // setMicVolume(Math.round(average));
            colorPids(average);
          };
        })
        .catch(function (err) {
          /* handle the error */
          console.error(err);
        });
  }

  useEffect(() => {
    navigator.mediaDevices.addEventListener("devicechange", async () => {
      console.log("active micro chane.");
      try {
        const _audioContext = new AudioContext();
        const devices = await _stream.getAudioTracks();
        if (devices.length > 0) {
          setUsedMic(devices[0].label);
        }
        refresh()
      } catch (err) {
        console.log(err);
      }
    });
  }, []);

  function colorPids(vol: any) {
    const allPids: any = [...document.querySelectorAll(".pid")];
    const numberOfPidsToColor = Math.round(vol / 10);
    const pidsToColor = allPids.slice(0, numberOfPidsToColor);
    for (const pid of allPids) {
      pid.style.backgroundColor = "#e6e7e8";
    }
    for (const pid of pidsToColor) {
      // console.log(pid[i]);
      pid.style.backgroundColor = "#69ce2b";
    }
  }

  useEffect(() => {
    if (stream && !audioJoinState) {
      stream?.getTracks().forEach(function (track: any) {
        track.stop();
      });
      if (audioContext) {
        audioContext.close();
        audioContext = null;
      }
      if (scriptProcessor) {
        scriptProcessor.disconnect();
        scriptProcessor.onaudioprocess = null;
      }
      if (analyser) {
        analyser.disconnect();
        analyser = null;
      }
      if (microphone) {
        microphone.disconnect();
        microphone = null;
      }
      setStream(null);
    }
  }, [stream, audioJoinState, scriptProcessor]);

  const render = useCallback(() => {
    if (event?.isAudioStreaming) {
      if (audioJoinState) {
        if (event.usersRole != "Customer") {
          if (remoteUsersNames.length > 0) {
            return remoteUsersNames.map((name: any, index: number) => (
              <Text key={index}>
                <b>{name.name}</b> {t("infoBox.hasMicrophone")}
              </Text>
            ));
          } else {
            return <Text>{t("infoBox.noMicrophoneOnline")}</Text>;
          }
        } else {
          return <Text>{t("infoBox.connectAudio")}</Text>;
        }
      } else {
        return <Text>{t("infoBox.plaseConnect")}</Text>;
      }
    }
  }, [event, remoteUsersNames, audioJoinState]);

  const refresh = () => {
    if (stream ) {
      stream?.getTracks().forEach(function (track: any) {
        track.stop();
      });
      if (audioContext) {
        audioContext.close();
        audioContext = null;
      }
      if (scriptProcessor) {
        scriptProcessor.disconnect();
        scriptProcessor.onaudioprocess = null;
      }
      if (analyser) {
        analyser.disconnect();
        analyser = null;
      }
      if (microphone) {
        microphone.disconnect();
        microphone = null;
      }
      setStream(null);
      createMedia()
    }
  }
  return (
    <div style={{ position: "relative", height: "100%" }}>
      <Group direction="column">
        {render()}
        
        {audioJoinState && (
          <>
          <Group direction="row" style={{width:"100%",display:"flex",flexDirection:"row"}}>
            <Text style={{width:"70%"}}>{usedMic}</Text>
            <div style={{width:"20%"}}>
            <ActionIcon  color="green" variant="filled" radius={50} onClick={refresh}><IoRefreshOutline size={20}/></ActionIcon>
            </div>
          </Group>
            <div className={`pid-wrapper`} style={{ width: "80%" }}>
              <div className={`pid ${classes.pid}`}></div>
              <div className={`pid ${classes.pid}`}></div>
              <div className={`pid ${classes.pid}`}></div>
              <div className={`pid ${classes.pid}`}></div>
              <div className={`pid ${classes.pid}`}></div>
              <div className={`pid ${classes.pid}`}></div>
              <div className={`pid ${classes.pid}`}></div>
              <div className={`pid ${classes.pid}`}></div>
              <div className={`pid ${classes.pid}`}></div>
              <div className={`pid ${classes.pid}`}></div>
            </div>
          </>
        )}
      </Group>
    </div>
  );
};
const useStyles = createStyles((theme) => ({
  pid: {
    margin: 5,
    width: "calc(10% - 10px)",
    display: "inline-block",
    height: 10,
  },
}));
export default Info;
