import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Container,
  TextInput,
  Text,
  Title,
  Anchor,
  Paper,
} from "@mantine/core";

import IPageProps from "../../interfaces/page.interface";
import { useForm } from "react-hook-form";

const JoinEventPage: React.FunctionComponent<IPageProps> = (props) => {
  const [start, setStart] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const eventId: any = watch("eventId");

  const goToEvent = (id: any) => {
    setStart(true);
    if (id) {
      setStart(false);
      navigate("/p/" + id);
    }
  };
  return (
    <Container size={420} my={40}>
      <Title
        align="center"
        sx={(theme) => ({
          fontFamily: `Greycliff CF, ${theme.fontFamily}`,
          fontWeight: 900,
        })}
      >
        Welcome!
      </Title>
      <Text color="dimmed" size="sm" align="center" mt={5}>
        Log in to start the event
      </Text>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <TextInput
          {...register("eventId")}
          error={start && !eventId && "please_enter_id"}
          label="Please enter your event id"
          placeholder="Event ID"
        />
        <Button
          color="red"
          fullWidth
          mt="xl"
          onClick={() => goToEvent(eventId)}
        >
          Enter your event
        </Button>
      </Paper>
    </Container>
  );
};

export default JoinEventPage;
