import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFirebaseUserInfo } from "../../hooks/firebaseAuth.hook";
import { IProfile } from "../../interfaces/profile.interface";

interface IUserState {
  firebaseUserInfo: IFirebaseUserInfo | null;
  profile: IProfile | null;
  profiles: IProfile[] | null;
}

const initialstate: IUserState = {
  firebaseUserInfo: null,
  profile: null,
  profiles: null,
};

export const slice = createSlice({
  name: "user",
  initialState: initialstate,
  reducers: {
    setLoading: (state: any, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFirebaseUserInfo: (
      state: any,
      action: PayloadAction<IFirebaseUserInfo | null>
    ) => {
      state.firebaseUserInfo = action.payload;
    },
    setProfile: (state: any, action: PayloadAction<IProfile | null>) => {
      state.profile = action.payload;
    },
    setAllProfiles: (state: any, action: PayloadAction<[] | null>) => {
      state.profiles = action.payload;
    },
    resetProfile: (state: any) => {
      state = initialstate;
    },
  },
});

export const {
  setLoading,
  setFirebaseUserInfo,
  setProfile,
  setAllProfiles,
  resetProfile,
} = slice.actions;

export default slice.reducer;
