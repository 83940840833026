import {
  Container,
  Stepper,
  Button,
  Group,
  createStyles,
  Center,
  SimpleGrid,
  StepperProps,
  Card,
} from "@mantine/core";
import React, { useState, useEffect } from "react";
import ICreateEvent from "../../interfaces/create-event.interface";
import Layout from "../../components/low-level/layout";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  EventComplete,
  EventLanguages,
  EventDetails,
  EventPrivacy,
  EventSettings,
} from "../../components/low-level/new-event";
import { useUser } from "../../hooks/user.hook";

const NewEventPage = () => {
  const { id }: any = useParams();
  const [value, setValue] = useState<ICreateEvent>({
    name: "",
    organization: id,
    isPrivate: false,
    showPlayer: false,
    isAudioStreaming: false,
    otherWebsites: false,
    isRichText: false,
    showDownload: false,
    showSignLanguage: false,
    livePreviewLogos: [],
    isPreviewCustomLogo: false,
    // startDate: "",
    // startTime: "",
    // endDate: "",
    // endTime: "",
    urls: [],
    sources: [],
    translations: [],
    eventMembers: [],
    eventMembersEmail: [],
    eventMembersData: [],
  } as ICreateEvent);
  const { handlers: userHandlers, profiles }: any = useUser();

  useEffect(() => {
    userHandlers.getAllProfile();
  }, []);

  const { t } = useTranslation("common");
  const [active, setActive] = useState(0);

  const nextStep = () =>
    setActive((current: number) =>
      current < (!value.isRichText ? 4 : 3) ? current + 1 : current
    );
  const prevStep = () =>
    setActive((current: number) => (current > 0 ? current - 1 : current));

  function StyledStepper(props: StepperProps) {
    const { classes } = useStyles();
    return <Stepper classNames={classes} {...props} />;
  }

  return (
    <Layout
      scrollScreen={
        (active == 1 && value.isPrivate) ||
        active == 2 ||
        active == 3 ||
        active == 4
          ? // active == (!value.isRichText ? 4 : 3)
            true
          : false
      }
      eventName={t("events.new_event")}
    >
      <Container p="md" size="md">
        <Card>
          <StyledStepper
            color="red"
            active={active}
            onStepClick={setActive}
            breakpoint="sm"
          >
            <Stepper.Step
              label={t("events.details")}
              color="red"
              allowStepSelect={active > 0}
            >
              <Center style={{ width: "100%" }}>
                <EventDetails
                  prevStep={prevStep}
                  nextStep={nextStep}
                  value={value}
                  setValue={setValue}
                />
              </Center>
            </Stepper.Step>
            <Stepper.Step
              label={t("events.privacy")}
              allowStepSelect={active > 1}
            >
              <Center style={{ width: "100%" }}>
                <EventPrivacy
                  prevStep={prevStep}
                  nextStep={nextStep}
                  value={value}
                  setValue={setValue}
                />
              </Center>
            </Stepper.Step>
            <Stepper.Step
              label={t("events.settings")}
              allowStepSelect={active > 1}
            >
              <Center style={{ width: "100%" }}>
                <EventSettings
                  prevStep={prevStep}
                  nextStep={nextStep}
                  value={value}
                  setValue={setValue}
                />
              </Center>
            </Stepper.Step>
            {!value.isRichText && (
              <Stepper.Step
                label={t("events.translations")}
                allowStepSelect={active > 1}
              >
                <Center style={{ width: "100%" }}>
                  <EventLanguages
                    prevStep={prevStep}
                    nextStep={nextStep}
                    value={value}
                    setValue={setValue}
                  />
                </Center>
              </Stepper.Step>
            )}
            <Stepper.Completed>
              <Center style={{ width: "100%" }}>
                <EventComplete
                  prevStep={prevStep}
                  pageName="newEvent"
                  nextStep={nextStep}
                  value={value}
                  setValue={setValue}
                />
              </Center>
            </Stepper.Completed>
          </StyledStepper>
        </Card>
      </Container>
    </Layout>
  );
};

const useStyles = createStyles((theme, params, getRef) => ({
  root: {
    padding: theme.spacing.md,
  },

  separator: {
    height: 2,
    borderTop: `2px dashed ${
      theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[4]
    }`,
    borderRadius: theme.radius.xl,
    backgroundColor: "transparent",
  },

  separatorActive: {
    borderWidth: 0,
    backgroundImage: theme.fn.linearGradient(
      45,
      theme.colors.red[6],
      theme.colors.red[6]
    ),
  },

  stepIcon: {
    ref: getRef("stepIcon"),
    borderColor: "transparent",
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[3],
    borderRadius: 10,
    borderWidth: 0,
    color: "white",
  },

  step: {
    transition: "transform 150ms ease",
  },

  stepProgress: {
    transform: "scale(1.05)",

    [`& .${getRef("stepIcon")}`]: {},
  },

  stepCompleted: {
    [`& .${getRef("stepIcon")}`]: {
      borderWidth: 0,
      backgroundColor: "transparent",
      backgroundImage: theme.fn.linearGradient(
        45,
        theme.colors.red[7],
        theme.colors.red[7]
      ),
      boxShadow: theme.shadows.xs,
    },
  },
}));
export default NewEventPage;
