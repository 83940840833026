import i18n from "i18next";
import en from "./en/translation.json";
import de from "./de/translation.json";
import it from "./it/translation.json";
import fr from "./fr/translation.json";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import { useLocalStorage } from "@mantine/hooks";
import LngDetector from 'i18next-browser-languagedetector';

export const resources = {
  en: {
    common: en,
  },
  de: {
    common: de,
  },
  it: {
    common: it,
  },
  fr: {
    common: fr,
  },
} as const;

// localStorage.getItem("language")
i18n
  .use(detector)
  .use(backend)
  .use(LngDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources,
    interpolation: {
      escapeValue: false,
    },
  });
