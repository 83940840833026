import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import IPageProps from '../../interfaces/page.interface';
import { IProtectedRouteProps } from '../../interfaces/protectedPage.interface';

const ProtectedRoute: React.FunctionComponent<IProtectedRouteProps> = props => {

    const { children, redirectPath = '/login', isAllowed } = props;

    if (!isAllowed) {
        return <Navigate replace to={redirectPath} />;
    }
    return children ? children : <Outlet />;

}

export default ProtectedRoute;
