import React, { useEffect, useState } from "react";
import {
  ActionIcon,
  Badge,
  Button,
  Card,
  Checkbox,
  Grid,
  Group,
  Image,
  Input,
  InputWrapper,
  Loader,
  Select,
  Text,
  TextInput,
  Title,
  createStyles,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import useVideo from "../../../hooks/show-video.hooks";
import { useEvents } from "../../../hooks/event.hook";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { UploadImages } from "../../../hooks/uploadImages.hook";
import { useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";

const EditDatePage = ({ event }: any) => {
  const { classes } = useStyles();
  const { handlers: eventHandlers, events, loading } = useEvents();
  const [videoLinkError, setVideoLinkError] = useState(false);
  const [checked, setChecked] = useState(false);
  const [customLogos, setCustomLogos] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [videoTypes, setVideoTypes] = useState(event?.sources[0]?.type);
  const [videoUrl, setVideoUrl] = useState(event?.sources[0]?.src);
  const [videoTypeError, setVideoTypeError] = useState(false);
  const [currentDocId, setCurrentDocId] = useState<null | string>(null);
  const [uploadImages, setUploadImages]: any = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const { handlers, loading: imageLoading } = UploadImages();
  const [onUploadProgress, setOnUploadProgress]: any = useState(0);
  const [customPreImages, setCustomPreImages]: any = useState([]);
  const loadingEvent = useAppSelector(
    (state: RootState) => state.event.loading
  );
  const { video, currentRaiseHandState } = useVideo(currentDocId);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation("common");

  const videoTypesOptions = [
    { value: "application/x-mpegURL", label: "Live Stream" },
    { value: "video/youtube", label: "Youtube" },
    { value: "video/facebook", label: "Facebook" },
    { value: "video/vimeo", label: "Vimeo" },
    { value: "video/mp4", label: "mp4" },
  ];

  useEffect(() => {
    if (event && event?.showPlayer) {
      setChecked(event.showPlayer);
      setCustomPreImages(event.livePreviewLogos);
      setCustomLogos(event.isPreviewCustomLogo);
      setCurrentDocId(event.id);
    }
  }, [event]);

  useEffect(() => {
    if (checked && event?.sources[0]?.src?.length < 1) {
      if (videoTypes?.length > 0 && videoUrl?.length > 0) {
        if (videoUrl == event?.sources[0]?.src) {
          setSaveButtonDisabled(true);
        } else {
          setSaveButtonDisabled(false);
        }
      } else {
        setSaveButtonDisabled(true);
      }
    } else {
      if (event.showPlayer != checked) {
        setSaveButtonDisabled(false);
      } else {
        if (videoUrl?.length == 0) {
          setSaveButtonDisabled(true);
        } else {
          if (videoUrl?.length > 0) {
            setSaveButtonDisabled(false);
          } else {
            setSaveButtonDisabled(true);
          }
        }
      }
    }
  }, [checked, videoTypes, videoUrl]);

  const videoComplete = async () => {
    var sources = [{ src: videoUrl, type: videoTypes }];

    const data: any = {
      showPlayer: checked,
      sources: sources,
      isPreviewCustomLogo: customLogos,
    };

    await eventHandlers.eventUpdate(event.id, event.shortUid, data);
    if (uploadImages.length > 0) {
      await handlers.uploadPreviewImages(uploadImages, setOnUploadProgress);
      setUploadImages([])
    }
  };

  const showPreview = () => {
    video(currentDocId);
  };
  const dropzoneChildren = () => (
    <Group style={{ minHeight: 220, pointerEvents: "none" }}>
      <div>
        <Text size="xl" inline align="center">
          {t("event.drag_image")}
        </Text>
        <Text size="sm" color="dimmed" inline mt={7} align="center">
          {t("event.drag_image_subtitle")}
        </Text>
      </div>
    </Group>
  );

  const dropImages = (files: any) => {
    if (
      files?.length > 1 ||
      event?.livePreviewLogos?.length + files?.length + uploadImages?.length > 1
    ) {
      setErrorMessage(t("event.image_upload_max_length_5_error"));
      return;
    } else {
      setErrorMessage("");
      setUploadImages([...uploadImages, ...files]);
    }
  };
  const imageChildren = () => (
    <>
      {uploadImages.map((x: any, i: number) => (
        <div key={i} style={{ height: 200, width: 200, position: "relative" }}>
          {/* <button onClick={()=> setImages([])} style={{position:"absolute",right:0,top:0,width:100}}>X</button> */}
          <Image width={200} height={200} src={URL.createObjectURL(x)} />
        </div>
      ))}
    </>
  );

  const clearImages = () => {
    setUploadImages([]);
    setErrorMessage("");
  };
  const deleteImage = async (x: string) => {
    await handlers.deletePreviewImage(x);
    setUploadImages([]);
    setErrorMessage("");
  };
  return (
    <Card
      p="lg"
      style={{
        position: "relative",
        overflow: "initial",
        marginBottom: 10,
      }}
    >
      {loadingEvent && (
        <div className={classes.date_loading}>
          <Loader color="red" variant="dots" />
        </div>
      )}
      <Grid>
        <Grid.Col xs={12} lg={event?.showPlayer ? 10 : 12}>
          <Title>Video</Title>
          <Group mt={10}>
            <Checkbox
              checked={checked}
              onChange={(event) => setChecked(event.currentTarget.checked)}
              color="red"
            />
            <Text>Show Player</Text>
          </Group>
          <Select
            mt={10}
            label={t("events.platform")}
            placeholder={t("events.platform")}
            color="red"
            required
            disabled={!checked}
            error={videoTypeError && t("events.required_video_type")}
            defaultValue={videoTypes}
            onChange={(e: any) => setVideoTypes(e)}
            data={videoTypesOptions}
          />
          <InputWrapper
            mt={10}
            id="event-name-wrapper"
            required
            label={t("events.url")}
            error={videoLinkError && t("events.required_link")}
            style={{ width: "100%" }}
          >
            <Input
              id="link"
              disabled={!checked}
              defaultValue={videoUrl}
              onChange={(e: any) => setVideoUrl(e.target.value)}
              placeholder={t("events.video_link")}
              required
            />
          </InputWrapper>
          {checked && (
            <>
              <Group mt={10}>
                <Checkbox
                  checked={customLogos}
                  onChange={(event) =>
                    setCustomLogos(event.currentTarget.checked)
                  }
                  color="red"
                />
                <Text>Preview Custom Logos</Text>
              </Group>
              {customLogos && event?.livePreviewLogos?.length == 0 && (
                <>
                  <Dropzone
                    onDrop={(files) => dropImages(files)}
                    maxSize={5242880}
                    onReject={(rejectedFiles) => {
                      if (rejectedFiles[0].errors[0].code == "file-too-large") {
                        setErrorMessage(t("image_upload_max_size_5_mb"));
                      }
                    }}
                    mt={10}
                    classNames={{
                      root:
                        errorMessage != "" ? classes.dropzone_error : undefined,
                    }}
                    disabled={
                      event?.livePreviewLogos?.length == 1 ||
                      uploadImages?.length == 1
                    }
                    accept={[MIME_TYPES.png, MIME_TYPES.jpeg]}
                  >
                    {() => (
                      <Group direction="row" position="center" spacing="xl">
                        {uploadImages?.length > 0
                          ? imageChildren()
                          : dropzoneChildren()}
                      </Group>
                    )}
                  </Dropzone>
                  {uploadImages.length > 0 && (
                    <Group
                      mt="md"
                      direction="row"
                      position="right"
                      spacing="xl"
                    >
                      <Button
                        disabled={uploadImages?.length == 0}
                        onClick={clearImages}
                        color="red"
                      >
                        {t("clear")}
                      </Button>
                    </Group>
                  )}
                </>
              )}
            </>
          )}
          {customLogos &&
          <div className={classes.images_main}>
            <div className={classes.images_container}>
              {imageLoading ? (
                <div
                  style={{
                    height: 200,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Loader variant="dots" color="red" />
                </div>
              ) : (
                customPreImages?.map((x: any, i: number) => (
                  <div className={classes.image_contain} key={i}>
                    <div className={classes.image_area}>
                        <Image
                          width={200}
                          height={200}
                          fit="contain"
                          src={x.img}
                        />
                        <Button
                          fullWidth
                          onClick={() => deleteImage(x)}
                          color="red"
                        >
                          {t("delete")}
                        </Button>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          }
          <div className={classes.save_button}>
            <Button
              mt={20}
              style={{ height: 50 }}
              onClick={videoComplete}
              disabled={saveButtonDisabled}
              color={event?.sources[0]?.src ? "blue" : "green"}
            >
              {event?.sources[0]?.src ? "Update" : "Save"}
            </Button>
          </div>
        </Grid.Col>
        <Grid.Col xs={12} lg={event?.showPlayer ? 2 : 0}>
          {event?.sources[0]?.src && event?.showPlayer && (
            <>
              <Text>Show Video Preview</Text>
              <Button
                fullWidth
                style={{ height: 60 }}
                onClick={showPreview}
                color={currentRaiseHandState?.showPreview ? "red" : "gray"}
              >
                {currentRaiseHandState?.showPreview
                  ? "Preview is Live"
                  : "Preview is Hidden"}
              </Button>
            </>
          )}
        </Grid.Col>
      </Grid>
    </Card>
  );
};
const useStyles = createStyles((theme) => ({
  date_wrapper: {
    height: 40,
    width: "calc(100% - 20px)",
    border: "1px solid rgb(206, 212, 218)",
    borderRadius: 1,
    padding: "0px 10px",
    backgroundColor: "white",
  },
  save_button: {
    width: "100%",
    justifyContent: "flex-end",
    display: "flex",
  },
  start_stop_area: {
    height: 60,
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
  },
  start_button: {
    borderRadius: "50%",
    width: 50,
    height: 50,
  },
  date_wrapper_disabled: {
    backgroundColor: "#f1f3f5",
  },
  date_loading: {
    height: "100%",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.5)",
    width: "100%",
    position: "absolute",
    zIndex: 9999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  date_area: {
    marginTop: 20,
    width: "100%",
  },
  images_main: {
    width: "100%",
    display: "flex",
  },
  images_container: {
    width: "100%",
    marginTop: 10,
  },
  image_contain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  image_area: {
    padding: 5,
    margin: "5px 0px",
    borderRadius: 5,
    display: "flex",
    flexDirection: "column",
    border: "1px solid grey",
    alignItems: "flex-start",
    justifyContent: "center",
    "@media (max-width: 768px)": {
      width: "100%",
      flexDirection: "column",
    },
  },
  dropzone_error: {
    border: "2px dashed red",
  },
}));
export default EditDatePage;
