import {
  Container,
  createStyles,
  Title,
  Group,
  Text,
  ActionIcon,
  Menu,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "../../components/low-level/layout";
import Table from "../../components/low-level/table";
import { useTranslation } from "react-i18next";
import { useOrganization } from "../../hooks/organization.hook";
import { useMembers } from "../../hooks/members.hook";
import { Edit, Trash, DotsVertical } from "tabler-icons-react";
import { useRoles } from "../../hooks/roles.hooks";
import Role from "../../enums/role.enum";
import { useUser } from "../../hooks/user.hook";

const MembersPage: React.FunctionComponent = (props) => {
  const { id } = useParams();
  const { classes } = useStyles();
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const { handlers: rolesHandlers, OrganizationRole } = useRoles();
  const { handlers: userHandlers, profile }: any = useUser();
  const { handlers: membersHandlers, member, members }: any = useMembers();
  const [data, setData] = useState(members);

  useEffect(() => {
    if (id) membersHandlers.getAllMembers(id);
  }, [id]);

  const th = [
    { name: t("members.firstname"), sortName: "firstName", sort: true },
    { name: t("members.lastname"), sortName: "lastName", sort: true },
    { name: t("members.email"), sortName: "email", sort: true },
    { name: t("members.role"), sort: false, sortName: "role" },
  ];

  const removeItem = (memberId: string) => {
    membersHandlers.removeMember(id, memberId);
  };
  const editPage = (e: any) => {
    navigate(`/organizations/${id}/${e}/edit-member`);
  };
  const tr = (item: any) => {
    return (
      <>
        <td>
          <Group spacing="sm">
            <div>
              <Text
                className={classes.text}
                style={{ width: 100 }}
                weight={500}
              >
                {item?.firstName}
              </Text>
            </div>
          </Group>
        </td>
        <td>
          <Group spacing="sm">
            <div>
              <Text
                style={{ width: 100 }}
                weight={500}
                className={classes.text}
              >
                {item?.lastName}
              </Text>
            </div>
          </Group>
        </td>
        <td>
          <Group spacing="sm">
            <div>
              <Text className={classes.text} weight={500}>
                {item?.email}
              </Text>
            </div>
          </Group>
        </td>
        <td>
          <Group>
            <Text className={classes.text} style={{ width: "80px" }}>
              {item?.roles?.map((x: string) => x)}
            </Text>
            {item.email !== profile.email &&
              (OrganizationRole == Role.Admin ||
                OrganizationRole == Role.Owner) && (
                <ActionIcon>
                  <Menu
                    control={
                      <div>
                        <DotsVertical
                          size={20}
                          strokeWidth={1}
                          color={"black"}
                        />
                      </div>
                    }
                  >
                    <Menu.Item
                      onClick={() => editPage(item._id)}
                      icon={<Edit size={14} />}
                    >
                      {t("edit")}
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => removeItem(item._id)}
                      color="red"
                      icon={<Trash size={14} />}
                    >
                      {t("delete")}
                    </Menu.Item>
                  </Menu>
                </ActionIcon>
              )}
          </Group>
        </td>
      </>
    );
  };

  return (
    <Layout>
      <Container size="md" px="xs">
        <Table
          addButtonVisible={
            OrganizationRole == Role.Admin || OrganizationRole == Role.Owner
          }
          addButtonNavigate={`/organizations/${id}/new-member`}
          addButton="addMembers"
          tableName="members"
          th={th}
          tr={tr}
          data={members}
          loading={false}
          filter={false}
        />
      </Container>
    </Layout>
  );
};
const useStyles = createStyles((theme) => ({
  text: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "@media (max-width: 768px)": {
      width: "70%",
    },
  },
}));
export default MembersPage;
