import React, { useState, useEffect } from "react";
import {
  CardElement,
  useElements,
  ElementsConsumer,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  Container,
  Card,
  Center,
  Text,
  TextInput,
  Button,
} from "@mantine/core";
import axios from "axios";

const Index = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState();
  const [name, setName] = useState("");
  const handleSubmit = async (e: any) => {
    if (!stripe || !elements) {
      return;
    }
    e.preventDefault();
    const billingDetails = {
      name: name,
    };

    const { data: clientSecret } = await axios.post("/api/payment_intents", {
      amount: 100,
    });

    try {
      if (CardElement) {
        const cardElement: any = elements?.getElement(CardElement);
        const paymentMethodReq: any = await stripe?.createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: billingDetails,
        });

        if (paymentMethodReq.error) {
        }
        // const confirmedCardPayment = await stripe?.confirmedCardPayment({
        //   payment_method: paymentMethodReq?.paymentMethod?.id,
        // });
      }
      // setError(error);
    } catch (e) {
      throw e;
    }
  };

  const CARD_OPTIONS = {
    // iconStyle: "solid",
    style: {
      base: {
        iconColor: "#ced4da",
        color: "#000",
        fontWeight: "500",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "14px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#fce883",
        },
        "::placeholder": {
          color: "#ced4da",
        },
      },
      invalid: {
        iconColor: "#ef2961",
        color: "#ef2961",
      },
    },
    complete: {
      iconColor: "green",
      color: "green",
    },
    hidePostalCode: true,
  };
  return (
    <Container size="xs">
      <Card shadow="sm" m="lg" p="lg">
        <Center>
          <Text weight="bold">Payment</Text>
        </Center>
        <form onSubmit={handleSubmit}>
          <TextInput
            mt="xs"
            mb="xs"
            placeholder="Your name"
            label="Name"
            name="name"
            required
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setName(e.target.value)
            }
          />
          <Text
            style={{
              fontSize: 14,
              color: "#212529",
              fontWeight: "500",
            }}
          >
            Card Detail
          </Text>

          <div
            style={{
              padding: "8px ",
              borderRadius: 4,
              border: "1px solid #ced4da",
            }}
          >
            <CardElement options={CARD_OPTIONS} />
          </div>
          <Text
            style={{
              fontSize: 12,
              color: "red",
            }}
          >
            {/* {error.error && error.error.message} */}
          </Text>
          <Center mt="xs">
            <button>Submit</button>
          </Center>
        </form>
      </Card>
    </Container>
  );
};

export default Index;
