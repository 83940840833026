import React, { useState, useEffect } from "react";
import { createStyles, Container, Image, Center, Group } from "@mantine/core";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEvents } from "../../../hooks/event.hook";
const Footer = ({ currentEvent }: any) => {
  const { t, i18n } = useTranslation("common");
  let location = useLocation();
  const { classes } = useStyles();
  const { id }: any = useParams();

  return (
    <>
      <div className={`${classes.footer} `}>
        <Center style={{ width: "100%", height: "70%" }}>
          {id == "oymvo8" ? (
            <Group
              className={classes.logo_group}
              style={{ justifyContent: "center" }}
            >
              <a
                href="https://www.proinfirmis.ch/it/index.html"
                target="_blank"
                className={classes.logo}
                style={{ width: "30%" }}
              >
                <Image
                  alt="Apre pagina Pro Infirmis"
                  src={require("../../../assets/Pro_Infirmis_Logo_rgb.png")}
                />
              </a>

              <a
                href="https://www.proinfirmis.ch/it/politica/sessione-delle-persone-con-disabilita.html"
                target="_blank"
                className={classes.logo}
                style={{ width: "30%" }}
              >
                <Image
                  alt="Apre pagina sessione delle persone con disabilità"
                  src={require("../../../assets/pi_call_it.png")}
                />
              </a>
            </Group>
          ) : id == "npp88j" ? (
            <Group
              className={classes.logo_group}
              style={{ justifyContent: "center" }}
            >
              <a
                href="https://www.proinfirmis.ch/fr/index.html"
                target="_blank"
                className={classes.logo}
                style={{ width: "30%" }}
              >
                <Image
                  alt="Ouvre page Pro Infirmis"
                  src={require("../../../assets/Pro_Infirmis_Logo_rgb.png")}
                />
              </a>

              <a
                href="https://www.proinfirmis.ch/fr/politique/session-des-personnes-handicapees.html"
                target="_blank"
                className={classes.logo}
                style={{ width: "30%" }}
              >
                <Image
                  alt="Ouvre page session des personnes handicapées"
                  src={require("../../../assets/pi_call_fr.png")}
                />
              </a>
            </Group>
          ) : id == "pnopsb" ? (
            <Group
              className={classes.logo_group}
              style={{ justifyContent: "center" }}
            >
              <a
                href="https://www.proinfirmis.ch/index.html"
                target="_blank"
                className={classes.logo}
                style={{ width: "30%" }}
              >
                <Image
                  alt="Öffnet Seite Pro Infirmis"
                  src={require("../../../assets/Pro_Infirmis_Logo_rgb.png")}
                />
              </a>

              <a
                href="https://www.proinfirmis.ch/politik/behindertensession.html"
                target="_blank"
                className={classes.logo}
                style={{ width: "30%" }}
              >
                <Image
                  alt="Öffnet Seite Behindertensession"
                  src={require("../../../assets/pi_call_de.png")}
                />
              </a>
            </Group>
          ) : id == "xioiy8" ? (
            <Group className={classes.logo_group}>
              <Image
                className={classes.logo}
                styles={{ imageWrapper: { position: "initial" } }}
                alt="Schweizerische Eidgenossenschaft"
                src={require("../../../assets/logo4.png")}
              />

              <Image
                className={classes.logo}
                alt="Die Post"
                styles={{ imageWrapper: { position: "initial" } }}
                src={require("../../../assets/logo3.png")}
              />
              <Image
                className={classes.logo}
                alt="SBB CFF FFS"
                styles={{ imageWrapper: { position: "initial" } }}
                src={require("../../../assets/logo2.png")}
              />
              <Image
                className={classes.logo}
                alt="Digitale Verwaltung Schweiz"
                styles={{ imageWrapper: { position: "initial" } }}
                src={require("../../../assets/logo1.png")}
              />
              <Image
                className={classes.logo}
                alt="SWISS TXT"
                styles={{ imageWrapper: { position: "initial" } }}
                src={require("../../../assets/swisstxt.png")}
              />
            </Group>
          ) : id == "lvrkkc" ? (
            <Group
              className={classes.logo_group}
              style={{ justifyContent: "center" }}
            >
              <a
                href="https://www.fhnw.ch/de/die-fhnw/standorte/campus-olten/oeffentliche-vortraege-in-leichter-sprache"
                target="_blank"
                className={classes.logo}
                style={{ width: "30%" }}
              >
                <Image
                  alt="Schweizerische Eidgenossenschaft"
                  src={require("../../../assets/nw.png")}
                />
              </a>
            </Group>
          ) : (
            <>
              {currentEvent?.customLogos.length > 0 ? (
                <Group
                  className={classes.logo_group}
                  style={{ justifyContent: "center" }}
                >
                  {currentEvent?.customLogos.map((x: any,i:number) => (
                    <a
                    key={i}
                      href={x.url}
                      target="_blank"
                      style={{ height: "100%" }}
                      className={classes.logo}
                    >
                      <Image
                        fit="contain"
                        height="100%"
                        styles={{
                          figure: { height: "100%" },
                          root: { height: "100%" },
                          imageWrapper: { height: "100%" },
                        }}
                        alt="Apre pagina Pro Infirmis"
                        src={x.img}
                      />
                    </a>
                  ))}
                </Group>
              ) : (
                <Container pr="2%" className={classes.inner}>
                  <Center>
                    <a href="/organizations">
                      <Image
                        className={classes.main_logo}
                        lang="en"
                        alt="Swiss Logo"
                        fit="contain"
                        src={require("../../../assets/logo.png")}
                      />
                    </a>
                  </Center>
                </Container>
              )}
            </>
          )}
        </Center>
        <div className={classes.disclosure_area}>
          <a
            target="_blank"
            lang="en"
            href="https://www.swisstxt.ch/en/legal-disclosure/"
          >
            Legal Disclosure
          </a>
        </div>
      </div>
    </>
  );
};
const useStyles = createStyles((theme) => ({
  footer: {
    bottom: 0,
    height: "100%",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    // "@media (max-width: 768px)": {
    //   height: 50,
    // },
  },
  disclosure_area: {
    width: "100%",
    paddingLeft: 26,
    height: "35%",
    a: {
      fontSize: 12,
      textDecoration: "none",
      color: "black",
      "&:hover": {
        color: theme.colors.red[9],
      },
    },
    "@media (max-width: 768px)": {
      display: "flex",
      justifyContent: "center",
    },
  },
  inner: {
    width: "100%",
  },

  logo: {
    width: "18%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: " all ease-in .2s",
    "&:hover": {
      transition: " all ease-in .2s",
      transform: "scale(1.05)",
    },
  },
  main_logo: {
    width: "15vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 768px)": {
      width: "50vw",
    },
  },
  logo_group: {
    width: "49%",
    marginTop: 20,
    height: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    "@media (max-width: 1450px)": {
      width: "80%",
      height: "100%",
    },
    "@media (max-width: 768px)": {
      width: "100%",
      marginTop: 0,
    },
  },
}));
export default Footer;
