import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Container,
  TextInput,
  Text,
  Title,
  Image,
  Anchor,
  PasswordInput,
  Paper,
  createStyles,
  InputWrapper,
} from "@mantine/core";
import { useTranslation } from "react-i18next";

import { isValidEmail } from "../../../utils";
import { useSelector } from "react-redux";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import IPageProps from "../../../interfaces/page.interface";
import { useForm } from "react-hook-form";
import { useFirebaseAuth } from "../../../hooks/firebaseAuth.hook";
import { useUser } from "../../../hooks/user.hook";

const SignInPage: React.FunctionComponent<IPageProps> = (props) => {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation("common");

  const [loginStart, setLoginStart] = useState(false);
  const loading = useSelector((state: any) => state.firebaseAuth.loading);
  const navigate = useNavigate();
  const { login } = useFirebaseAuth();
  const { firebaseUserInfo, profile } = useUser();
  const isAuth = firebaseUserInfo !== null && profile !== null;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const emailCredential = watch("email");
  const passwordCredential = watch("password");
  const isValid = isValidEmail(emailCredential);
  const passwordCredentialIsFilled = passwordCredential?.length >= 5;

  const onSubmit = async () => {
    setLoginStart(true);
    login(emailCredential, passwordCredential);
  };

  useEffect(() => {
    if (!isAuth) {
      return;
    }
    navigate("/organizations");
  }, [isAuth]);

  return (
    <Container
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
      className={classes.main_container}
    >
      <Container className={classes.container}>
        <Title
          align="center"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 900,
          })}
        >
          {t("auth.welcome")}
        </Title>
        <Text color="dimmed" size="sm" align="center" mt={5}>
          {t("auth.dont_have_account")}{" "}
          <Anchor<"a"> href="/registration" size="sm">
            {t("auth.create_account_login")}
          </Anchor>
        </Text>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <InputWrapper
            id="email"
            required
            error={loginStart && !isValid && t("auth.required")}
          >
            <TextInput
              {...register("email")}
              label={t("auth.email")}
              placeholder={t("auth.email_placeholder")}
              required
            />
          </InputWrapper>
          <InputWrapper
            id="password"
            required
            error={
              loginStart && !passwordCredentialIsFilled && t("auth.required")
            }
          >
            <PasswordInput
              {...register("password")}
              label={t("auth.password")}
              placeholder={t("auth.password").toLowerCase()}
              required
              mt="md"
              visibilityToggleIcon={({ reveal, size }) =>
                reveal ? (
                  <AiOutlineEye size={20} />
                ) : (
                  <AiOutlineEyeInvisible size={20} />
                )
              }
            />
          </InputWrapper>
          <Text align="right" size="xs" mt="xs">
            {t("auth.forget_password")}{" "}
            <Anchor<"a"> href="/password-reset" size="sm">
              {t("auth.reset_password_login")}
            </Anchor>
          </Text>
          <Button
            color="red"
            fullWidth
            mt="xl"
            onClick={() => onSubmit()}
            loading={loading}
          >
            {t("auth.login")}
          </Button>
        </Paper>
      </Container>
      <Image
        height={40}
        mt={50}
        fit="contain"
        src={require("../../../assets/logo.png")}
      />
    </Container>
  );
};
const useStyles = createStyles((theme) => ({
  main_container: {
    "@media (max-width: 768px)": {
      height: "calc(100vh - 60px) !important",
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
    },
  },
  container: {
    width: "45%",
    "@media (max-width: 768px)": {
      width: "100%",
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
    },
  },
}));
export default SignInPage;
