import React, { useState } from "react";
import {
  ref,
  storage,
  uploadBytesResumable,
  listAll,
  getDownloadURL,
  deleteObject,
} from "../api/firebase";
import { useEvents } from "./event.hook";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { AppDispatch, RootState } from "../store/store";
import { setLoading, setImages } from "../store/reducers/upload.reducer";

export const UploadImages = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const { handlers: eventsHandlers, events, currentEvent } = useEvents();

  const loading: any = useAppSelector(
    (state: RootState) => state.uploadImage.loading
  );

  const images: any = useAppSelector(
    (state: RootState) => state.uploadImage.images
  );
  const uploadImages = async (
    files: File[],
    onUploadProgress: (progress: number) => void
  ) => {
    dispatch(setLoading(true));
    const promises = files.map((file, i) => {
      const fileRef = ref(
        storage,
        `public-event-images/${currentEvent?.organization?.id}/${
          currentEvent.shortUid
        }/${Math.floor(Math.random() * Date.now()).toString(36)}`
      );
      const uploadTask = uploadBytesResumable(fileRef, file);

      uploadTask.on("state_changed", (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (progress == 100) {
          dispatch(setLoading(false));
        }
        onUploadProgress(progress);
      });

      return uploadTask;
    });

    await Promise.all(promises);
    const urls = await Promise.all(
      promises.map(async (p) => {
        const url = await getDownloadURL(p.snapshot.ref);
        return url;
      })
    );
    if (urls.length > 0) {
      const customLogos = urls.map((url) => ({ img: url, header: false }));

      const data = {
        customLogos: [...currentEvent?.customLogos, ...customLogos],
      };
      const eventId: any = currentEvent.id;
      const shortUid: any = currentEvent.shortUid;

      await eventsHandlers.eventUpdate(eventId, shortUid, data);
    }
  };
  const uploadPreviewImages = async (
    files: File[],
    onUploadProgress: (progress: number) => void
  ) => {
    dispatch(setLoading(true));
    const promises = files.map((file, i) => {
      const fileRef = ref(
        storage,
        `public-event-images/${currentEvent?.organization?.id}/${
          currentEvent.shortUid
        }/${Math.floor(Math.random() * Date.now()).toString(36)}`
      );
      const uploadTask = uploadBytesResumable(fileRef, file);

      uploadTask.on("state_changed", (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (progress == 100) {
          dispatch(setLoading(false));
        }
        onUploadProgress(progress);
      });

      return uploadTask;
    });

    await Promise.all(promises);
    const urls = await Promise.all(
      promises.map(async (p) => {
        const url = await getDownloadURL(p.snapshot.ref);
        return url;
      })
    );
    if (urls.length > 0) {
      const livePreviewLogos = urls.map((url) => ({ img: url, header: false }));

      const data = {
        livePreviewLogos: [...currentEvent?.livePreviewLogos, ...livePreviewLogos],
      };
      const eventId: any = currentEvent.id;
      const shortUid: any = currentEvent.shortUid;

      await eventsHandlers.eventUpdate(eventId, shortUid, data);
    }
  };

  const updateUrl = async (data: any) => {
    const eventId: any = currentEvent.id;
    const shortUid: any = currentEvent.shortUid;
    const _data = {
      customLogos: data,
    };
    await eventsHandlers.eventUpdate(eventId, shortUid, _data);
  };
  // const getImage = async () => {
  //   console.log("zxccurrentEvent", currentEvent);
  //   const storageRef = ref(
  //     storage,
  //     `public-event-images/${currentEvent?.organization?.id}/${currentEvent.shortUid}`
  //   );

  //   listAll(storageRef)
  //     .then((res) => {
  //       const promises = res.items.map((itemRef) => {
  //         // Her bir resim referansı için indirme URL'sini alma
  //         return getDownloadURL(itemRef);
  //       });
  //       return Promise.all(promises);
  //     })
  //     .then((downloadURLs: any) => {
  //       dispatch(setImages(downloadURLs));
  //     })
  //     .catch((error) => {
  //       // Hata işleme kodu
  //     });
  // };
  const deletePreviewImage = async (imageUrl: string) => {
    dispatch(setLoading(true));
    try {
      // const storageRef = ref(storage, imageUrl);
      // await deleteObject(storageRef);
      const newArr = currentEvent.livePreviewLogos?.filter(
        (y: any) => y != imageUrl
      );
      const data = {
        livePreviewLogos: newArr,
      };
      const eventId: any = currentEvent.id;
      const shortUid: any = currentEvent.shortUid;
      await eventsHandlers.eventUpdate(eventId, shortUid, data);

      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));
      console.log("zxce", e);
    }
  };
  const deleteImage = async (imageUrl: string) => {
    dispatch(setLoading(true));
    try {
      // const storageRef = ref(storage, imageUrl);
      // await deleteObject(storageRef);
      const newArr = currentEvent.customLogos?.filter(
        (y: any) => y != imageUrl
      );
      const data = {
        customLogos: newArr,
      };
      const eventId: any = currentEvent.id;
      const shortUid: any = currentEvent.shortUid;
      await eventsHandlers.eventUpdate(eventId, shortUid, data);

      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));
      console.log("zxce", e);
    }
  };

  const handlers = {
    uploadImages: uploadImages,
    // getImage: getImage,
    updateUrl: updateUrl,
    deleteImage: deleteImage,
    uploadPreviewImages:uploadPreviewImages,
    deletePreviewImage:deletePreviewImage
  };
  return {
    handlers,
    loading,
    images,
  };
};
