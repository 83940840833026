import {
  Container,
  Stepper,
  Button,
  Group,
  createStyles,
  Input,
  Center,
  InputWrapper,
  SimpleGrid,
  StepperProps,
  TextInput,
  Card,
  Title,
  Text,
} from "@mantine/core";
import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useOrganization } from "../../hooks/organization.hook";
import Layout from "../../components/low-level/layout";
import { useTranslation } from "react-i18next";
import { User } from "tabler-icons-react";
import { ICreateOrganization } from "../../interfaces/create-organization.interface";

const NewEventPage = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const { handlers } = useOrganization();
  const { classes } = useStyles();

  const [organization, setOrganization] = useState<ICreateOrganization>(
    {} as ICreateOrganization
  );
  const [active, setActive] = useState(0);

  const nextStep = () =>
    setActive((current: number) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActive((current: number) => (current > 0 ? current - 1 : current));

  const nameForm: any = useForm();
  const translationForm = useForm();

  const handleSubmitName = (data: { eventName: string }) => {
    setOrganization({ ...organization, name: data.eventName });
    nextStep();
  };

  const handleSubmitTranslations = (data: any) => {
    const translationsArray: string[] = [];
    Object.keys(data).map((key) => {
      if (data[key]) {
        translationsArray.push(key);
      }
    });
    setOrganization({ ...organization });
    nextStep();
  };

  const handleSubmitEvent = async (event: ICreateOrganization) => {
    const data = { name: event.name };
    handlers.createOrganization(data);
    navigate("/organizations");
  };

  function StyledStepper(props: StepperProps) {
    return <Stepper classNames={classes} {...props} />;
  }

  return (
    <Layout eventName={t("organization.new_organization")}>
      <Container pt="md" size="md">
        <Card>
          <StyledStepper
            color="red"
            active={active}
            onStepClick={setActive}
            breakpoint="sm"
          >
            <Stepper.Step
              color="red"
              label={t("organization.organization_name")}
              allowStepSelect={active > 0}
            >
              <Center style={{ width: "100%" }}>
                <SimpleGrid cols={1} style={{ width: "100%" }}>
                  <InputWrapper
                    id="event-name-wrapper"
                    required
                    label={t("organization.organization_name")}
                    description={t("organization.please_enter_organization_name")}
                    error={
                      nameForm.formState.errors.eventName?.message && (
                        <span>
                          {nameForm.formState.errors.eventName?.message}
                        </span>
                      )
                    }
                    style={{ width: "100%" }}
                  >
                    <Input
                      id="event-name"
                      placeholder={t("organization.organization_name")}
                      {...nameForm.register("eventName", {
                        required: `${t("organization.organization_name_required")}`,
                        minLength: {
                          value: 3,
                          message: t("organization.organization_name_min_3")
                        },
                        maxLength: {
                          value: 40,
                          message: t("organization.organization_name_max_40")
                        },
                      })}
                    />
                  </InputWrapper>
                  <Group position="apart">
                    <div></div>
                    <Button
                      color="red"
                      onClick={nameForm.handleSubmit((data: any) =>
                        handleSubmitName(data)
                      )}
                    >
                     {t("next_step")}
                    </Button>
                  </Group>
                </SimpleGrid>
              </Center>
            </Stepper.Step>
            <Stepper.Completed>
              <Center style={{ width: "100%" }}>
                <SimpleGrid cols={1} className={classes.grid}>
                  <Card className={classes.card} shadow="sm" p="lg">
                    <Card p="xs" className={classes.card_icon}>
                      <Group>
                        <User />
                        <Text weight={500}>{t("organization.name_of_organization")}</Text>
                        <Text>{organization.name}</Text>
                      </Group>
                    </Card>
                  </Card>

                  <Group position="apart">
                    <Button variant="default" onClick={prevStep}>
                    {t("back")}
                    </Button>
                    <Button
                      color="red"
                      onClick={() => handleSubmitEvent(organization)}
                    >
                    {t("organization.create")}
                    </Button>
                  </Group>
                </SimpleGrid>
              </Center>
            </Stepper.Completed>
          </StyledStepper>
        </Card>
      </Container>
    </Layout>
  );
};

const useStyles = createStyles((theme, params, getRef) => ({
  root: {
    padding: theme.spacing.md,
  },

  separator: {
    height: 2,
    borderTop: `2px dashed ${
      theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[4]
    }`,
    borderRadius: theme.radius.xl,
    backgroundColor: "transparent",
  },

  separatorActive: {
    borderWidth: 0,
    backgroundImage: theme.fn.linearGradient(
      45,
      theme.colors.red[6],
      theme.colors.red[6]
    ),
  },

  stepIcon: {
    ref: getRef("stepIcon"),
    borderColor: "transparent",
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[3],
    borderRadius: 10,
    borderWidth: 0,
    color: "white",
  },

  step: {
    transition: "transform 150ms ease",
  },

  stepProgress: {
    transform: "scale(1.05)",

    [`& .${getRef("stepIcon")}`]: {},
  },

  stepCompleted: {
    [`& .${getRef("stepIcon")}`]: {
      borderWidth: 0,
      backgroundColor: "transparent",
      backgroundImage: theme.fn.linearGradient(
        45,
        theme.colors.red[7],
        theme.colors.red[7]
      ),
      boxShadow: theme.shadows.xs,
    },
  },
  card: {
    backgroundColor: theme.white[1],
  },
  grid: {
    width: "80%",
    "@media (max-width: 769px)": {
      width: "100%",
    },
  },
  card_icon: {
    backgroundColor: theme.colors.gray[0],
    width: "100%",
  },
}));
export default NewEventPage;
