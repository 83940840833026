import { useState, Dispatch, useEffect, SetStateAction } from "react";
import {
  createStyles,
  ActionIcon,
  Group,
  Center,
  Container,
  Menu,
  Image,
} from "@mantine/core";
import {
  ArrowsMoveHorizontal,
  ArrowsMoveVertical,
  ArrowNarrowDown,
  ArrowsMaximize,
  ArrowsMinimize,
  HandStop,
  Settings,
  ChevronUp,
  ChevronDown,
  Palette,
  Language,
  Microphone,
  MicrophoneOff,
  Volume,
  VolumeOff,
} from "tabler-icons-react";

import ColorSettings from "./colorSettings";
import {
  useDisclosure,
  useLocalStorage,
  useViewportSize,
} from "@mantine/hooks";
import { IoPeopleOutline, IoImagesOutline } from "react-icons/io5";
import Player from "../player";
import { IEvent } from "../../../interfaces/event.interface";
import TextAreaEditor from "./textAreaEditor";
import RichTextEditor from "./richText";
import TranslationAreaEditor from "./translationAreaEditor";
import useRaiseHand from "../../../hooks/raise-hand.hooks";
import EditEventPage from "../../../pages/EditEventPage/EditEventPage";
import EditEventMembersPage from "../../../pages/EditMemberPage/EditEventMembersPageTest";
import Role from "../../../enums/role.enum";
import Loading from "../loading";
import { useTranslation } from "react-i18next";
import UploadEventImagePage from "../event/EventUploadImage";
import { BsCalendarDate, BsCameraVideo, BsFiletypeDocx } from "react-icons/bs";
import EditDatePage from "../event/EventEditDate";
import EventEditVideo from "../event/EventEditVideo";
import SignLanguage from "../event/SignLanguage";
import { useNavigate, useParams } from "react-router-dom";
import { RiVideoUploadLine } from "react-icons/ri";
import EventUploadVideo from "../event/EventUploadVideo";

const InterpreterArea = (props: {
  event: IEvent;
  setOpenEditArea: Dispatch<SetStateAction<any>>;
  openEditArea: boolean;
  setOpened: any;
  setFixed: any;
  audioJoinState: boolean;
  leave: any;
  handleJoin: any;
  handleJoinAsHost: any;
  audioConnecting: boolean;
}) => {
  const { id }: any = useParams();
  let navigate = useNavigate();
  const { height, width } = useViewportSize();
  const [variation, setVariation]: any = useLocalStorage({
    key: "variation",
    defaultValue: 1,
  });
  const [variationHorizontal, setVariationHorizontal]: any = useLocalStorage({
    key: "variationHorizontal",
    defaultValue: 2,
  });
  const { event }: any = props;

  const [currentTranslation, setCurrentTranslation] = useState<null | string>(
    null
  );
  const [closeMenu, setCloseMenu] = useState(false);
  const { t, i18n } = useTranslation("common");

  const [editorFullSize, setEditorFullSize] = useLocalStorage({
    key: "editorFullSize",
    defaultValue: false,
  });
  const [colorMenu, setColorMenu] = useDisclosure(false);
  const [textAreaColor, setTextAreaColor] = useLocalStorage({
    key: "textAreaColorEvent",
    defaultValue: "dark",
  });
  const [currentDocId, setCurrentDocId] = useState<null | string>(null);

  const { initRaiseHands, raiseHand, currentRaiseHandState, raiseHandActive } =
    useRaiseHand(currentDocId, currentTranslation);

  const [showTranslation, setShowTranslation] = useState(false);
  const [disabledSettingsButtons, setDisabledSettingsButtons] = useState(false);
  const [openMembers, setOpenMembers] = useState(false);
  const [openImageUpload, setOpenImageUpload] = useState(false);
  const [openVideoUpload, setOpenVideoUpload] = useState(false);
  const [openDateArea, setOpenDateArea] = useState(false);
  const [openVideoArea, setOpenVideoArea] = useState(false);
  const [signLanguage, setSignLanguage] = useState(false);
  const [autoScroll, setAutoScroll] = useState(true);
  const [isPrivate, setIsPrivate] = useState(null);
  const [downloadWorld, setDownloadDocx] = useState(false);
  const [showDownloadDocx, setShowDownloadDocx] = useState(false);

  const [changeSize, setChangeSize] = useState(false);
  const [changeSizeNoVideo, setChangeSizeNoVideo] = useState(false);
  const { classes } = useStyles();
  const playerOptions = {};
  const [player, setPlayer] = useState(null);
  const [fontSize, setFontSize] = useLocalStorage({
    key: "eventFontSize",
    defaultValue: 18,
  });

  useEffect(() => {
    if (
      props?.event?.usersRole === Role.SignLanguage &&
      props?.event?.showSignLanguage
    ) {
      setSignLanguage(true);
    }
  }, [props.event]);
  useEffect(() => {
    if (width < 550) {
      setVariationHorizontal(3);
    }
  }, []);
  useEffect(() => {
    if (event) {
      setCurrentDocId(event.id + "_main");
    }
  }, [event]);

  const ChangeSizeNoVideo = () => {
    setChangeSizeNoVideo(!changeSizeNoVideo);
  };
  const ChangeSize = () => {
    setChangeSize(!changeSize);
  };

  const handleAudioTracks = (tracks: any) => {
    // setAudioTracks(tracks);
    // setCurrentTrack(tracks[0]);
  };
  const handleSetTranslation = (translation: string) => {
    setShowTranslation(true);
    setCurrentTranslation(translation);
    setCurrentDocId(translation + "-" + event.id);
  };
  const handleShowSource = () => {
    setShowTranslation(false);
    setCurrentTranslation(null);
    setCurrentDocId(event.id + "_main");
  };
  const handleShowSourceEdit = () => {
    setShowTranslation(true);
    setCurrentTranslation("preprocessed");
    setCurrentDocId(event.id + "-preprocessed");
  };

  const handleClickOnAutoScroll = () => {
    setAutoScroll(!autoScroll);
  };

  if (!event) return <Loading />;

  const openEdit = () => {
    props.setOpened(false);
    props.setFixed(false);
    setSignLanguage(false);
    props.setOpenEditArea(!props.openEditArea);
  };

  const changeScale = () => {
    if (variation == 3) setVariation(1);
    else {
      setVariation(variation + 1);
    }
  };
  const changeHorizontalScale = () => {
    if (variationHorizontal == 3) setVariationHorizontal(1);
    else {
      setVariationHorizontal(variationHorizontal + 1);
    }
  };

  const fullSize = () => {
    setEditorFullSize(!editorFullSize);
  };
  const getTextAreaColor = (color: string) => {
    setTextAreaColor(color);
  };
  const color = () => {
    var currentColor: any = "";
    if (textAreaColor == "light")
      currentColor = {
        backgroundColor: "white",
        // overflowY: "hidden",
        color: "black",
        border: "0px solid black",
      };

    if (textAreaColor == "light_text_background")
      currentColor = {
        backgroundColor: "black",
        color: "white",
      };
    if (textAreaColor == "dark")
      currentColor = {
        backgroundColor: "black",
        color: "white",
      };
    if (textAreaColor == "green")
      currentColor = {
        backgroundColor: "rgb(0, 255, 0)",
        color: "white",
        border: "0px solid black",
      };
    if (textAreaColor == "green_black")
      currentColor = {
        backgroundColor: "rgb(0, 255, 0)",
        color: "black",
        border: "0px solid black",
      };
    if (textAreaColor == "blue")
      currentColor = {
        backgroundColor: "rgb(0, 51, 204)",
        color: "white",
        border: "0px solid black",
      };
    if (textAreaColor == "blue_black")
      currentColor = {
        backgroundColor: "rgb(0, 51, 204)",
        color: "black",
        border: "0px solid black",
      };
    if (textAreaColor == "transparent_text")
      currentColor = {
        backgroundColor: "rgb(0,0,0,.5)",
        color: "white",
        border: "0px solid black",
        overflowY: "hidden",
        textShadow: "2px 2px #000000",
      };
    if (textAreaColor == "transparent")
      currentColor = {
        backgroundColor: "transparent",
        color: "white",
        border: "0px solid black",
        overflowY: "hidden",
        textShadow: "2px 2px #000000",
      };
    return currentColor;
  };
  const languageList = () => {
    return (
      <Menu
        styles={{
          body: { zIndex: 999 },
        }}
        control={
          <ActionIcon
            ml={editorFullSize ? 0 : "xs"}
            size={40}
            mb={editorFullSize ? 0 : 5}
            disabled={disabledSettingsButtons}
            variant="default"
          >
            <Language
              size={25}
              strokeWidth={2}
              color={
                !currentTranslation
                  ? disabledSettingsButtons
                    ? "#ced4da"
                    : "black"
                  : "black"
              }
            />
          </ActionIcon>
        }
      >
        <Menu.Label>{t("language")}</Menu.Label>
        {event?.translations.map((x: any, i: number) => (
          <Menu.Item
            key={i}
            aria-label={t(`${x}-language`)}
            onClick={() => handleSetTranslation(x)}
          >
            {x.toUpperCase()}
          </Menu.Item>
        ))}
        <Menu.Item aria-label={t("source")} onClick={() => handleShowSource()}>
          {t("source")}
        </Menu.Item>
        <Menu.Item
          aria-label={t("source")}
          onClick={() => handleShowSourceEdit()}
        >
          {t("source_edit")}
        </Menu.Item>
      </Menu>
    );
  };

  const buttons = () => {
    return (
      <>
        {props.event.usersRole !== Role.Customer && !editorFullSize && (
          <ActionIcon
            size={40}
            ml={editorFullSize ? 0 : "xs"}
            mb={editorFullSize ? 0 : 5}
            color={raiseHandActive ? "green" : "grey"}
            variant={raiseHandActive ? "filled" : "default"}
            disabled={disabledSettingsButtons}
          >
            <HandStop size={25} onClick={() => raiseHand(currentDocId)} />
          </ActionIcon>
        )}

        {props.event.usersRole !== Role.Customer &&
          props.event.isAudioStreaming &&
          !props.audioConnecting && (
            <ActionIcon
              size={40}
              disabled={disabledSettingsButtons}
              ml={editorFullSize ? 0 : "xs"}
              mb={editorFullSize ? 0 : 5}
              color={props.audioJoinState ? "green" : "grey"}
              variant={props.audioJoinState ? "filled" : "default"}
            >
              {!props.audioJoinState ? (
                <VolumeOff size={25} onClick={() => props.handleJoin()} />
              ) : (
                <Volume size={25} onClick={() => props.leave()} />
              )}
            </ActionIcon>
          )}

        {props.event.usersRole !== Role.Customer &&
          props.event.isAudioStreaming &&
          props.audioConnecting && (
            <ActionIcon
              size={40}
              ml={editorFullSize ? 0 : "xs"}
              mb={editorFullSize ? 0 : 5}
              color={"orange"}
              disabled={disabledSettingsButtons}
              variant={"filled"}
            >
              <Volume size={25} />
            </ActionIcon>
          )}

        {props.event.usersRole === Role.Customer &&
          props.event.isAudioStreaming &&
          !props.audioConnecting && (
            <ActionIcon
              size={40}
              ml={editorFullSize ? 0 : "xs"}
              mb={editorFullSize ? 0 : 5}
              color={props.audioJoinState ? "green" : "grey"}
              disabled={disabledSettingsButtons}
              variant={props.audioJoinState ? "filled" : "default"}
            >
              {!props.audioJoinState ? (
                <MicrophoneOff
                  size={25}
                  onClick={() => props.handleJoinAsHost()}
                />
              ) : (
                <Microphone size={25} onClick={() => props.leave()} />
              )}
            </ActionIcon>
          )}

        {props.event.usersRole === Role.Customer &&
          props.event.isAudioStreaming &&
          props.audioConnecting && (
            <ActionIcon
              ml={editorFullSize ? 0 : "xs"}
              size={40}
              mb={editorFullSize ? 0 : 5}
              color={"orange"}
              disabled={disabledSettingsButtons}
              variant={"filled"}
            >
              <Microphone size={25} />
            </ActionIcon>
          )}

        <ActionIcon
          ml={editorFullSize ? 0 : "xs"}
          size={40}
          mb={editorFullSize ? 0 : 5}
          color={autoScroll ? "red" : "grey"}
          variant={autoScroll ? "filled" : "default"}
          disabled={disabledSettingsButtons}
          onClick={handleClickOnAutoScroll}
        >
          <ArrowNarrowDown size={25} />
        </ActionIcon>
        <>
          <>
            <ActionIcon
              onClick={() => {
                if (fontSize >= 6 && fontSize < 40) {
                  setFontSize(fontSize + 2);
                }
              }}
              ml={editorFullSize ? 0 : "xs"}
              mb={editorFullSize ? 0 : 5}
              disabled={disabledSettingsButtons}
              size={40}
              variant="default"
            >
              <div className={`${classes.fontSizeIcon}`}>
                <h2 className={`${classes.a}`}>A</h2>
                <h4
                  className={`${classes.plus} ${classes.a}`}
                  style={{
                    color: disabledSettingsButtons ? "#ced4da" : "black",
                  }}
                >
                  +
                </h4>
              </div>
            </ActionIcon>

            <ActionIcon
              onClick={() => {
                if (fontSize > 6 && fontSize <= 40) {
                  setFontSize(fontSize - 2);
                }
              }}
              disabled={disabledSettingsButtons}
              ml={editorFullSize ? 0 : "xs"}
              size={40}
              mb={editorFullSize ? 0 : 5}
              variant="default"
            >
              <div className={`${classes.fontSizeIcon}`}>
                <h3 className={`${classes.a}`}>A</h3>
                <h3
                  className={`${classes.plus} ${classes.a}`}
                  style={{
                    color: disabledSettingsButtons ? "#ced4da" : "black",
                  }}
                >
                  -
                </h3>
              </div>
            </ActionIcon>
          </>
          {!props.openEditArea &&
            !event.isRichText &&
            currentTranslation == null && (
              <Menu
                opened={colorMenu}
                size={height < 700 ? "auto" : "xs"}
                styles={{
                  body: {
                    display: "flex",
                    zIndex: 999,
                    marginRight: editorFullSize ? 45 : 0,
                    flexDirection: height < 700 ? "row" : "column",
                  },
                }}
                control={
                  <ActionIcon
                    ml={editorFullSize ? 0 : "xs"}
                    size={40}
                    disabled={disabledSettingsButtons}
                    mb={editorFullSize ? 0 : 5}
                    variant="default"
                  >
                    <Palette size={25} />
                  </ActionIcon>
                }
                onOpen={setColorMenu.open}
                onClose={setColorMenu.close}
              >
                <ColorSettings
                  variation={0}
                  getTextAreaColor={getTextAreaColor}
                />
              </Menu>
            )}
          {!props.openEditArea && !editorFullSize && (
            <>
              <ActionIcon
                onClick={fullSize}
                ml="xs"
                size={40}
                mb={5}
                disabled={disabledSettingsButtons}
                variant="default"
              >
                <ArrowsMaximize size={25} />
              </ActionIcon>
              {props.event.showPlayer && (
                <ActionIcon
                  mb={5}
                  disabled={disabledSettingsButtons}
                  color={
                    !props.event.showPlayer
                      ? props.event.usersRole === Role.Customer ||
                        props.event.usersRole === Role.User
                        ? changeSizeNoVideo
                          ? "red"
                          : "gray"
                        : changeSize
                        ? "gray"
                        : "red"
                      : changeSize
                      ? "red"
                      : "gray"
                  }
                  variant={"filled"}
                  onClick={() =>
                    !props.event.showPlayer
                      ? props.event.usersRole === Role.Customer ||
                        props.event.usersRole === Role.User
                        ? ChangeSizeNoVideo()
                        : ChangeSize()
                      : ChangeSize()
                  }
                  ml="xs"
                  size={40}
                >
                  <ArrowsMoveVertical style={{ width: "90%" }} size="md" />
                </ActionIcon>
              )}
              {!props.event.showPlayer && (
                <>
                  <ActionIcon
                    mb={5}
                    variant="default"
                    ml="xs"
                    disabled={disabledSettingsButtons}
                    onClick={changeScale}
                    size={40}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h4 className={classes.variation}>{variation}</h4>
                      <ArrowsMoveVertical style={{ width: "80%" }} size="md" />
                    </div>
                  </ActionIcon>
                  <ActionIcon
                    mb={5}
                    variant="default"
                    ml="xs"
                    disabled={disabledSettingsButtons}
                    onClick={changeHorizontalScale}
                    size={40}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h4 className={classes.variation}>
                        {variationHorizontal}
                      </h4>
                      <ArrowsMoveHorizontal
                        style={{ width: "80%" }}
                        size="md"
                      />
                    </div>
                  </ActionIcon>
                </>
              )}
            </>
          )}

          {languageList()}
        </>
        {(props.event.usersRole === Role.Admin ||
          props.event.usersRole === Role.Owner) &&
          !editorFullSize && (
            <>
              <ActionIcon
                color={props.openEditArea ? "red" : "grey"}
                variant={props.openEditArea ? "filled" : "default"}
                onClick={openEdit}
                ml="xs"
                mb={5}
                size={40}
                disabled={disabledSettingsButtons}
              >
                <Settings size={25} />
              </ActionIcon>

              {props.openEditArea && (
                <>
                  <ActionIcon
                    color={openMembers ? "red" : "grey"}
                    disabled={disabledSettingsButtons}
                    variant={openMembers ? "filled" : "default"}
                    onClick={() => {
                      setOpenMembers(!openMembers);
                      if (
                        openImageUpload ||
                        openDateArea ||
                        openVideoArea ||
                        openVideoUpload ||
                        signLanguage
                      ) {
                        setSignLanguage(false);
                        setOpenImageUpload(false);
                        setOpenVideoUpload(false);
                        setOpenDateArea(false);
                        setOpenVideoArea(false);
                      }
                    }}
                    ml="xs"
                    mb={5}
                    size={40}
                  >
                    <IoPeopleOutline size={25} />
                  </ActionIcon>
                  <ActionIcon
                    color={openImageUpload ? "red" : "grey"}
                    disabled={disabledSettingsButtons}
                    variant={openImageUpload ? "filled" : "default"}
                    onClick={() => {
                      setOpenImageUpload(!openImageUpload);
                      if (
                        openMembers ||
                        openDateArea ||
                        openVideoArea ||
                        openVideoUpload ||
                        signLanguage
                      ) {
                        setSignLanguage(false);
                        setOpenMembers(false);
                        setOpenDateArea(false);
                        setOpenVideoUpload(false);
                        setOpenVideoArea(false);
                      }
                    }}
                    ml="xs"
                    mb={5}
                    size={40}
                  >
                    <IoImagesOutline size={25} />
                  </ActionIcon>
                  <ActionIcon
                    color={openVideoUpload ? "red" : "grey"}
                    disabled={disabledSettingsButtons}
                    variant={openVideoUpload ? "filled" : "default"}
                    onClick={() => {
                      setOpenVideoUpload(!openVideoUpload);
                      if (
                        openMembers ||
                        openDateArea ||
                        openVideoArea ||
                        openImageUpload ||
                        signLanguage
                      ) {
                        setSignLanguage(false);
                        setOpenMembers(false);
                        setOpenImageUpload(false);
                        setOpenDateArea(false);
                        setOpenVideoArea(false);
                      }
                    }}
                    ml="xs"
                    mb={5}
                    size={40}
                  >
                    <RiVideoUploadLine size={25} />
                  </ActionIcon>
                  <ActionIcon
                    color={openDateArea ? "red" : "grey"}
                    disabled={disabledSettingsButtons}
                    variant={openDateArea ? "filled" : "default"}
                    onClick={() => {
                      setOpenDateArea(!openDateArea);
                      if (
                        openMembers ||
                        openImageUpload ||
                        openVideoUpload ||
                        openVideoArea ||
                        signLanguage
                      ) {
                        setSignLanguage(false);
                        setOpenMembers(false);
                        setOpenImageUpload(false);
                        setOpenVideoUpload(false);
                        setOpenVideoArea(false);
                      }
                    }}
                    ml="xs"
                    mb={5}
                    size={40}
                  >
                    <BsCalendarDate size={25} />
                  </ActionIcon>
                  <ActionIcon
                    color={openVideoArea ? "red" : "grey"}
                    disabled={disabledSettingsButtons}
                    variant={openVideoArea ? "filled" : "default"}
                    onClick={() => {
                      setOpenVideoArea(!openVideoArea);
                      if (
                        openMembers ||
                        openImageUpload ||
                        openVideoUpload ||
                        openDateArea ||
                        signLanguage
                      ) {
                        setOpenMembers(false);
                        setOpenDateArea(false);
                        setOpenImageUpload(false);
                        setOpenVideoUpload(false);
                        setSignLanguage(false);
                      }
                    }}
                    ml="xs"
                    mb={5}
                    size={40}
                  >
                    <BsCameraVideo size={25} />
                  </ActionIcon>
                </>
              )}
            </>
          )}
        {(props.event.usersRole === Role.Owner ||
          props.event.usersRole === Role.Admin ||
          props.event.usersRole === Role.Interpreter ||
          props.event.usersRole === Role.SignLanguage) &&
          props?.event?.showSignLanguage &&
          props.event.showPlayer && (
            <ActionIcon
              color={signLanguage ? "red" : "grey"}
              disabled={disabledSettingsButtons}
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
              variant={signLanguage ? "filled" : "default"}
              onClick={() => {
                setSignLanguage(!signLanguage);
                props.setOpenEditArea(false);
              }}
              // onClick={() => navigate(`/event/${id}/sign-language`)}
              ml="xs"
              size={40}
            >
              <div
                style={{ opacity: disabledSettingsButtons ? 0.2 : 1 }}
                className={`${classes.fontSizeIcon}`}
              >
                {signLanguage ? (
                  disabledSettingsButtons ? (
                    <Image
                      src={require("../../../assets/sign_languge_close_black.png")}
                    />
                  ) : (
                    <Image
                      src={require("../../../assets/sign_language_close.png")}
                    />
                  )
                ) : (
                  <Image src={require("../../../assets/sign_language.png")} />
                )}
              </div>
            </ActionIcon>
          )}
        {(props.event.usersRole === Role.Admin ||
          props.event.usersRole === Role.Owner ||
          props.event.usersRole === Role.Customer) &&
          event?.isRichText &&
          event?.showDownload &&
          showDownloadDocx && (
            <ActionIcon
              onClick={() => setDownloadDocx(true)}
              ml={editorFullSize ? 0 : "xs"}
              size={40}
              mb={editorFullSize ? 0 : 5}
              disabled={!showDownloadDocx}
              variant="default"
            >
              <BsFiletypeDocx size={25} />
            </ActionIcon>
          )}
      </>
    );
    // if (props.event.usersRole === Role.Customer) {
    //   return <CustomerButtons />;
    // }
    // if (
    //   props.event.usersRole === Role.Admin ||
    //   props.event.usersRole === Role.Owner
    // ) {
    //   return <AdminButtons />;
    // }
    // if (props.event.usersRole === Role.Interpreter) {
    //   return <InterpreterButtons />;
    // }
  };

  const fullScreenButtons = () => {
    return (
      <Group className={classes.full_screen_controller}>
        {closeMenu ? (
          <ActionIcon
            size={40}
            variant="default"
            onClick={() => setCloseMenu(!closeMenu)}
          >
            <ChevronUp />{" "}
          </ActionIcon>
        ) : (
          <ActionIcon
            size={40}
            className={classes.icon_hover}
            onClick={() => setCloseMenu(!closeMenu)}
          >
            <ChevronDown />
          </ActionIcon>
        )}

        {closeMenu && (
          <>
            {" "}
            <ActionIcon
              ml={editorFullSize ? 0 : "xs"}
              size={40}
              mb={editorFullSize ? 0 : 5}
              color="red"
              variant="filled"
              onClick={fullSize}
            >
              <ArrowsMinimize />
            </ActionIcon>
            {buttons()}
          </>
        )}
      </Group>
    );
  };
  return (
    <Group align="top" className={classes.main_container}>
      <div className={classes.editor_main_area}>
        <div
          className={classes.editor_container}
          style={{
            width:
              variationHorizontal == 2
                ? "75%"
                : variationHorizontal == 3
                ? "90%"
                : "50%",
          }}
        >
          <div
            className={`${classes.editor_text_area}`}
            style={{
              height:
                props?.openEditArea || signLanguage
                  ? "100%"
                  : "calc(100vh - 200px)",
            }}
          >
            <div
              className={classes.left_area}
              style={{ overflow: openDateArea ? "initial" : "hidden" }}
            >
              {signLanguage && props?.event?.showSignLanguage && (
                <SignLanguage
                  setDisabledSettingsButtons={setDisabledSettingsButtons}
                  event={event}
                />
              )}
              {props?.openEditArea ? (
                <>
                  {openMembers && <EditEventMembersPage />}
                  {openImageUpload && <UploadEventImagePage />}
                  {openVideoUpload && <EventUploadVideo />}
                  {openDateArea && (
                    <EditDatePage
                      schedulingId={event?.schedulingId}
                      event={event}
                    />
                  )}
                  {openVideoArea && (
                    <EventEditVideo
                      schedulingId={event?.schedulingId}
                      event={event}
                    />
                  )}
                  {!openImageUpload &&
                    !openVideoUpload &&
                    !openMembers &&
                    !openDateArea &&
                    !openVideoArea &&
                    !signLanguage && (
                      <EditEventPage
                        setOpenEditArea={props.setOpenEditArea}
                        setIsPrivate={setIsPrivate}
                      />
                    )}
                </>
              ) : (
                !signLanguage && (
                  <>
                    <div
                      className={`${!editorFullSize && classes.editor_text} ${
                        changeSizeNoVideo &&
                        !editorFullSize &&
                        classes.full_editor_text
                      }
                    ${
                      !props?.event?.showPlayer &&
                      variation == 3 &&
                      classes.text_three_variation
                    }
                    ${
                      !props?.event?.showPlayer &&
                      variation == 2 &&
                      classes.text_two_variation
                    }
                    ${
                      !props?.event?.showPlayer &&
                      variation == 1 &&
                      classes.text_one_variation
                    } 
                    ${!changeSize && classes.large_editor_text} 
                     ${editorFullSize && classes.editor_full_page}
                    ${changeSize && classes.small_editor_text} ${
                        !props?.event?.showPlayer &&
                        !editorFullSize &&
                        classes.large_editor_text
                      }`}
                      style={{
                        position: editorFullSize ? "absolute" : "relative",
                      }}
                    >
                      {event?.isRichText && (
                        <RichTextEditor
                          id={event.uid}
                          downloadWorld={downloadWorld}
                          setDownloadDocx={setDownloadDocx}
                          setShowDownloadDocx={setShowDownloadDocx}
                          showDownloadDocx={showDownloadDocx}
                          collection={"richtexts"}
                          fontSize={fontSize + "px"}
                          autoScroll={autoScroll}
                          setAutoScroll={setAutoScroll}
                        />
                      )}
                      {!showTranslation &&
                        !event?.isRichText &&
                        !currentTranslation && (
                          <TextAreaEditor
                            readonly={false}
                            event={event}
                            variation={0}
                            autoScroll={autoScroll}
                            fontSize={fontSize}
                            setAutoScroll={setAutoScroll}
                            color={color()}
                          />
                        )}

                      {showTranslation &&
                        !event?.isRichText &&
                        currentTranslation === "preprocessed" && (
                          <TranslationAreaEditor
                            event={event}
                            autoScroll={autoScroll}
                            fontSize={fontSize}
                            language={currentTranslation}
                          />
                        )}

                      {showTranslation &&
                        !event?.isRichText &&
                        currentTranslation === "en" && (
                          <TranslationAreaEditor
                            event={event}
                            autoScroll={autoScroll}
                            fontSize={fontSize}
                            language={currentTranslation}
                          />
                        )}

                      {showTranslation &&
                        !event?.isRichText &&
                        currentTranslation === "de" && (
                          <TranslationAreaEditor
                            event={event}
                            autoScroll={autoScroll}
                            fontSize={fontSize}
                            language={currentTranslation}
                          />
                        )}

                      {showTranslation &&
                        !event?.isRichText &&
                        currentTranslation === "it" && (
                          <TranslationAreaEditor
                            event={event}
                            autoScroll={autoScroll}
                            fontSize={fontSize}
                            language={currentTranslation}
                          />
                        )}

                      {showTranslation &&
                        !event?.isRichText &&
                        currentTranslation === "fr" && (
                          <TranslationAreaEditor
                            event={event}
                            autoScroll={autoScroll}
                            fontSize={fontSize}
                            language={currentTranslation}
                          />
                        )}
                      {showTranslation &&
                        !event?.isRichText &&
                        currentTranslation === "uk" && (
                          <TranslationAreaEditor
                            event={event}
                            autoScroll={autoScroll}
                            fontSize={fontSize}
                            language={currentTranslation}
                          />
                        )}
                      {showTranslation &&
                        !event?.isRichText &&
                        currentTranslation === "rm" && (
                          <TranslationAreaEditor
                            event={event}
                            autoScroll={autoScroll}
                            fontSize={fontSize}
                            language={currentTranslation}
                          />
                        )}
                    </div>
                    <div
                      className={`${
                        props?.event?.showPlayer
                          ? classes.video_area
                          : classes.hidden_video_area
                      } 
                ${changeSize && classes.big_video_area}`}
                    >
                      {props?.event?.showPlayer &&
                        props?.event?.sources[0].src != "" && (
                          <Player
                            onPlayerInit={setPlayer}
                            playerOptions={playerOptions}
                            onPlayerDispose={setPlayer}
                            showPlayer={props?.event?.showPlayer}
                            sources={props?.event?.sources}
                            onAudioTracksInit={handleAudioTracks}
                          />
                        )}
                    </div>
                  </>
                )
              )}
            </div>
            <div className={classes.editor_button_area}>
              {props.event.usersRole !== Role.SignLanguage &&
                (editorFullSize ? fullScreenButtons() : buttons())}
            </div>
          </div>
        </div>
      </div>
    </Group>
  );
};
const useStyles = createStyles((theme) => ({
  main_container: {
    height: "100%",
  },
  variation: {
    marginBlockStart: "0em",
    marginBlockEnd: "0em",
    position: "absolute",
    left: 0,
    top: 0,
  },
  text_one_variation: {
    height: "40% !important",
  },
  text_two_variation: {
    height: "60% !important",
  },
  text_three_variation: {
    height: "100% !important",
  },
  editor_main_area: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    height: "100%",
    paddingTop: 20,
    alignItems: "center",
  },
  icon_hover: {
    svg: {
      color: "white",
    },
    ":hover": {
      svg: {
        color: "black !important",
      },
    },
  },
  fontSizeIcon: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    height: 28,
    width: 28,
  },
  a: {
    marginBlockStart: "0em",
    marginBlockEnd: "0em",
  },
  plus: {
    position: "absolute",
    top: "-2px",
    right: 0,
  },
  full_screen_controller: {
    position: "absolute",
    right: 0,
    top: 0,
    gap: 5,
    padding: "0px 4px 5px 4px",
    zIndex: 9999,
    backgroundColor: "rgba(255, 0, 0, 0.5)",
    display: "flex",
    flexDirection: "column",
  },
  editor_full_page: {
    height: "100vh !important",
    width: "100vw",
    left: 0,
    top: 0,
    zIndex: 100,
  },
  editor_container: {
    height: "100%",
  },
  editor_text: {
    height: "60%",
    width: "100%",
    display: "flex",
  },
  full_editor_text: {
    height: "95% !important",
  },
  small_editor_text: {
    height: "40% !important",
  },
  large_editor_text: {
    height: "55%",
    display: "flex",
  },
  editor_text_area: {
    display: "flex",
    width: "100%",
  },
  video_area: {
    height: "35%",
    marginTop: 20,
    width: "100%",
    display: "flex",
  },
  hidden_video_area: {
    height: "0% !important",
  },
  left_area: {
    width: "100%",
  },
  big_video_area: {
    height: "55%",
  },
  line_area: {
    display: "flex",
    width: "100%",
  },
  line: {
    // backgroundColor: theme.colors.gray[2],
    // width: "100%",
    // height: 2,
  },
  editor_button_area: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
}));
export default InterpreterArea;
