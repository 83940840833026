import React, { useState } from "react";
import { Button } from "@mantine/core";

interface Props {
  text?: string;
  loading?: any;
  color?: any;
  onClick: any;
  disabled?: boolean;
  variant?: any;
  children?: any;
  mt?: any;
  style?: any;
}

const Index = (props: Props) => {
  return (
    <Button
      style={{ ...props.style }}
      mt={props.mt}
      loading={props.loading}
      color={props.color ? props.color : "default"}
      variant={props.variant}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children ? props.children : props.text}
    </Button>
  );
};
export default Index;
