import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { AppDispatch, RootState } from "../store/store";
import { useNavigate } from "react-router-dom";
import restClient from "../api/restClient";
import {
  setOrganization,
  setOrganizations,
  setLoading,
} from "../store/reducers/organization.reducer";
import { IOrganization } from "../interfaces/organization.interface";
import { IProfile } from "../interfaces/profile.interface";
import { auth } from "../api/firebase";
import getAuthHeader from "../utils/getAuthHeader";

export declare interface ICreateOrganizationDto {
  name: string;
}

export declare interface ICreatedOrganizationResponse {}

export const useOrganization = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();

  const profile: IProfile = useAppSelector(
    (state: RootState) => state.user.profile
  );

  const organization: IOrganization = useAppSelector(
    (state: RootState) => state.organization.organization
  );
  const organizations: IOrganization[] = useAppSelector(
    (state: RootState) => state.organization.organizations
  );

  const createOrganization = async (data: ICreateOrganizationDto) => {
    dispatch(setLoading(true));
    try {
      const token = await auth.currentUser?.getIdToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await restClient.post("/organizations", data, config);
      const organization: ICreatedOrganizationResponse = response.data;
      if (organization) {
        getAllOrganizations();
        dispatch(setLoading(false));
      }
    } catch (e) {
      dispatch(setLoading(false));
      return e;
    }
  };
  const removeOrganization = async (id: string) => {
    dispatch(setLoading(true));
    try {
      const config = await getAuthHeader();
      const response = await restClient.delete("/organizations/" + id, config);
      const organization: any = response.data;
      if (organization) {
        getAllOrganizations();
        dispatch(setLoading(false));
      }
    } catch (e) {
      dispatch(setLoading(false));
      return e;
    }
  };
  const updateOrganization = async (id: string, name: any) => {
    dispatch(setLoading(true));
    try {
      const options = {
        name: name,
      };
      const config = await getAuthHeader();
      const response = await restClient.patch(
        "/organizations/" + id,
        options,
        config
      );
      const organization: any = response.data;
      if (organization) {
        getAllOrganizations();
        dispatch(setLoading(false));
        navigate(-1);
      }
    } catch (e) {
      dispatch(setLoading(false));
      return e;
    }
  };

  const getOrganization = async (id: string) => {
    try {
      const config = await getAuthHeader();
      const response = await restClient.get("/organizations/" + id, config);
      const organization: ICreatedOrganizationResponse = response.data;
      if (organization) {
        dispatch(setOrganization(organization));
        return organization;
      }
    } catch (e) {
      console.log("error", e);
      return e;
    }
  };

  const getAllOrganizations = async () => {
    try {
      const config = await getAuthHeader();
      const response = await restClient.get("/organizations", config);
      const organizations: any = response.data;
      if (organizations) {
        dispatch(setOrganizations(organizations));
        return organizations;
      }
    } catch (e) {
      return e;
    }
  };

  const handlers = {
    createOrganization: createOrganization,
    getOrganization: getOrganization,
    getAllOrganizations: getAllOrganizations,
    removeOrganization: removeOrganization,
    updateOrganization: updateOrganization,
  };

  return {
    handlers,
    organization,
    organizations,
  };
};
