import { Menu, createStyles } from "@mantine/core";
import { useTranslation } from "react-i18next";

export interface IProps {
  variation: number;
  getTextAreaColor: (e: string) => void;
}

const colorSettings = (props: IProps) => {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <>
      <>
        <Menu.Item
          aria-label={t("color-light")}
          lang="en"
          onClick={() => props.getTextAreaColor("light")}
          className={classes.color_button + "  " + classes.light}
        >
          Abc
        </Menu.Item>
        <Menu.Item
          aria-label={t("color-black-background")}
          
          lang="en"
          onClick={() => props.getTextAreaColor("dark")}
          className={classes.color_button + "  " + classes.black_white}
        >
          Abc
        </Menu.Item>
        <Menu.Item
          aria-label={t("color-green-background")}
          
          lang="en"
          onClick={() => props.getTextAreaColor("green")}
          className={classes.color_button + "  " + classes.green_white}
        >
          Abc
        </Menu.Item>
        <Menu.Item
          aria-label={t("color-green-background-black-text")}
          
          lang="en"
          onClick={() => props.getTextAreaColor("green_black")}
          className={classes.color_button + "  " + classes.green_black}
        >
          Abc
        </Menu.Item>
        <Menu.Item
          aria-label={t("color-blue-background")}
          
          lang="en"
          onClick={() => props.getTextAreaColor("blue")}
          className={classes.color_button + "  " + classes.blue}
        >
          Abc
        </Menu.Item>
        <Menu.Item
          aria-label={t("color-blue-background-black-text")}
          
          lang="en"
          onClick={() => props.getTextAreaColor("blue_black")}
          className={classes.color_button + "  " + classes.blue_black}
        >
          Abc
        </Menu.Item>
      </>
    </>
  );
};
export default colorSettings;
const useStyles = createStyles((theme) => ({
  color_button: {
    width: "100%",
    height: 50,
    fontSize: 20,
    fontWeight: 700,
    borderRadius: 0,
    marginTop: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  black_white: { backgroundColor: theme.black, color: theme.white },
  black_background_text_white: {
    display: "inline-flex;",
    backgroundColor: "black",
    color: "white",
  },
  green_white: {
    border: `1px solid ${theme.black}`,
    backgroundColor: "rgb(0, 255, 0)",
    color: theme.white,
  },

  transparent: {
    backgroundColor: "rgb(0, 0, 0, .5)",
    color: theme.white,
  },
  green_black: {
    border: `1px solid ${theme.black}`,
    backgroundColor: "rgb(0, 255, 0)",
    color: theme.black,
  },
  blue: {
    backgroundColor: "rgb(0, 51, 204)",
    color: theme.white,
  },
  blue_black: {
    backgroundColor: "rgb(0, 51, 204)",
    color: theme.black,
  },
  light: {
    backgroundColor: theme.white,
    color: theme.black,
    border: `1px solid ${theme.black}`,
  },
}));
