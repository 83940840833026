import React, { useState, useEffect } from "react";
import { createStyles, ActionIcon, Group } from "@mantine/core";
import Header from "../header/publicHeader";
import Footer from "../footer/publicFooter";
import IPLayout from "../../../interfaces/public-layout.interface";
import { X } from "tabler-icons-react";
import Div100vh from "react-div-100vh";
import { logEventAnalytics } from "../../../api/firebase";
import SkipLinks from "skip-links";
import { isLoadedInIframe } from "../../../utils/is-loaded-in-Iframe";

const PublicLayout: React.FunctionComponent<IPLayout> = (props: any) => {
  const { classes } = useStyles();
  const [opened, setOpened] = useState(false);
  const [openWebsiteMenu, setOpenWebsiteMenu] = useState(false);
  const [openWebsite, setOpenWebsite] = useState(undefined);
  const [mobile, setMobile] = useState(false);

  const openWebsiteArea = (e?: any) => {
    try {
      logEventAnalytics("useInformationbar", {
        opened: true,
        url: e,
      });
      console.log(props.currentEvent.shortUid);
      logEventAnalytics(
        props?.currentEvent?.shortUid + " useInformationbar " + e
      );
    } catch (error) {}
    if (props?.currentEvent?.urls.length > 1) {
      setOpenWebsiteMenu(!openWebsiteMenu);
      setOpenWebsite(e);
      setOpened(true);
    } else {
      setOpenWebsite(e);
      setOpened(!opened);
    }
  };

  useEffect(() => {
    if (window?.innerWidth < 769) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  const headerLogo = props?.currentEvent?.customLogos?.find(
    (x: any) => x?.header == true
  );

  const links = [
    { title: "Skip to title", to: "eventTitle" },
    { title: "Skip to video", to: "eventVideo" },
    { title: "Skip to text area", to: "textarea1" },
  ];

  const filteredLinks = links.filter((link) => {
    return props?.currentEvent?.showPlayer || link.to !== "eventVideo";
  });
  return (
    <>
      <div className={classes.skip_button}>
        <SkipLinks links={filteredLinks} />
      </div>
      <Div100vh className={classes.layout_main}>
        <div
          className={
            opened
              ? mobile
                ? classes.layout_main_big
                : classes.layout_main_content
              : classes.layout_main_big
          }
        >
          {!isLoadedInIframe() && (
            <header>
              <Header
                logo={headerLogo}
                otherWebsites={props?.currentEvent?.otherWebsites}
                openWebSite={openWebsiteArea}
                website={opened}
                mobile={mobile}
                eventName={props?.currentEvent?.name}
                urls={props?.currentEvent?.urls}
              />
            </header>
          )}
          <main>
            <div className={`${classes.body_area}`}>{props.children}</div>
          </main>
          {!isLoadedInIframe() && (
            <footer style={{ height: "10vh" }}>
              <Footer currentEvent={props?.currentEvent} />
            </footer>
          )}
        </div>
        {opened && (
          <div className={classes.drawer_area}>
            <ActionIcon
              className={classes.close_button}
              onClick={() => setOpened(false)}
            >
              <X color="white" />
            </ActionIcon>
            <iframe
              src={openWebsite}
              style={{ width: "100%", height: "100vh" }}
              title="description"
            ></iframe>
          </div>
        )}
      </Div100vh>
    </>
  );
};

const useStyles = createStyles((theme) => ({
  layout_main: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100vh",
    overflowY: "hidden",
    overflow: "hidden",
    backgroundColor: theme.colors.gray[1],
  },
  layout_main_content: {
    width: "80%",
    [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
      width: "70%",
    },
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      width: "60%",
    },
  },
  layout_main_big: {
    width: "100%",
    height: "100vh",
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      height: "calc(100vh - 60px)",
    },
  },
  drawer_area: {
    width: "25%",
    zIndex: 99,
    [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
      width: "35%",
    },
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      width: "40%",
    },
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      position: "absolute",
      width: "100%",
    },
  },
  close_button: {
    position: "absolute",
    top: 10,
    right: 10,
    width: "50px",
    zIndex: 99,
    height: "50px",
    transition: "all .5s",
    borderRadius: "50%",
    background: theme.colors.red[7],
    ":hover": {
      background: theme.colors.red[9],
      transform: "scale(0.90)",
      transition: "all .5s",
      boxShadow: theme.shadows.lg,
    },
  },
  body_area: {
    height: "83vh",
    overflow: "hidden",
    backgroundColor: theme.colors.gray[1],
  },
  skip_button: {
    ul: {
      marginBlockStart: "0em",
      marginBlockEnd: "0em",
      paddingInlineStart: "1em",
    },
  },
}));
export default PublicLayout;
