import React, { useState, useEffect } from "react";
import {
  Group,
  Textarea,
  Input,
  Text,
  InputWrapper,
  Select,
  Button,
  Modal,
  Notification,
} from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import axios from "axios";
import { useUser } from "../../../hooks/user.hook";
import { Check, X } from "tabler-icons-react";
import { useEvents } from "../../../hooks/event.hook";
import { useTranslation, WithTranslation } from "react-i18next";

const SupportMailBox = ({ openModal, setOpenModal, closeMail = true }: any) => {
  const { handlers: userHandlers, firebaseUserInfo, profile } = useUser();
  const { t, i18n } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState("");
  const [supportSelectError, setSupportSelectError] = useState(false);
  const { width } = useViewportSize();

  const form = useForm({
    initialValues: {
      email: closeMail ? "" : profile?.email,
      supportType: "",
      comment: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : t("invalid_email")),
    },
  });
  const { currentEvent }: any = useEvents();

  const sendMail = (val: any) => {
    setLoading(true);
    try {
      axios
        .post("https://api-external-service.dev.liveaccess.net/send-mail", {
          email: val.email,
          comment: val.comment,
          eventName: currentEvent ? currentEvent?.name : "No Events Name ",
          eventLink: currentEvent
            ? window.location.origin + "/event/" + currentEvent?.shortUid
            : "No Events Link",
        })
        .then((response: any) => {
          setLoading(false);
          setAlert("success");
          form.setValues({
            email: closeMail ? "" : profile?.email,
            comment: "",
            supportType: "",
          });
        })
        .catch((error) => {
          setAlert("error");
          form.setValues({
            email: closeMail ? "" : profile?.email,
            comment: "",
            supportType: "",
          });
          setLoading(false);
        });
      setSupportSelectError(false);
    } catch (error) {
      setAlert("error");
      form.setValues({
        email: closeMail ? "" : profile?.email,
        comment: "",
        supportType: "",
      });
      setLoading(false);
      setSupportSelectError(false);
    }
  };

  useEffect(() => {
    if (alert.length > 0) {
      setTimeout(() => {
        setOpenModal(false);
        setAlert("");
      }, 3000);
    }
  }, [alert]);
  return (
    <>
      <Modal
        styles={{
          modal: {
            width: width < 769 ? "100%" : "50vw",
          },
        }}
        opened={openModal}
        onClose={() => {
          setOpenModal(false);
          setAlert("");
        }}
        title={t("supportBox.title")}
      >
        {alert.length > 0 ? (
          alert == "success" ? (
            <Group
              style={{ justifyContent: width < 1400 ? "center" : undefined }}
            >
              <Check color="green" size={35} />
              <Text>{t("supportBox.success")}</Text>
            </Group>
          ) : (
            <Group
              style={{ justifyContent: width < 1400 ? "center" : undefined }}
            >
              <X color="red" size={35} />
              <Text>{t("supportBox.error")}</Text>
            </Group>
          )
        ) : (
          <form onSubmit={form.onSubmit((values) => sendMail(values))}>
            {closeMail && (
              <InputWrapper
                error={form.errors.email}
                label={t("email")}
                required={closeMail}
              >
                <Input
                  placeholder={t("enter_email")}
                  required={closeMail}
                  {...form.getInputProps("email")}
                />
              </InputWrapper>
            )}

            <Textarea
              placeholder={t("supportBox.enter_description")}
              label={t("supportBox.description")}
              required
              maxRows={5}
              {...form.getInputProps("comment")}
              minRows={5}
            />
            <Group
              mt={10}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "60%" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text
                    style={{ textDecoration: "underline", color: "red" }}
                    component="a"
                    href="mailto:AS-support@swisstxt.ch"
                  >
                    AS-support@swisstxt.ch
                  </Text>
                  <Text
                    style={{ textDecoration: "underline", color: "red" }}
                    component="a"
                    href="tel:+41443151584"
                  >
                    +41 44 315 15 84
                  </Text>
                </div>
                <Text color="dimmed">MO-FR, 08:00 - 17:30</Text>
                <Text color="dimmed">
                  {t("supportBox.german_mail")}{" "}
                  <Text
                    style={{ textDecoration: "underline", color: "red" }}
                    component="a"
                    href="mailto:AS-live@swisstxt.ch"
                  >
                    AS-live@swisstxt.ch
                  </Text>
                </Text>
                <Text color="dimmed">
                  {t("supportBox.french_mail")}{" "}
                  <Text
                    style={{ textDecoration: "underline", color: "red" }}
                    component="a"
                    href="mailto:as-suisseromande@swisstxt.ch"
                  >
                    as-suisseromande@swisstxt.ch
                  </Text>
                </Text>
              </div>
              <div></div>
              <Button color="red" loading={loading} type="submit" mt="xl">
                {t("send")}
              </Button>
            </Group>
          </form>
        )}
        {/* Modal content */}
      </Modal>
    </>
  );
};
export default SupportMailBox;
