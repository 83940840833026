import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Title, Group, Text, Switch, createStyles } from "@mantine/core";
import { useTranslation } from "react-i18next";

interface IProps {
  setValue: Dispatch<SetStateAction<any>>;
  checked: boolean;
  label: string;
  description?: string;
  marginTop?: any;
}

const Index: React.FunctionComponent<IProps> = (props: IProps) => {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Group
      position="apart"
      className={classes.item}
      noWrap
      spacing="xl"
      style={{ marginTop: props.marginTop ? 0 : 10 }}
      mt="xs"
    >
      <div>
        <Text>{props.label}</Text>
        {props.description && (
          <Text size="xs" color="dimmed">
            {props.description}
          </Text>
        )}
      </div>
      <Switch
        color="red"
        onLabel="ON"
        offLabel="OFF"
        checked={props.checked}
        onChange={(event: any) => props.setValue(event.currentTarget.checked)}
        className={classes.switch}
        size="lg"
      />
    </Group>
  );
};
const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.white[1],
  },
  card_icon: {
    backgroundColor: theme.colors.gray[0],
    width: "100%",
  },
  switch: {
    "& *": {
      cursor: "pointer",
    },
  },
  item: {
    "& + &": {
      paddingTop: theme.spacing.sm,
      marginTop: theme.spacing.sm,
      borderTop: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },
  },
  detail_title: {
    width: 100,
    fontWeight: "bold",
  },
}));
export default Index;
