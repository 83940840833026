import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IUploadState {
  loading: boolean;
  users: any;
  switchVideo: boolean;
}
const initialstate: IUploadState = {
  loading: false,
  users: [],
  switchVideo: false,
};

export const counterSlice = createSlice({
  name: "signLanguage",
  initialState: initialstate,
  reducers: {
    setLoading: (state: any, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setUsers: (state: any, action: PayloadAction<any>) => {
      state.users = action.payload;
    },
    setSignLanguageVideoState: (state: any, action: PayloadAction<any>) => {
      state.signLanguageState = action.payload;
    },
    setSignPublicVideo: (state: any, action: PayloadAction<any>) => {
      state.signPublicVideo = action.payload;
    },
    setSwitchVideo: (state: any, action: PayloadAction<boolean>) => {
      state.switchVideo = action.payload;
    },
  },
});

export const {
  setLoading,
  setUsers,
  setSignLanguageVideoState,
  setSwitchVideo,
  setSignPublicVideo
} = counterSlice.actions;

export default counterSlice.reducer;
