import { useAppDispatch, useAppSelector } from "../store/hooks";
import { AppDispatch, RootState } from "../store/store";
import { IEvent } from "../interfaces/event.interface";
import getAuthHeader from "../utils/getAuthHeader";
import { useUser } from "./user.hook";
import { IOrganization } from "../interfaces/organization.interface";
import { useOrganization } from "./organization.hook";
import restClient from "../api/restClient";
import { setRole, setLoading } from "../store/reducers/roles.reducer";
export const useRoles = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const { handlers: orgaHandlers, organization } = useOrganization();

  const { profile } = useUser();

  const OrganizationRole: any = useAppSelector(
    (state: RootState) => state.roles.role
  );
  const loading: any = useAppSelector(
    (state: RootState) => state.roles.loading
  );

  const getRoles = async (id: string) => {
    dispatch(setLoading(true));
    const config = await getAuthHeader();
    try {
      const response = await restClient.get("/organizations/" + id, config);
      const event: any = response.data;
      let userRole = "Guest";
      if (event.members && event.members.length > 0) {
        const member = event.members.find(
          (member: any) => member.profile.id === profile.id
        );
        if (member) {
          userRole = member.roles[0];
        }
        dispatch(setLoading(false));
      }
      event.usersRole = userRole;
      dispatch(setRole(userRole));
    } catch (e) {
      dispatch(setLoading(false));
      return e;
    }
  };

  const handlers = {
    getRoles: getRoles,
  };
  return {
    handlers,
    OrganizationRole,
    loading,
  };
};
