import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ISchedulerState {
  loading: boolean;
  scheduler: {
    name: string;
    targetEvent: string;
    sourceUrl: string;
    sourceLanguage: string;
    timespan: {
      start: string;
      end: string;
    };
    state: string;
    stateArgs: [{}];
  };
}
const initialstate: ISchedulerState = {
  loading: false,
  scheduler: {
    name: "",
    targetEvent: "",
    sourceUrl: "",
    sourceLanguage: "",
    timespan: {
      start: "",
      end: "",
    },
    state: "",
    stateArgs: [{}],
  },
};

export const counterSlice = createSlice({
  name: "scheduler",
  initialState: initialstate,
  reducers: {
    setLoading: (state: any, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSchedulerData: (state: any, action: PayloadAction<any>) => {
      state.scheduler = action.payload;
    },
  },
});

export const { setLoading, setSchedulerData } = counterSlice.actions;

export default counterSlice.reducer;
