import React, { useState, useRef, useEffect, useTransition } from "react";
import { Dropzone, DropzoneStatus, MIME_TYPES } from "@mantine/dropzone";
import {
  Group,
  Text,
  Card,
  useMantineTheme,
  MantineTheme,
  Image,
  Button,
  createStyles,
  Progress,
  Input,
  Checkbox,
  Select,
} from "@mantine/core";
import { UploadImages } from "../../../hooks/uploadImages.hook";
import { Loader } from "@mantine/core";
import { useEvents } from "../../../hooks/event.hook";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../store/store";
import { useAppSelector } from "../../../store/hooks";

const UploadEventImagePage = (props: any) => {
  const { t, i18n } = useTranslation("common");
  const [searchValue, onSearchChange] = useState("");

  const { handlers, loading } = UploadImages();
  const { currentEvent } = useEvents();
  const { classes } = useStyles();
  const loadingEvent = useAppSelector(
    (state: RootState) => state.event.loading
  );
  const theme = useMantineTheme();
  const [uploadImages, setUploadImages]: any = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData]: any = useState([]);
  const [selectHeaderIndex, setSelectHeaderIndex]: any = useState(null);

  const [onUploadProgress, setOnUploadProgress]: any = useState(0);

  useEffect(() => {
    if (currentEvent?.customLogos?.length > 0) {
      setData(currentEvent?.customLogos);
      const header = currentEvent?.customLogos?.filter(
        (x: any) => x.header == true
      );
      if (header.length == 1) {
        setSelectHeaderIndex(
          String(
            currentEvent?.customLogos?.findIndex(
              (item: any) => item.header === true
            ) + 1
          )
        );
      }
    }
  }, [currentEvent]);

  const dropImages = (files: any) => {
    if (
      files?.length > 5 ||
      currentEvent?.customLogos?.length + files?.length + uploadImages?.length >
        5
    ) {
      setErrorMessage(t("event.image_upload_max_length_5_error"));
      return;
    } else {
      setErrorMessage("");
      setUploadImages([...uploadImages, ...files]);
    }
  };

  const dropzoneChildren = (theme: MantineTheme) => (
    <Group style={{ minHeight: 220, pointerEvents: "none" }}>
      <div>
        <Text size="xl" inline align="center">
          {t("event.drag_image")}
        </Text>
        <Text size="sm" color="dimmed" inline mt={7} align="center">
          {t("event.drag_image_subtitle")}
        </Text>
      </div>
    </Group>
  );
  const imageChildren = () => (
    <>
      {uploadImages.map((x: any, i: number) => (
        <div key={i} style={{ height: 200, width: 200, position: "relative" }}>
          {/* <button onClick={()=> setImages([])} style={{position:"absolute",right:0,top:0,width:100}}>X</button> */}
          <Image width={200} height={200} src={URL.createObjectURL(x)} />
        </div>
      ))}
    </>
  );

  const upload = async () => {
    await handlers.uploadImages(uploadImages, setOnUploadProgress);
  };

  useEffect(() => {
    if (onUploadProgress == 100) {
      setUploadImages([]);
      setOnUploadProgress(0);
    }
  }, [loading, onUploadProgress]);

  const deleteImage = async (x: string) => {
    await handlers.deleteImage(x);
  };

  const clearImages = () => {
    setUploadImages([]);
    setErrorMessage("");
  };

  const onChangeUrl = (e: any, index: number) => {
    const list: any = [...data];
    const find: any = data.find((x: any, i: number) => i === index);
    list[index] = { ...find, url: e };
    setData(list);
  };
  const imageUpdate = async () => {
    await handlers.updateUrl(data);
  };

  const selectHeader = async (index: any) => {
    setSelectHeaderIndex(index);
    const newData = data.map((item: any, i: number) => {
      return { ...item, header: i === index };
    });
    await handlers.updateUrl(newData);
  };

  const deleteSelectHeader = async () => {
    setSelectHeaderIndex(null);
    const newData = data.map((item: any, i: number) => {
      return { ...item, header: false };
    });
    await handlers.updateUrl(newData);
  };
  return (
    <Card
      p="lg"
      style={{
        position: "relative",
        overflow: "hidden",
        marginBottom: 10,
      }}
    >
      <Text size="xl" color="red" align="center">
        {errorMessage}
      </Text>
      <Dropzone
        onDrop={(files) => dropImages(files)}
        maxSize={5242880}
        onReject={(rejectedFiles) => {
          if (rejectedFiles[0].errors[0].code == "file-too-large") {
            setErrorMessage(t("image_upload_max_size_5_mb"));
          }
        }}
        classNames={{
          root: errorMessage != "" ? classes.dropzone_error : undefined,
        }}
        disabled={
          currentEvent?.customLogos?.length == 5 || uploadImages?.length == 5
        }
        accept={[MIME_TYPES.png, MIME_TYPES.jpeg]}
      >
        {(status) => (
          <Group direction="row" position="center" spacing="xl">
            {uploadImages?.length > 0
              ? imageChildren()
              : dropzoneChildren(theme)}
          </Group>
        )}
      </Dropzone>

      <Progress mt="xl" value={onUploadProgress} />

      <Group mt="md" direction="row" position="right" spacing="xl">
        <Button
          disabled={uploadImages?.length == 0}
          onClick={clearImages}
          color="red"
        >
          {t("clear")}
        </Button>
        <Button
          disabled={uploadImages?.length == 0 || loading}
          onClick={upload}
          color="green"
        >
          {t("save")}
        </Button>
      </Group>
      {data.length > 0 && (
        <Group
          align="flex-end"
          style={{ justifyContent: "space-between", gap: 0 }}
          direction="row"
        >
          <Select
            style={{ width: selectHeaderIndex != null ? "78%" : "100%" }}
            label="Select Header Logo"
            value={selectHeaderIndex}
            onChange={(e: any) => selectHeader(e - 1)}
            placeholder="Select Header Logo"
            data={Object.keys(data).map((index) => String(parseInt(index) + 1))}
          />
          {selectHeaderIndex != null && (
            <Button
              onClick={deleteSelectHeader}
              style={{ width: "20%" }}
              color="red"
            >
              x
            </Button>
          )}
        </Group>
      )}
      <div className={classes.images_main}>
        <div className={classes.images_container}>
          {loadingEvent ? (
            <div
              style={{
                height: 200,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loader variant="dots" color="red" />
            </div>
          ) : (
            data?.map((x: any, i: number) => (
              <div className={classes.image_contain} key={i}>
                <div className={classes.image_area}>
                  <Image width={200} height={200} fit="contain" src={x.img} />
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Group direction="column" style={{ width: "80%", gap: 0 }}>
                      <Text>Website Url</Text>
                      <Input
                        title="Website Url"
                        defaultValue={x.url}
                        onChange={(e: any) => onChangeUrl(e.target.value, i)}
                        style={{ width: "100%" }}
                        placeholder=" Url"
                      />
                    </Group>
                  </div>
                  <Group mt={5} position="right">
                    <Button
                      fullWidth
                      onClick={() => deleteImage(x)}
                      color="red"
                    >
                      {t("delete")}
                    </Button>
                    <Button
                      fullWidth
                      disabled={
                        x?.url == undefined ||
                        x.url.length == 0 ||
                        x?.url == currentEvent.customLogos[i]?.url
                      }
                      onClick={() => imageUpdate()}
                      color="green"
                    >
                      {t("save")}
                    </Button>
                  </Group>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </Card>
  );
};
const useStyles = createStyles((theme) => ({
  images_main: {
    width: "100%",
    display: "flex",
  },
  images_container: {
    width: "100%",
    marginTop: 10,
  },
  image_contain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  image_area: {
    padding: 5,
    margin: "5px 0px",
    borderRadius: 5,
    display: "flex",
    flexDirection: "row",
    border: "1px solid grey",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    "@media (max-width: 768px)": {
      width: "100%",
      flexDirection: "column",
    },
  },
  dropzone_error: {
    border: "2px dashed red",
  },
}));
export default UploadEventImagePage;
