import { useAppDispatch, useAppSelector } from "../store/hooks";
import { AppDispatch, RootState } from "../store/store";

import { IFirebaseUserInfo } from "./firebaseAuth.hook";
import {
  resetProfile,
  setProfile,
  setAllProfiles,
} from "../store/reducers/user.reducer";
import restClient from "../api/restClient";
import { IProfile } from "../interfaces/profile.interface";
import getAuthHeader from "../utils/getAuthHeader";
export declare interface ICreateProfileDto {
  email: string;
  firebaseUid: string;
  firstName: string;
  lastName: string;
}

export declare interface ICreatedProfileResponse {
  createdAt: string;
  email: string;
  firstName: string;
  fullName: string;
  id: string;
  lastName: string;
  memberOfOrganization: any[];
  uid: string;
  firebaseUid: string;
}

export const useUser = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const firebaseUserInfo: IFirebaseUserInfo = useAppSelector(
    (state: RootState) => state.user.firebaseUserInfo
  );
  const profile: IProfile = useAppSelector(
    (state: RootState) => state.user.profile
  );
  const profiles: IProfile = useAppSelector(
    (state: RootState) => state.user.profiles
  );

  const createProfile = async (data: ICreateProfileDto) => {
    try {
      const response = await restClient.post("/profiles", data);
      const profile: ICreatedProfileResponse = response.data;
      if (profile) {
        dispatch(setProfile(profile));
      }
    } catch (e) {
      return e;
    }
  };

  const getProfile = async (uid: any) => {
    try {
      const config = await getAuthHeader();
      const response = await restClient.get("/profiles/fb/" + uid, config);
      const profile: ICreatedProfileResponse[] = response.data;
      if (profile && profile.length > 0) {
        dispatch(setProfile(profile[0]));
        return profile;
      } else {
        return null;
      }
    } catch (e) {
      return e;
    }
  };

  const getAllProfile = async () => {
    try {
      const config = await getAuthHeader();
      const response = await restClient.get("/profiles", config);
      const profiles: any = response.data;
      if (profiles) {
        dispatch(setAllProfiles(profiles));
        return profiles;
      } else {
        return null;
      }
    } catch (e) {
      return e;
    }
  };

  const updateProfile = async (id: any, data?: any) => {
    try {
      const config = await getAuthHeader();
      const response = await restClient.patch("/profiles/" + id, data, config);
      const profile: ICreatedProfileResponse[] = response.data;
      if (profile && profile.length > 0) {
        dispatch(setProfile(profile[0]));
        return profile;
      } else {
        return null;
      }
    } catch (e) {
      return e;
    }
  };

  const handlers = {
    createProfile: createProfile,
    getProfile: getProfile,
    updateProfile: updateProfile,
    getAllProfile: getAllProfile,
  };

  return {
    handlers,
    firebaseUserInfo,
    profile,
    profiles,
  };
};
