import { ActionIcon } from "@mantine/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { BsFiletypeTxt } from "react-icons/bs";
import { useConnector } from "../../../../hooks/sharedbconnector.hook";
import {
  IReplayClient,
  ReplayClient,
  ReplayLine,
} from "../../../../hooks/replayconnector.hook";

const DownloadTxtButton = ({ t, whiteColor, event, language }: any) => {
  const [lines, setLines]: any[] = useState([]);
  const [endTime, setEndTime] = useState("");
  const [loading, setLoading] = useState(false);

  const setLinesFunction = useCallback(
    (line: any) => {
      setLines((lines: any) => [...lines, ...line]);
      setLoading(false)
    },
    [lines]
  );
  const _delay = 0; //ms

  useEffect(() => {
    if (lines.length > 0) {
      const startTime = lines[0].meta.writeStart;
      const startDate:any = new Date(startTime);
      const endTime = lines[lines.length - 1].meta.writeEnd;
      const endDate:any = new Date(endTime);
      const timeDifference = Math.abs(endDate - startDate);
      const seconds = timeDifference / 1000;
      const minutes = seconds / 60;
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = Math.floor(minutes % 60);
      const remainingSeconds = Math.floor(seconds % 60);
      const formattedDifference = `${String(hours).padStart(2, "0")}:${String(remainingMinutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
      setEndTime(formattedDifference);
    }
  }, [lines]);

  useEffect(() => {
    setLoading(true)
    const startTime = Date.now() - _delay;
    let _replayClient: IReplayClient;

    _replayClient = new ReplayClient();
    _replayClient.connect(
      "wss://prod-replay-controller.madarray.solutions",
      "token-123",
      event.id,
      startTime,
      language,
      (line: any) => setLinesFunction(line),
      (ev: any) => console.log("zxcev", ev),
      () => console.log("zxconnection lost....")
    );

    return () => {
      _replayClient.disconnect();
    };
  }, []);

  useEffect(() => {
    return () => {
      close();
    };
  }, []);

  const downloadTxt = () => {
    const textContent = lines
      .map((item: any, index: number) => {
        if (index === 0) {
          // İlk eleman ise başlangıç saati ekleyin
          return `00:00:00 ${lines[0].value}`;
        } else if (index === lines.length - 1) {
          // Son eleman ise bitiş saati ekleyin
          return `${lines[lines.length - 1].value} ${endTime}`;
        } else {
          return item.value;
        }
      })
      .join(" "); // '/n' == moves to the next line, ' ' == converts to text
    const blob = new Blob([textContent], { type: "text/plain" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `export-${language?.toUpperCase()}.txt`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };
  return (
    <ActionIcon
      aria-label={t("download-pdf")}
      lang="en"
      disabled={loading}
      onClick={downloadTxt}
      size="md"
      loading={loading}
    >
      <BsFiletypeTxt
        style={{ width: "100%" }}
        size="xl"
        color={whiteColor ? "white" : "black"}
      />
    </ActionIcon>
  );
};

export default DownloadTxtButton;
