import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import {
  InputWrapper,
  SimpleGrid,
  Group,
  createStyles,
  TextInput,
  Input,
} from "@mantine/core";
import { DatePicker, TimeInput } from "@mantine/dates";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "../button/button";

interface IProps {
  setValue: Dispatch<SetStateAction<any>>;
  value: any;
  nextStep: () => void;
  prevStep: () => void;
}
const EventDetails: React.FunctionComponent<IProps> = (props: IProps) => {
  const { t, i18n } = useTranslation("common");
  const [next, setNext] = useState(false);
  const [startDate, setStartDate] = useState(props.value.startDate);
  const [endDate, setEndDate] = useState(props.value.endDate);
  const [startTime, setStartTime] = useState(props.value.startTime);
  const [endTime, setEndTime] = useState(props.value.endTime);
  const { classes } = useStyles();

  const getStartDateValue = (e: any) => {
    setStartDate(e);
  };
  const getEndDateValue = (e: any) => {
    setEndDate(e);
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const nameMin = watch("name")?.length < 3;
  const nameMax = watch("name")?.length > 40;

  const eventNameError = () => {
    var error: any = null;
    if (next) {
      if (nameMin) error = t("events.min_char");
      else if (nameMax) error = t("events.max_char");
      else error = false;
    }
    return error;
  };

  const getEventDetail = () => {
    setNext(true);
    if (watch("name")) {
      props.setValue({
        ...props.value,
        name: watch("name"),
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
      });
      props.nextStep();
    }
  };

  return (
    <SimpleGrid cols={1} className={classes.container}>
      <TextInput
        id="name"
        label={t("events.event_name")}
        error={eventNameError()}
        defaultValue={props.value.name}
        {...register("name")}
        placeholder={t("events.event_name")}
        required
      />
      <Group position="apart">
        <div></div>
        <Button
          text={t("next_step")}
          loading={false}
          color="red"
          onClick={() => getEventDetail()}
        />
      </Group>
    </SimpleGrid>
  );
};
const useStyles = createStyles((theme) => ({
  container: {
    width: "80%",
    "@media (max-width: 769px)": {
      width: "100% !important",
    },
  },
  input_area: {
    "@media (max-width: 769px)": {
      height: "auto",
      width: "100% !important",
      maxWidth: "100%",
    },
  },
  group: {
    "@media (max-width: 769px)": {
      display: "flex",
      flexDirection: "column",
      width: "100% !important",
    },
  },
}));
export default EventDetails;
