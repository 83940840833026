import { useState, useEffect } from "react";
import {
  addDoc,
  collection,
  doc,
  limit,
  getDoc,
  getDocs,
  onSnapshot,
  setDoc,
  query,
  orderBy,
  startAfter,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../api/firebase";
import { useUser } from "./user.hook";
import MessageClass from "../classes/Message.class";

declare global {
  interface Window {
    client: any;
  }
}

export interface IMessage {
  message: string;
  sendTime: string;
  sender: string;
  senderId: string;
  uid: string;
  direction: "incoming" | "outgoing";
  position: "single" | "first" | "normal" | "last";
}
export default function useChat() {
  const [messages, setMessages] = useState<IMessage[] | []>([]);
  const [lastMessage, setLastMessage]: any = useState(null);
  const [limitData, setLimitData] = useState(70);

  const [chatId, setChatId] = useState<any>(null);

  const { profile } = useUser();

  useEffect(() => {
    if (!chatId) {
      return;
    }
    setChatId(chatId);
    const chatSubcolMessages = collection(db, `chats/${chatId}/messages`);
    let q = query(chatSubcolMessages, orderBy("sendTime", "desc"), limit(70));
    // if (lastMessage) {
    //   q = query(
    //     chatSubcolMessages,
    //     orderBy("sendTime", "asc"),
    //     startAfter(lastMessage),
    //     limit(limitData)
    //   );
    // }
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const messages: IMessage[] = [];
      snapshot.forEach((doc) => {
        const message: any = doc.data();
        const m: IMessage = message;
        m.senderId === profile.id
          ? (m.direction = "outgoing")
          : (m.direction = "incoming");
        messages.push(message);
      });
      // setMessages(messages);
      setMessages(messages.reverse());
      // setLastMessage(snapshot.docs[snapshot.docs.length - 1]);
    });
    return () => {
      unsubscribe();
      setMessages([]);
      setChatId(null);
    };
  }, [chatId,limitData]);

  const initChat = (id: string) => {
    if (!id) return;
    setChatId(id);
  };

  const sendMessage = async (messageInputValue: string) => {
    if (!messageInputValue) return;
    const uid = profile.id;
    const newMessage = new MessageClass({
      userId: uid,
      message: messageInputValue,
      profile,
    });
    let m = Object.assign({}, newMessage);

    const col1Ref = collection(db, "chats");

    const docRef = doc(col1Ref, chatId);
    const col2Ref = collection(docRef, "messages");

    addDoc(col2Ref, m);
  };

  return {
    initChat,
    sendMessage,
    messages,
  };
}
