import React, { useState, useEffect } from "react";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
} from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import "./style.scss";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Linkify from "react-linkify";
import sanitizeHtml from "sanitize-html";

type Props = {
  id: string;
  initChat?: any;
  sendMessage?: any;
  messages?: any;
};

const Chat = (props: Props) => {
  const { t } = useTranslation("common");
  const { id, initChat, sendMessage, messages } = props;
  const [msgInputValue, setMsgInputValue] = useState("");

  useEffect(() => {
    if (id) {
      initChat(id);
    }
  }, [id]);

  const handleSend = (message: any) => {
    sendMessage(message);
    setMsgInputValue("");
  };

  return (
    <div style={{ position: "relative", height: "100%" }}>
      <MainContainer>
        <ChatContainer>
          <MessageList>
            {messages.map((m: any) => {
              const sanitizedMessage = sanitizeHtml(m.message, {
                allowedTags: [],
                allowedAttributes: {},
              });
              return (
                <Message key={m.uid} model={m}>
                  <Message.Header sender={m.sender} />
                  <Message.CustomContent>
                    <Linkify
                      componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                      ) => (
                        <a target="blank" href={decoratedHref} key={key}>
                          {decoratedText}
                        </a>
                      )}
                    >
                      {sanitizedMessage}
                    </Linkify>
                  </Message.CustomContent>
                  <Message.Footer
                    sentTime={moment(m.sendTime)?.format("DD.MM.YYYY HH:mm")}
                  />
                </Message>
              );
            })}
          </MessageList>
          <MessageInput
            onSend={handleSend}
            placeholder={t("layout.type_message")}
            attachButton={false}
            style={{
              flexGrow: 1,
              borderTop: 0,
              flexShrink: "initial",
            }}
          />
        </ChatContainer>
      </MainContainer>
    </div>
  );
};

export default Chat;
