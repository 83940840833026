import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEvents } from "../../hooks/event.hook";
import InterpreterArea from "../../components/low-level/interpreter-area/publicInterpreter";
import Layout from "../../components/low-level/layout/publicLayout";

const Index = () => {
  const { id } = useParams();

  const { handlers: eventsHandlers, currentEvent } = useEvents();

  useEffect(() => {
    if (id) {
      eventsHandlers.getEventData(id);
    }
  }, [id]);
 
  return ( 
    <Layout currentEvent={currentEvent}>
      <InterpreterArea event={currentEvent} />
    </Layout>
  );
};
export default Index;
