import React, { useEffect, useState } from "react";
import { useScheduler } from "../../../hooks/scheduler.hooks";
import {
  ActionIcon,
  Badge,
  Button,
  Card,
  Checkbox,
  Grid,
  Group,
  Loader,
  Text,
  TextInput,
  Title,
  createStyles,
} from "@mantine/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsFillPlayFill, BsFillStopFill } from "react-icons/bs";

const EditDatePage = ({ schedulingId, event }: any) => {
  const { handlers, scheduler, loading } = useScheduler();
  const { classes } = useStyles();

  const [url, setUrl] = useState("");
  const [startDate, setStartDate]: any = useState<Date | null>(null);
  const [endDate, setEndDate]: any = useState<Date | null>(null);
  const [checked, setChecked] = useState(false);
  const [schedulingIdFilled, setSchedulingIdFilled] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  useEffect(() => {
    if (schedulingId != null && schedulingId != "") {
      setChecked(true);
      setSchedulingIdFilled(true);
      handlers.getCapture(schedulingId);
    } else {
      setSchedulingIdFilled(false);
    }

    return () => {
      handlers.resetInformation();
    };
  }, [schedulingId]);

  useEffect(() => {
    if (schedulingIdFilled && scheduler?.sourceUrl?.length > 0) {
      setStartDate(new Date(scheduler?.timespan?.start));
      setEndDate(new Date(scheduler?.timespan?.end));
      setUrl(scheduler?.sourceUrl);
    } else {
      setStartDate(null);
      setEndDate(null);
      setUrl("");
    }
  }, [scheduler, schedulingIdFilled]);

  const handleStartDateChange = (date: any) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: any) => {
    setEndDate(date);
  };

  const save = async () => {
    if (schedulingIdFilled) {
      const data = {
        id: schedulingId,
        name: event.id,
        targetEvent: event.id,
        targetLanguages: ["de", "en", "fr"],
        sourceUrl: url,
        sourceLanguage: "de",
        timespan: {
          start: startDate?.toISOString(),
          end: endDate?.toISOString(),
        },
      };
      if (checked) {
        await handlers.updateCapture(data);
      } else {
        await handlers.deleteCapture(event.id, event.shortUid, schedulingId);
      }
    } else {
      const data = {
        name: event.id,
        targetEvent: event.id,
        targetLanguages: ["de", "en", "fr"],
        sourceUrl: url,
        sourceLanguage: "de",
        timespan: {
          start: startDate?.toISOString(),
          end: endDate?.toISOString(),
        },
      };
      await handlers.createCapture(event.id, event.shortUid, data);
    }
  };

  const addEndDateMinutes = (date: Date, minutes: number) => {
    var endDate = new Date(date);
    endDate.setMinutes(endDate.getMinutes() + minutes);

    setEndDate(endDate);
  };

  useEffect(() => {
    if (checked) {
      if (url?.length > 5 && startDate != "" && endDate != "") {
        setSaveButtonDisabled(false);
      } else {
        setSaveButtonDisabled(true);
      }
    } else if (schedulingId) {
      setSaveButtonDisabled(false);
    } else {
      setSaveButtonDisabled(true);
    }
  }, [url, startDate, endDate, checked, scheduler]);

  const filterPassedTime = (time?: any) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate?.toISOString() < selectedDate?.toISOString();
  };

  return (
    <Card
      p="lg"
      style={{
        position: "relative",
        overflow: "initial",
        marginBottom: 10,
      }}
    >
      {loading && (
        <div className={classes.date_loading}>
          <Loader color="red" variant="dots" />
        </div>
      )}
      <Grid>
        <Grid.Col xs={12} lg={schedulingIdFilled ? 10 : 12}>
          <Group>
            <Checkbox
              checked={checked}
              disabled={scheduler?.state == "active"}
              onChange={(event) => setChecked(event.currentTarget.checked)}
              color="red"
            />
            <Title>Stream</Title>
          </Group>
          <Text>Url</Text>
          <TextInput
            onChange={(e) => setUrl(e.target.value)}
            value={url}
            disabled={!checked}
            placeholder="Url"
          />
          <div className={classes.date_area}>
            <Text>Start Date</Text>
            <DatePicker
              selectsStart
              selected={startDate}
              onChange={handleStartDateChange}
              disabled={!checked}
              minDate={new Date()}
              maxDate={endDate}
              className={`${classes.date_wrapper} ${
                !checked && classes.date_wrapper_disabled
              }`}
              showTimeSelect
              dateFormat="dd/MM/yyyy HH:mm"
              timeIntervals={15}
              filterTime={filterPassedTime}
              timeCaption="Time"
              placeholderText="Choose date and time"
            />
          </div>

          <div className={classes.date_area}>
            <Text mb={0}>End Date</Text>
            <DatePicker
              selectsEnd
              selected={endDate}
              disabled={!checked}
              startDate={startDate}
              minDate={startDate}
              onChange={handleEndDateChange}
              className={`${classes.date_wrapper} ${
                !checked && classes.date_wrapper_disabled
              }`}
              showTimeSelect
              filterTime={(time: any) =>
                filterPassedTime(time) && time > startDate
              }
              dateFormat="dd/MM/yyyy HH:mm"
              timeIntervals={15}
              timeCaption="Time"
              placeholderText="Choose date and time"
            />
          </div>
          <div className={classes.save_button}>
            <Button
              mt={20}
              style={{ height: 50 }}
              onClick={save}
              disabled={saveButtonDisabled}
              color={schedulingIdFilled ? (checked ? "blue" : "red") : "green"}
            >
              {schedulingIdFilled ? (checked ? "Update" : "Delete") : "Save"}
            </Button>
          </div>
        </Grid.Col>
        <Grid.Col xs={12} lg={schedulingIdFilled ? 2 : 0}>
          {schedulingIdFilled && (
            <>
              <Badge
                color={
                  scheduler?.state == "created"
                    ? "blue"
                    : scheduler?.state == "scheduled"
                    ? "orange"
                    : scheduler?.state == "running"
                    ? "red"
                    : scheduler?.state == "completed"
                    ? "green"
                    : scheduler?.state == "failed"
                    ? "gray"
                    : "blue"
                }
                variant="filled"
                fullWidth
                style={{ height: 50 }}
              >
                {scheduler?.state}
              </Badge>
              {/* <Button style={{ width: "100%", height: 80 }} color="red">
                {scheduler?.state}
              </Button> */}
              <div className={classes.start_stop_area}>
                {/* <ActionIcon
                  mr={10}
                  className={classes.start_button}
                  color="green"
                  variant="filled"
                >
                  <BsFillPlayFill size={25} />
                </ActionIcon> */}
                <ActionIcon
                  ml={10}
                  onClick={() => handlers.stopCapture(schedulingId)}
                  className={classes.start_button}
                  color="red"
                  variant="filled"
                >
                  <BsFillStopFill size={25} />
                </ActionIcon>
              </div>
              <Button
                onClick={() => addEndDateMinutes(endDate, 15)}
                mt={10}
                fullWidth
                color="red"
              >
                + 15 Minutes
              </Button>
              <Button
                onClick={() => addEndDateMinutes(endDate, 30)}
                mt={10}
                fullWidth
                color="red"
              >
                + 30 Minutes
              </Button>
              <Group></Group>
            </>
          )}
        </Grid.Col>
      </Grid>
    </Card>
  );
};
const useStyles = createStyles((theme) => ({
  date_wrapper: {
    height: 40,
    width: "calc(100% - 20px)",
    border: "1px solid rgb(206, 212, 218)",
    borderRadius: 1,
    padding: "0px 10px",
    backgroundColor: "white",
  },
  save_button: {
    width: "100%",
    justifyContent: "flex-end",
    display: "flex",
  },
  start_stop_area: {
    height: 60,
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
  },
  start_button: {
    borderRadius: "50%",
    width: 50,
    height: 50,
  },
  date_wrapper_disabled: {
    backgroundColor: "#f1f3f5",
  },
  date_loading: {
    height: "100%",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.5)",
    width: "100%",
    position: "absolute",
    zIndex: 9999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  date_area: {
    marginTop: 20,
    width: "100%",
  },
}));
export default EditDatePage;
