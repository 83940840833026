import {
  Container,
  Stepper,
  Group,
  createStyles,
  Center,
  SimpleGrid,
  StepperProps,
  MultiSelect,
  Select,
  Card,
  Title,
  Text,
} from "@mantine/core";
import { useListState } from "@mantine/hooks";
import React, { useState, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/low-level/layout";
import { useTranslation } from "react-i18next";
import { ICreateOrganization } from "../../interfaces/create-organization.interface";
import { IProfile } from "../../interfaces/profile.interface";
import { useMembers } from "../../hooks/members.hook";
import { useUser } from "../../hooks/user.hook";
import Button from "../../components/low-level/button/button";
import { IoPersonOutline } from "react-icons/io5";

// Bu Array sonradan serverdan gelecek

const NewMemberPage = () => {
  const { id } = useParams();
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const [selectEmail, setSelectEmail]: any = useState([]);
  const [_nextStep, setNextStep]: any = useState(false);
  const [dataForSelect, setDataForSelect]: any = useState([]);
  const [dataForMap, setDataForMap]: any = useState([]);
  const { handlers: userHandlers, profiles, profile }: any = useUser();
  const { handlers: membersHandlers, members, loading }: any = useMembers();

  const roles = [
    { value: "Admin", label: t("admin") },
    { value: "Interpreter", label: t("interpreter") },
    { value: "User", label: t("user") },
    { value: "Owner", label: t("owner") },
    { value: "Customer", label: t("customer") },
    { value: "SignLanguage", label: t("sign_language") },
  ];

  useEffect(() => {
    userHandlers.getAllProfile();
  }, []);

  useEffect(() => {
    membersHandlers.getAllMembers(id);
  }, []);

  useEffect(() => {
    var arr: any = [];
    if (members?.length > 0 && profiles?.length > 0) {
      arr = profiles.filter((profile: any) => {
        return (
          members.filter((member: any) => {
            return member.email === profile.email;
          }).length == 0
        );
      });
    } else {
      profiles?.map((x: any) => arr.push(x));
    }

    // Serverden gelen datayı map edip select için gerekli olan arrayi oluşturuyoruz (value ve label)
    if (arr && arr.length > 0) {
      const dataWithValue = arr?.map((item: any) => {
        return {
          value: item.email,
          label: item.email,
        };
      });
      setDataForSelect(dataWithValue);
    }
  }, [profiles, members]);

  useEffect(() => {
    // dataForMap in icinde yeni bir array olustur

    // ! DataForMap arrin icindeki objectleri sonra api ye gonderebilirzin !

    if (dataForMap?.length < selectEmail?.length) {
      const arr1 = selectEmail.map((item: any) => {
        return profiles.find((item2: any) => item2.email === item);
      });

      const arr2 = arr1.filter((object1: any) => {
        return !dataForMap.some((object2: any) => {
          return object1?.id === object2?.id;
        });
      });

      const arr3 = [...dataForMap, ...arr2];
      setDataForMap(arr3);
    } else {
      const arr2 = dataForMap?.filter((object1: any) => {
        return !selectEmail.some((object2: any) => {
          return object1.email === object2;
        });
      });

      const profileToRemove: IProfile = arr2[0];

      const arr3 = dataForMap?.filter((obj: IProfile) => {
        return obj?.id !== profileToRemove?.id;
      });

      setDataForMap(arr3);
    }
  }, [selectEmail]);

  const { classes } = useStyles();

  const [organization, setOrganization] = useState<ICreateOrganization>(
    {} as ICreateOrganization
  );

  const nameForm: any = useForm();

  const handleSubmitName = async (data: { eventName: string }) => {
    setOrganization({ ...organization, name: data.eventName });
    var required = dataForMap.filter((x: any) => !x.role);
    if (dataForMap.length >= 1 && required.length == 0) {
      handleSubmitMember();
    }
  };

  const handleSubmitMember = async () => {
    const data: any = dataForMap.map((x: any) => {
      return { profile: x.id, organization: id, roles: x.role };
    });

    if (data) {
      const promises = await data.map((x: any) => {
        return membersHandlers.createMember(x);
      });
      await Promise.all(promises).then((values) => {});
      navigate(`/organizations/${id}/members`);
    }
  };

  const getSelectEmail = (e: any) => {
    if (e.length == 0) {
      setDataForMap([]);
    }
    setSelectEmail(e);
    setNextStep(false);
  };

  const getSelectRole = (e: any, profile: IProfile) => {
    // bütün array clone al
    let clonedArray = JSON.parse(JSON.stringify(dataForMap));

    // objecti kur ve degistir
    var item = { ...profile, role: e };

    // degistirilen objecti arrayin icindeki bul ve degistir
    var foundIndex = clonedArray.findIndex((x: any) => x?.id == profile?.id);
    clonedArray[foundIndex] = item;

    // yeni arrayi set et
    setDataForMap(clonedArray);
  };

  return (
    <Layout
      navigate={`/organizations/${id}/members`}
      scrollScreen={dataForMap.length > 4 && true}
      eventName="New Member"
    >
      <Container pt="md" size="md">
        <Card>
          <Center style={{ width: "100%" }}>
            <SimpleGrid cols={1} style={{ width: "100%" }}>
              <MultiSelect
                data={dataForSelect.filter(
                  (x: any) => x.value != profile.email
                )}
                label={t("members.select_member")}
                searchable
                error={_nextStep && selectEmail.length < 1 && t("select_email")}
                clearable
                clearButtonLabel={t("members.clear")}
                value={selectEmail}
                onChange={(e: any) => getSelectEmail(e)}
                placeholder={t("members.select_member")}
              />
              {dataForMap?.map((x: IProfile | any, i: number) => (
                <Card
                  key={i}
                  style={{ width: "100%", padding: 10 }}
                  className={classes.card}
                  shadow="sm"
                  p="lg"
                >
                  <Group grow className={classes.group}>
                    <Group className={classes.title}>
                      <IoPersonOutline />
                      <Text className={classes.email}>{x?.email}</Text>
                    </Group>
                    <Select
                      className={classes.select}
                      placeholder={t("members.select_role")}
                      value={x?.role}
                      error={_nextStep && !x?.role && t("role_select")}
                      onChange={(e: any) => getSelectRole(e, x)}
                      data={roles}
                    />
                  </Group>
                </Card>
              ))}
              <Group position="apart">
                <div></div>

                <Button
                  text={t("members.create_member")}
                  loading={loading}
                  color={"red"}
                  onClick={nameForm.handleSubmit((data: any) =>
                    handleSubmitName(data)
                  )}
                />
              </Group>
            </SimpleGrid>
          </Center>
        </Card>
      </Container>
    </Layout>
  );
};

const useStyles = createStyles((theme, params, getRef) => ({
  root: {
    padding: theme.spacing.md,
  },

  separator: {
    height: 2,
    borderTop: `2px dashed ${
      theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[4]
    }`,
    borderRadius: theme.radius.xl,
    backgroundColor: "transparent",
  },

  separatorActive: {
    borderWidth: 0,
    backgroundImage: theme.fn.linearGradient(
      45,
      theme.colors.red[6],
      theme.colors.red[6]
    ),
  },

  stepIcon: {
    ref: getRef("stepIcon"),
    borderColor: "transparent",
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[3],
    borderRadius: 10,
    borderWidth: 0,
    color: "white",
  },

  step: {
    transition: "transform 150ms ease",
  },

  stepProgress: {
    transform: "scale(1.05)",

    [`& .${getRef("stepIcon")}`]: {},
  },

  stepCompleted: {
    [`& .${getRef("stepIcon")}`]: {
      borderWidth: 0,
      backgroundColor: "transparent",
      backgroundImage: theme.fn.linearGradient(
        45,
        theme.colors.red[7],
        theme.colors.red[7]
      ),
      boxShadow: theme.shadows.xs,
    },
  },
  card: {
    backgroundColor: theme.white[1],
    width: "100%",
  },
  card_icon: {
    backgroundColor: theme.colors.gray[0],
    width: "100%",
  },
  group: {
    "@media (max-width: 768px)": {
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
    },
  },
  title: {
    "@media (max-width: 768px)": {
      flexDirection: "row",
      maxWidth: "100%",
      width: "100%",
    },
  },
  email: {
    "@media (max-width: 768px)": {
      whiteSpace: "nowrap",
      width: "70%",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  select: {
    "@media (max-width: 768px)": {
      width: "100% !important",
      maxWidth: "100% !important",
    },
  },
}));
export default NewMemberPage;
