import React, { useState, SetStateAction, Dispatch } from "react";
import { createStyles, ActionIcon, Group, Menu, Modal } from "@mantine/core";
import {
  Settings,
  ArrowsDiagonal,
  ChevronUp,
  ChevronDown,
  X,
} from "tabler-icons-react";
import { useTranslation } from "react-i18next";

export interface IProps {
  mobile: boolean;
  changeScale: any;
  changeScaleNoVideo: any;
  textFullSize: boolean;
  variation: number;
  variationNoVideo: number;
  whiteColor: any;
  settingsOpenFullScreen: boolean;
  setSettingsOpen: Dispatch<SetStateAction<any>>;
  setSettingsOpenFullScreen: Dispatch<SetStateAction<any>>;
  settingsItems: any;
  settingsMenuClose: any;
}
const InterpreterFullScreenSettings = (props: IProps) => {
  const [closeMenu, setCloseMenu] = useState(false);
  const { classes } = useStyles();
  const {
    mobile,
    textFullSize,
    changeScaleNoVideo,
    settingsOpenFullScreen,
    setSettingsOpen,
    setSettingsOpenFullScreen,
    settingsItems,
    whiteColor,
    variation,
    variationNoVideo,
    settingsMenuClose,
  } = props;
  const { t, i18n } = useTranslation();

  return (
    <Group
      style={{ flexDirection: "column" }}
      className={classes.editor_full_settings_area}
    >
      <ActionIcon
        aria-label={t("close-menu")}
        size="lg"
        onClick={() => setCloseMenu(!closeMenu)}
        className={classes.editor_button}
      >
        {closeMenu ? (
          <ChevronUp
            style={{ width: "100%" }}
            aria-label={t("menu-up")}
            className={`${!props.mobile && classes.icon_hover}`}
            size="xl"
            color="white"
          />
        ) : (
          <ChevronDown
            style={{ width: "100%" }}
            aria-label={t("menu-down")}
            className={`${!props.mobile && classes.icon_hover}`}
            size="xl"
            color="white"
          />
        )}
      </ActionIcon>

      {closeMenu && (
        <>
          {variationNoVideo == 3 && (
            <ActionIcon
              aria-label={t("change-size")}
              onClick={changeScaleNoVideo}
              size="lg"
            >
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className={`${!props.mobile && classes.icon_hover}`}
              >
                <h3
                  style={{
                    color: whiteColor ? "white" : "black",
                    position: "absolute",
                    left: 0,
                    bottom: 0,
                  }}
                  className={classes.variation}
                >
                  {variationNoVideo}
                </h3>
                <ArrowsDiagonal
                  style={{ width: "100%" }}
                  size="md"
                  color={textFullSize ? "white" : "black"}
                />
              </div>
            </ActionIcon>
          )}
          {!textFullSize && (variation == 2 || variation == 3) && (
            <ActionIcon
            aria-label={t("publicPage.change-size")}
            lang="en"
              size="lg"
              onClick={props.changeScale}
            >
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className={`${!props.mobile && classes.icon_hover}`}
              >
                <h3
                  style={{
                    color: whiteColor ? "white" : "black",
                  }}
                  className={classes.variation}
                >
                  {variation}
                </h3>
                <ArrowsDiagonal
                  style={{ width: "100%" }}
                  size="xl"
                  color="white"
                />
              </div>
            </ActionIcon>
          )}
          <ActionIcon
            size="lg"
            aria-label={t("settings")}
            
            onClick={() => {
              setSettingsOpen(false);
              setSettingsOpenFullScreen(!settingsOpenFullScreen);
            }}
            className={classes.editor_button}
          >
            <Settings
              style={{ width: "100%" }}
              className={`${!mobile && classes.icon_hover}`}
              size="xl"
              color={whiteColor ? "white" : "black"}
            />
          </ActionIcon>
          {settingsOpenFullScreen && <>{settingsItems()}</>}
        </>
      )}
    </Group>
  );
};
const useStyles = createStyles((theme) => ({
  editor_full_settings_area: {
    position: "absolute",
    display: "flex",
    zIndex: 99,
    right: 0,
    top: 0,
    padding: "0px 4px 5px 4px",
    backgroundColor: "rgba(255,0,0,.5)",
  },
  variation: {
    marginBlockStart: "0em",
    marginBlockEnd: "0em",
    position: "absolute",
    left: 0,
    top: 0,
  },
  editor_button: {},
  icon_hover: {
    ":hover": {
      filter: "grayscale(100%) brightness(0%) hue-rotate(360deg)",
    },
  },
}));
export default InterpreterFullScreenSettings;
