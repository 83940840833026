import React, { useRef, useEffect, useState } from "react";
import videojs from "video.js";
import "videojs-plus";
import "videojs-youtube";
import IPlayer from "../../../interfaces/player.interface";
import "videojs-plus/dist/videojs-plus.css";
import ReactPlayer from "react-player";
import Hls from "hls.js";
import "./styles.css";

const defaultPlayerOptions = {
  autoplay: false,
  muted: false,
  // aspectRatio: "16:9",
  mobileView: false,
};
const Index: React.FunctionComponent<IPlayer> = (props: any) => {
  const containerRef: any = useRef(null);

  const containerRefPlayer: any = useRef(null);

  const [audioTracks, setAudioTracks] = useState([]);
  const [hlsPlayer, setHls] = useState(null);
  const [currentSource, setCurrentSource] = useState(null);
  const [isInit, setIsInit] = useState(false);
  const [click, setClick] = useState(false);

  // useEffect(() => {
  //   var videoObj = videojs("player");
  //   if (!videoObj.paused()) {
  //     console.log("zxcVideo is playing");
  //   } else {
  //     console.log("zxcVideo is paused");
  //   }
  // }, [click]);

  useEffect(() => {
    if (isInit) return;
    if (containerRef?.current && props?.sources[0]?.type === "video/facebook") {
      setIsInit(true);
    }
    if (containerRef?.current && props?.sources[0]?.type != "video/facebook") {
      const videoEl = containerRef?.current?.querySelector("video");

      const player: any = videojs(videoEl, {
        ...defaultPlayerOptions,
        ...props?.playerOptions,
      });
      // player.userActive(true);
      const playerEl: any = player.el();
      const flag: any = player.getChild("PlayToggleLayer").el();
      for (const child of containerRef?.current?.children) {
        if (child !== playerEl) {
          playerEl.insertBefore(child, flag);
        }
      }
      props?.onPlayerInit(player);
      // player.unload({ loading: true });
      player.src(props?.sources);
      // // for debug purpose
      // window.player = player;
      setIsInit(true);
      // return () => {
      //   props.onPlayerDispose && props.onPlayerDispose(null);
      //   player.dispose();
      // };
      if (
        containerRefPlayer?.current &&
        props?.sources[0]?.type !== "video/youtube" &&
        props?.sources[0]?.type !== "video/facebook"
      ) {
        const hls: any = new Hls({ enableWorker: false });
        hls.loadSource(
          props?.sources[0].src
          // "https://stream-cartesius-tv.com/app_vod/smil:TbBXMtr.smil/playlist.m3u8"
          // "https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8"
        );
        hls.attachMedia(containerRefPlayer?.current);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          containerRefPlayer?.current?.play();
        });
        hls.on(Hls.Events.AUDIO_TRACKS_UPDATED, (eventName: any, data: any) => {
          if (data.audioTracks.length > 0) {
            setAudioTracks(data.audioTracks);
            props?.onAudioTracksInit &&
              props?.onAudioTracksInit(data.audioTracks);
          }
        });
        setHls(hls);
        setIsInit(true);
        props?.onPlayerInit && props?.onPlayerInit(hls);

        return () => {
          props?.onPlayerDispose && props?.onPlayerDispose(null);
        };
      }
    }
  }, [props, containerRefPlayer]);

  return (
    <>
      {props?.sources[0].type === "video/youtube" && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: props.pageName == "publicEvent" ? "100%" : "100%",
              height: "100%",
            }}
            className={
              props.alwaysController ? "player always-controller" : "player"
            }
            // onClick={() => setClick(!click)}
            ref={containerRef}
            id="player"
          >
            <video
              id="eventVideo"
              tabIndex={2}
              style={{
                borderRadius: "0.428rem",
                width: "100%",
                height: "100%",
                paddingTop: "0",
              }}
            />{" "}
            {props?.children}
          </div>
        </div>
      )}
      {props?.sources[0]?.type === "video/facebook" && (
        <ReactPlayer
          id="eventVideo"
          width="100%"
          tabIndex={2}
          height="100%"
          url={props?.sources[0]?.url}
          playing={true}
          muted={true}
        />
      )}
      {props?.sources[0]?.type !== "video/youtube" &&
        props?.sources[0]?.type !== "video/facebook" && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
              }}
              ref={containerRef}
            >
              <video
                id="eventVideo"
              tabIndex={2}
                style={{ width: "100%", height: "100%" }}
                ref={containerRefPlayer}
                controls
                muted
              />
              {props?.children}
            </div>
          </div>
        )}
    </>
  );
};
export default Index;
