import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import {
  Checkbox,
  CheckboxGroup,
  SimpleGrid,
  createStyles,
  Group,
} from "@mantine/core";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "../button/button";

interface IProps {
  setValue: Dispatch<SetStateAction<any>>;
  value: any;
  nextStep: () => void;
  prevStep: () => void;
}

const EventLanguages: React.FunctionComponent<IProps> = (props: IProps) => {
  const { t } = useTranslation("common");
  const { classes } = useStyles();

  const languages = [
    { value: "en", label: t("en") },
    { value: "de", label: t("de") },
    { value: "it", label: t("it") },
    { value: "fr", label: t("fr") },
    { value: "uk", label: t("uk") },
    { value: "rm", label: t("rm") },
  ];

  return (
    <SimpleGrid cols={1} className={classes.container}>
      <CheckboxGroup
        // defaultValue={}
        color="red"
        defaultValue={props.value.translations}
        onChange={(e) =>
          props.setValue({
            ...props.value,
            translations: e,
          })
        }
        orientation="vertical"
        label={t("events.select_languages")}
        description={t("events.select_languages_desc")}
      >
        {languages.map((x: any, i: number) => (
          <Checkbox key={i} value={x.value} label={x.label} />
        ))}
      </CheckboxGroup>
      <Group position="apart">
        <Button
          text={t("back")}
          loading={false}
          variant="default"
          onClick={props.prevStep}
        />

        <Button
          text={t("next_step")}
          loading={false}
          color="red"
          onClick={props.nextStep}
        />
      </Group>
    </SimpleGrid>
  );
};
const useStyles = createStyles((theme) => ({
  container: {
    width: "80%",
    "@media (max-width: 769px)": {
      width: "100% !important",
    },
  },
}));
export default EventLanguages;
