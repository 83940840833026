import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IRaiseHandStateData {
  createdAt: string | any;
  docId: string;
  raised: boolean;
  raisedBy: string;
  raisedByProfileId: string;
  docDisplayName: string;
  updatedAt: string | any;
}

interface IRaiseHandState {
  currentRaiseHandState: IRaiseHandStateData | null;
}
const initialstate: IRaiseHandState = {
  currentRaiseHandState: null,
};

export const counterSlice = createSlice({
  name: "raiseHand",
  initialState: initialstate,
  reducers: {
    setCurrentRaiseHandState: (state: { currentRaiseHandState: any }, action: { payload: any }) => {
      state.currentRaiseHandState = action.payload;
    },
  },
});

export const { setCurrentRaiseHandState } = counterSlice.actions;

export default counterSlice.reducer;
