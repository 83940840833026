import { useState, useEffect, useCallback } from "react";

import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import getAuthHeader from "../utils/getAuthHeader";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  setDoc,
  query,
  orderBy,
  updateDoc,
  deleteDoc,
  serverTimestamp
} from "firebase/firestore";
import { db } from "../api/firebase";
import { useUser } from "./user.hook";

import moment from "moment";
import { IRaiseHandStateData, setCurrentRaiseHandState } from '../store/reducers/raise-hand.reducer';
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store/store";
import { useDispatch } from 'react-redux';

declare global {
  interface Window {
    client: any;
  }
}

export default function useRaiseHand(id: string | null, docDisplayName: string | null) {

  const dispatch = useDispatch();

  const currentRaiseHandState: any = useAppSelector((state: RootState) => state.raiseHand.currentRaiseHandState);
 
  const [initLoaded, setInitLoaded] = useState<boolean>(false);

  const { profile } = useUser();

  useEffect(() => {
    if (!id ) {
      return;
    }
    let _docDisplayName = docDisplayName;
    if (!_docDisplayName) {
      _docDisplayName = 'source';
    }
    initRaiseHands(id, _docDisplayName);

  }, [id, docDisplayName]);

  useEffect(() => {
    if (!id || !initLoaded) {
      return;
    }

    const docRef = doc(db, "raise-hands", id);

    const unsub = onSnapshot(docRef, (doc: any) => {
      // IN REDUCER SCHREIBEN!!!
      const data = doc.data();
      dispatch(setCurrentRaiseHandState(data));
    });
    return () => {
      unsub && unsub();
      setInitLoaded(false);
      dispatch(setCurrentRaiseHandState(null));
    };
  }, [id, initLoaded]);

  const fetchInitialRaiseHandStatus = async (id: string, docDisplayName: string): Promise<void> => {

    const docRef = doc(db, "raise-hands", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {

      const data = docSnap.data();

      dispatch(setCurrentRaiseHandState(data));
      setInitLoaded(true);
    } else {
      // doc.data() will be undefined in this case
      // Add a new document in collection "cities

      const data: IRaiseHandStateData = {
        createdAt: serverTimestamp(),
        docId: id,
        raised: false,
        raisedBy: "",
        raisedByProfileId: "",
        docDisplayName: docDisplayName,
        updatedAt: serverTimestamp(),
      }

      await setDoc(docRef, data);
      const docSnap = await getDoc(docRef);
      const docData = docSnap.data();

      dispatch(setCurrentRaiseHandState(docData));
      setInitLoaded(true);

    }
  };

  const initRaiseHands = useCallback((id: string, docDisplayName: string) => {
    if (!id) return;

    if (id) {
      fetchInitialRaiseHandStatus(id, docDisplayName);

      dispatch(setCurrentRaiseHandState(null));
      setInitLoaded(false);

    }
  }, []);

  const raiseHand = async (id: string | null): Promise<void> => {
    if (!id) return;
    const raiseHandRef = doc(db, "raise-hands", id);
    const docRef = doc(db, "raise-hands", id);
    const docSnap = await getDoc(docRef);
    const data: any = docSnap.data();

    await updateDoc(raiseHandRef, {
      raised: data.raised ? false : true,
      updatedAt: serverTimestamp(),
      raisedBy: profile?.fullName,
      raisedByProfileId: profile?.id,
    });
  };

  return {
    initRaiseHands,
    raiseHand,
    currentRaiseHandState,
    raiseHandActive: currentRaiseHandState?.raised,
  };
}
