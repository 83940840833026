import { useAppDispatch, useAppSelector } from "../store/hooks";
import { AppDispatch, RootState } from "../store/store";

import restClient from "../api/restClient";
import {
  resetCurrentEvent,
  setCurrentEvent,
  setEvents,
  setLoading,
} from "../store/reducers/event.reducer";
import { IEvent } from "../interfaces/event.interface";
import ICreateEvent from "../interfaces/create-event.interface";
import { useOrganization } from "./organization.hook";
import getAuthHeader from "../utils/getAuthHeader";
import { useUser } from "./user.hook";
import { IOrganization } from "../interfaces/organization.interface";

export const useEvents = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const { handlers: orgaHandlers, organization } = useOrganization();
  const { profile } = useUser();

  const events: IEvent[] = useAppSelector(
    (state: RootState) => state.event.events
  );
  const currentEvent: IEvent = useAppSelector(
    (state: RootState) => state.event.currentEvent
  );
  const loading: IEvent = useAppSelector(
    (state: RootState) => state.event.loading
  );

  const getEventData = async (id: string) => {
    const config = await getAuthHeader();
    try {
      const response = await restClient.get("/events/shortuid/" + id, config);
      const event: IEvent = response.data;
      
      let userRole = "Guest";
      if (profile) {
        const profileId = profile.id;
        if (event.organization && event.organization.id) {
          const currentEventOrga: IOrganization | any =
            await orgaHandlers.getOrganization(event?.organization?.id);
            
            if (currentEventOrga?.members?.length > 0) {
              const member = currentEventOrga.members.find(
                (member: any) => member.profile.id === profile.id
                );
            if (member) {
              userRole = member.roles[0];
            }
          }
        }

        if (event && event.participants && event?.participants.length > 0) {
          const user = event.participants.find(
            (p) => p.profile.id === profileId
          );

          if (user) {
            userRole = user.roles[0];
          }
        }
      }

      event.usersRole = userRole;
      if (event) {
        dispatch(setCurrentEvent(event));
      }
    } catch (e) {
      console.log("error event:",e);
    }
  };

  const resetEventData = async () => {
    dispatch(resetCurrentEvent());
  };

  const eventUpdate = async (id: string, shortuid: string, data: any) => {
    dispatch(setLoading(true));
    try {
      const config = await getAuthHeader();
      const response = await restClient.patch("/events/" + id, data, config);
      const events: IEvent = response.data;
      if (events) {
        getEventData(shortuid);
        dispatch(setLoading(false));
      }
    } catch (e) {
      dispatch(setLoading(false));
    }
  };

  const getAllEvents = async () => {
    try {
      const config = await getAuthHeader();
      const response = await restClient.get("/events");
      const events: IEvent[] = response.data;
      if (events) {
        dispatch(setEvents(events));
      }
    } catch (e) {}
  };

  const getAllOrganizationEvents = async (id: string) => {
    try {
      const config = await getAuthHeader();
      const response = await restClient.get("/organizations/events/" + id, config);
      const events: IEvent[] = response.data;
      if (events) {
        dispatch(setEvents(events));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const createEvent = async (data: ICreateEvent) => {
    try {
      const config = await getAuthHeader();
      const response = await restClient.post("/events", data, config);
      const event: IEvent = response.data;

      if (event) {
        if (data.organization) {
          getAllOrganizationEvents(data.organization);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const removeEvent = async (itemId: string, id: string) => {
    try {
      const config = await getAuthHeader();
      const response = await restClient.delete("/events/" + itemId, config);
      const event: IEvent = response.data;
      if (event) {
        getAllOrganizationEvents(id);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handlers = {
    getAllEvents: getAllEvents,
    createEvent: createEvent,
    removeEvent: removeEvent,
    getEventData: getEventData,
    getAllOrganizationEvents: getAllOrganizationEvents,
    eventUpdate: eventUpdate,
    resetEventData: resetEventData,
  };

  return {
    handlers,
    events,
    currentEvent,
    loading,
  };
};
