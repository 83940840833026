import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IEvent } from "../../interfaces/event.interface";

interface IEventState {
  events: IEvent[] | null;
  eventMembers: [] | null;
  currentEvent: IEvent | null;
}

const initialstate: IEventState = {
  events: [],
  eventMembers: [],
  currentEvent: null,
};
export const slice: any = createSlice({
  name: "events",
  initialState: initialstate,
  reducers: {
    setLoading: (state: any, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setEvents: (state: any, action: PayloadAction<IEvent[]>) => {
      state.events = action.payload;
    },
    setEventMembers: (state: any, action: PayloadAction<IEvent[]>) => {
      state.eventMembers = action.payload;
    },
    setCurrentEvent: (state: any, action: PayloadAction<IEvent>) => {
      state.currentEvent = action.payload;
    },
    resetCurrentEvent: (state: any, action: PayloadAction<IEvent>) => {
      state.currentEvent = null;
    },
   
  },
});

export const {
  setLoading,
  setEvents,
  setEvent,
  setEventMembers,
  setCurrentEvent,
  resetCurrentEvent
} = slice.actions;

export default slice.reducer;
