import { auth } from '../api/firebase';

const getHeader = async () => {
  const token = await auth.currentUser?.getIdToken();
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export default getHeader;
