import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IUploadState {
  loading: boolean;
  images:any[];
}
const initialstate: IUploadState = {
  loading: false,
  images:[]
};

export const counterSlice = createSlice({
  name: "uploadImage",
  initialState: initialstate,
  reducers: {
    setLoading: (state: any, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setImages: (state: any, action: PayloadAction<boolean>) => {
      state.images = action.payload;
    },
  },
});

export const { setLoading,setImages } = counterSlice.actions;

export default counterSlice.reducer;
